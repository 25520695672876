<template>
  <!-- eslint-disable max-len, vue/max-len -->
    <article>
        <div class="cms-page">
            <div class="cms-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <a href="#">Legal</a> &raquo; <a href="#">Disclaimer</a>
            </div>
            <h3 class="cms-page__header">Disclaimer</h3>
            <div class="cms-page__content">
              <disclaimer-content/>
            </div>
            <div class="cms-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>

export default {
  name: 'Disclaimer',
  title: 'Disclaimer',
};
</script>

<style>

</style>
