<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper
    message="I see you may qualify for an additional offer">
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions
          :value="medicare"
          @input="onSelectOption"
          label="Are you interested in speaking with a licensed insurance agent about Medicare plans with as low as $0 monthly premiums and possible extra benefits such as vision and hearing at no extra cost?"
          :optionsList="[{id: 'true', label: 'Yes'}, {id: 'false', label: 'No'}]"
        />
      </fieldset>
      <div class="form__disclaimer">
        * By selecting “Yes, I provide my signature expressly consenting to be contacted by Fetch, LLC and Allied Insurance Partners regarding products or services, including Medicare Supplement, Medicare Advantage, and prescription drug insurance plans via live, automated dialing system or prerecorded telephone call, text, or email. I understand my telephone company may impose charges on me for these contacts and am not required to enter into this agreement as a condition of any purchase. I understand I can revoke this consent through any reasonable means. Agents are not connected with or endorsed by the U.S. government or the federal Medicare program.
      </div>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'Medicare',
  title: 'Apply | Step 12b',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      medicare: false,
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.medicare = value;
      this.saveFormData({
        additionalVerticals: {
          ...this.formData.additionalVerticals,
          medicare: this.medicare,
        },
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    if (typeof this.formData.additionalVerticals !== 'undefined') {
      this.medicare = this.formData.additionalVerticals.medicare || '';
    }
  },
};
</script>
