<template>
  <div class="error-page">
    <h3 class="error-page__header">Page Not Found!</h3>
    <div class="error-page__content">
      <p>The page you're looking does not exist or has been moved</p>
      <p><strong>Trying to request a loan?</strong></p>
      <div class="error-page__button-wrapper">
        <router-link class="error-page__button error-page__button_color uc" to="/registration/1">
          Click here to begin
        </router-link>
      </div>
    </div>
    <div class="error-page__footer-link"><router-link to="/">Return Home</router-link></div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  serverPrefetch () {
    // changing server context, because Vue SSR doesn't have the option to return params from client to server on rendering. The app should return content with a 404 error when the page is not found
    this.$root.$options.ssrContext.errorCode = 404;
  }
}
</script>
