import LTConnectorChildren from '@/code/FastCash/Bundle/LTConnector/Resources/vue-routing/chatBot'
import returningUsersChildren from '@/code/FastCash/Bundle/LTConnector/Resources/vue-routing/chatBotMultiStepShort'
import basePaths from '@/design/frontend/LeapTheory/Blank/router/paths'

export default {
  ...basePaths,
  home: {
    path: '/',
    components: {
      wrapper: () => import(/* webpackChunkName: "home" */ '../../chatbot/views'),
      default: () => import(/* webpackChunkName: "ChatBotNoLongFormHome" */ '../../chatbot/components/SectionHero.vue'),
    },
  },
  registration: {
    path: '/registration',
    children: LTConnectorChildren,
    components: {
      wrapper: () => import(/* webpackChunkName: "form" */ '../../chatbot/views/FormsWrapper.vue'),
    },
  },
  users: {
    path: '/users',
    children: returningUsersChildren,
    components: {
      wrapper: () => import(/* webpackChunkName: "form" */ '../../chatbot/views/FormsWrapper.vue'),
    },
  },
}
