<template>
  <!-- eslint-disable max-len -->
    <article>
        <div class="cms-page">
            <div class="cms-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <a href="#">Legal</a> &raquo; <a href="#">California - Do Not Sell My Info & Privacy Policy</a>
            </div>
            <h3 class="cms-page__header">California - Do Not Sell My Info & Privacy Policy</h3>
            <div class="cms-page__content">
                <p>Are you a resident of California? Read on. The California Consumer Privacy Act of 2018 (CCPA) is a law that gives you rights over your personal information (“info” for short), subject to certain exemptions.</p>

                <p>One of these is the right to opt-out of having your info sold. You can opt-out by clicking...</p>

                <p class="text-center">
                    <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA - Do Not Sell My Info`" class="btn btn-secondary uc">Do Not Sell My Info</a>
                </p>

                <p><span class="text-danger"><strong>WARNING:</strong></span> If you opt-out, we won’t be able to help you find a loan or other products/services that lenders and related (like pre-paid card, debt relief) providers in our network offer. You’ll have to re-submit your form, which lets us know you want us to sell your info so we can try to help you.</p>

                <h4 class="my-4">California Privacy Policy</h4>

                <p>Last Updated: February 28, 2022</p>
                <p>This “California Privacy Policy” has extra disclosures, just for California residents, about your personal information (“info” for short). This California Privacy Policy controls where there are conflicts with the Privacy Policy.</p>
                <p>For the purposes of this California Privacy Notice, except where a different definition is noted, “info” means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular California consumer or household.  Personal information does not include publicly available information, information that has been de-identified or aggregated, or other information covered by certain sector-specific privacy laws.</p>
                <p>This California Privacy Policy supplements our Privacy Policy (which can be found at <router-link to="/legal/privacy">https://{{ `${pageContext.url_name.toLowerCase()}` }}/legal/privacy</router-link>). Further, this California Privacy Policy applies only to the info we collect – if any – that is not already protected by federal privacy laws.  Because we are subject to certain federal laws protecting your privacy and the privacy of your personal information, such as the Gramm-Leach-Bliley Act (“GLBA”), the info subject to those federal laws is not subject to the CCPA and not covered by this California Privacy Policy.</p>

                <p><strong>Section 1. Summary of Your Privacy Rights</strong></p>

                <p>As a California resident,</p>
                <ol>
                    <li>You have a right to know the general categories of info we collect about you, where we get your info, why we collect your info, the categories of third-parties that we share your info with, and why we share your info. (See Section 2)</li>
                    <li>You have a right to request that we tell you what specific info we have about you. (See Section 3)</li>
                    <li>You have a right to request that we delete your info. (See Section 4)</li>
                    <li>You have a right to opt-out of having your info sold. (See “Do Not Sell My Info” link, above and Section 6.)</li>
                    <li>You have a right for us not to discriminate against you for exercising these privacy rights.</li>
                </ol>

                <p>The above rights are subject to certain legal exemptions, including, but not limited to, where the info is already protected by federal laws such as the GLBA or where granting a request would restrict our ability to comply with our legal obligations or exercise or defend legal claims.</p>

                <p><strong>Section 2. Right to Know - General Info</strong></p>
                <p>This chart summarizes the info we generally collect about you, why, and from where as well as who we share your info with and why.</p>

                <table class="table table-striped table-sm table-responsive">
                    <tr>
                        <th>Info Category</th>
                        <th>Examples</th>
                        <th>Do We Collect This Info About You? </th>
                        <th>Why Do We Collect & Use?</th>
                        <th>Where Do We Collect?</th>
                        <th>Who Do We Share With?</th>
                        <th>Why Do We Share?</th>
                    </tr>
                    <tr>
                        <td>A. Identifiers</td>
                        <td>Name, email, phone, IP address</td>
                        <td>Yes</td>
                        <td>For us to provide services, market to you, analyze your use, conduct research, maintain security of our system and operations, and meet our legal compliance requirements.</td>
                        <td>You give us this info, we get it automatically, we get it from third parties.</td>
                        <td>Corporate affiliates, businesses in our network (like lenders), marketing partners, vendors (including cloud storage providers, data analytics providers), business or research partners / customers, potential business or research partners / customers, law enforcement, government authorities, parties that legally compel us to share your info.</td>
                        <td>For third parties to provide products & services to you, market to you, analyze your use, conduct research, maintain security of their systems and operations, and meet their legal compliance requirements.</td>
                    </tr>
                    <tr>
                        <td>B. Info listed in the California Customer Records statute, §Cal. Civ. Code 1798.80(e)</td>
                        <td>Name, signature, address, employment</td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>You give us this info, we get it from third parties</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                    <tr>
                        <td>C. Protected info under California or federal law</td>
                        <td>Age, military status</td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row B, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                    <tr>
                        <td>D. Commercial info</td>
                        <td>Product or service provider sites you choose to navigate to</td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                    <tr>
                        <td>E. Biometric info</td>
                        <td>Fingerprint, voice</td>
                        <td>No</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>F. Internet or network activity</td>
                        <td>Interaction with a site or ad</td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                    <tr>
                        <td>G. Geolocation info</td>
                        <td>Physical location </td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                    <tr>
                        <td>H. Sensory info</td>
                        <td>Olfactory info</td>
                        <td>No</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>I. Professional, employment info</td>
                        <td>Current employer, length of employment</td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row B, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                    <tr>
                        <td>J. Non-public education information per the Family Educational Rights and Privacy Act, 20 U.S.C. Section 1232g, 34 C.F.R. Part 99</td>
                        <td>Student financial info</td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row B, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                    <tr>
                        <td>K. Inferences drawn from other info</td>
                        <td>Interest in products or services related to what our business partners currently provide</td>
                        <td>Yes</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                        <td>Same as in Row A, above</td>
                    </tr>
                </table>

                <p>We do not knowingly sell the personal information of individuals under 16 years of age.</p>

                <p><strong>Section 3. Right to Know - Specific Info</strong></p>
                <p>You can request access to the specific info we have about you by sending an email with the Subject Line “CCPA Right to Know” to <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA Right to Know`">{{ pageContext.brand_compliance }}</a>. We will not fulfill your request unless you have provided sufficient information that enables us to reasonably verify that you are the consumer about whom we collected the personal information on. In order to verify you, you must provide us with your [first and last name, email address, and physical address]. After we verify who you are, we’ll send you your info in electronic format that makes it easy for you to send to other companies, subject to any applicable exceptions.</p>
                <p>You may also designate an authorized agent, in writing or through a power of attorney, to request to exercise the above rights on your behalf.  The authorized agent may submit a request to exercise these rights by emailing the letter of authorization or power of attorney to <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA Right to Know`">{{ pageContext.brand_compliance }}</a>.</p>

                <p><strong>Section 4. Right to Delete</strong></p>
                <p>You can request we delete your info by sending an email with the Subject Line “CCPA Right to Delete” to <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA Right to Delete`">{{ pageContext.brand_compliance }}</a>. We will not fulfill your request unless you have provided sufficient information that enables us to reasonably verify that you are the consumer about whom we collected the personal information on. In order to verify you, you must provide us with your [first and last name, email address, and physical address]. Please list what info you’d like us to delete, using the categories listed in Section 2 above. After we verify who you are, we’ll delete and confirm deletion of your info, subject to any applicable exceptions.</p>
                <p>You may also designate an authorized agent, in writing or through a power of attorney, to request to exercise the above rights on your behalf.  The authorized agent may submit a request to exercise these rights by emailing the letter of authorization or power of attorney to <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA Right to Delete`">{{ pageContext.brand_compliance }}</a>.</p>

                <p><strong>Section 5. Direct Marketing by Third Parties</strong></p>
                <p>We disclose info to third parties for their own direct marketing purposes. California residents have the right to request information regarding such practices under California’s “Shine the Light” law. If you are a California resident and would like to inquire further, please email <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA Direct Marketing by Third Parties`">{{ pageContext.brand_compliance }}</a>.</p>

                <p><strong>Section 6. Notice of Right to Opt-Out</strong></p>
                <p>We sell info as further detailed in Section 2 of this California Privacy Notice. If you wish to request an opt-out of such sales, you may do so by emailing us at <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA - Do Not Sell My Info`">{{ pageContext.brand_compliance }}</a> or by clicking:</p>
                <p class="text-center">
                    <a :href="`mailto:${pageContext.brand_compliance}?subject=CCPA - Do Not Sell My Info`" class="btn btn-secondary uc">Do Not Sell My Info</a>
                </p>
                <p><strong>WARNING:</strong> If you opt-out, we won’t be able to help you find a loan or other products/services that lenders and related (like pre-paid card, debt relief) providers in our network offer. You’ll have to re-submit your form, which lets us know you want us to sell your info so we can try to help you.</p>
                <p>Further, if you wish to opt-out of our sharing of the limited info that is gathered when you visit our sites for purposes of targeted digital advertising, we encourage you to visit the <a href="https://optout.networkadvertising.org/?c=1" target="_blank">Network Advertising Initiative</a> and/or the Digital Advertising Alliance’s <a href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank">Self-Regulatory Program for Online Behavioral Advertising</a> for more information about opting out of seeing targeted digital advertisements.</p>

                <p><strong>Section 7. How to Contact Us</strong></p>
                If you have any questions about this California Privacy Policy or wish to exercise one of the privacy rights detailed above, please submit a rights request or otherwise contact us by emailing <a :href="`mailto:${pageContext.brand_compliance}`">{{ pageContext.brand_compliance }}</a> or sending a letter to:

                <p>{{pageContext.brand_name_llc}}<br>
                    <span v-html="pageContext.brand_address"></span></p>
            </div>
            <div class="cms-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'California',
  title: 'California - Do Not Sell My Info & Privacy Policy',
  computed: mapGetters(['pageContext']),
};
</script>

<style>

</style>
