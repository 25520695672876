<template>
  <!-- eslint-disable max-len, vue/max-len -->
  <section class="disclaimers bg-grey">
    <div class="disclaimers__container">
      <h2 class="disclaimers__header">Important Disclaimers and Disclosures</h2>
      <div class="disclaimers__content-wrapper">
        <div class="disclaimers__content">
          <disclaimer-content />
        </div><!--End Col 8-->
      </div><!--End Row-->
    </div><!--End Container-->
  </section>
</template>

<script>

export default {
  name: 'Disclaimers',
};
</script>
