<template>
    <div class="progress" :class="outerClass">
        <div class="progress-bar progress-bar_color" :style="{ width: percentage+'%'}"></div>
    </div>
</template>

<script>
export default {
    name: 'StepsPercentage',
    props: {
        percentage: {
            type: Number,
            default: 10
        },
        outerClass: {
            type: String,
            default: 'mb-5'
        },
    }
}
</script>

<style>

</style>
