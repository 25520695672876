export default {
  path: '/repost',
  components: {
    default: () => import(/* webpackChunkName: "repost" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Interstitial.vue'),
    wrapper: () => import(/* webpackChunkName: "repost" */ '../../Blank/views/one-column-right.vue'),
  },
  props: {
    wrapper: { formType: 'form_interstitial' },
  },
}
