import Vue from 'vue';
import Vuex from 'vuex';
import VueGtm from '@gtm-support/vue2-gtm';
import VeeValidate from 'vee-validate';
import VueScrollTo from 'vue-scrollto';
import Meta from 'vue-meta';
import createRouter from '@/code/Core/Bundle/Vue/view/createRouter';
import { registerComponents } from './registerComponents';
import { registerDefaultValidators } from './registerValidation';
import EventBus from './plugins/EventBus';

Vue.config.productionTip = false;

Vue.use(VueScrollTo, {
  duration: 1000,
});

Vue.use(Vuex);

const validateConfig = {
  aria: true,
  classNames: {},
  classes: true,
  events: 'input|blur|change|focus',
};

Vue.use(VeeValidate, validateConfig);
registerDefaultValidators();

Vue.use(Meta, {
  ssrAppId: 1, // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});

export default function (params, context) {
  const {
    store, gtmConfig, App, requireComponents, eventsList, paths,
  } = params;
  let { router } = params;
  if (paths) {
    router = createRouter(paths);
  }

  const vuex = new Vuex.Store(store);

  if (requireComponents) {
    registerComponents(requireComponents);
  }

  // if (process.env.VUE_ENV === 'server' && store) {
  vuex.dispatch('parseServerContext', context);
  // }
  if (process.env.VUE_ENV === 'client') {
    if (context) {
      // We initialize the store state with the data injected from the server
      vuex.replaceState(context);
    }

    const configs = vuex.getters.config;
    if (configs.google.gtmCode) {
      const vueGtmConfig = Object.assign(gtmConfig, {
        id: configs.google.gtmCode,
        vueRouter: router,
      });
      Vue.use(VueGtm, vueGtmConfig);

      router.afterEach((to) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          pagePath: to.path,
          pageTitle: to.name,
        });
      });
    }
  }

  Vue.use(EventBus, eventsList);

  const app = new Vue({
    store: vuex,
    router,
    render: (h) => h(App),
  });

  return { app, router, store: vuex };
}
