<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper
    loader-message="Please wait one moment while I check all your details"
    message="Everything looks good! This is the final step, in order to verify your identity, please provide your Driver's License or State ID number"
    :chatLoading="chatLoading"
  >
    <form v-if="!chatLoading" @submit.prevent="validateBeforeSubmit">
      <fieldset class="mb-5">
        <div class="row">
          <div class="form-row__inner form-row__inner_size-12">
            <div class="encrypted">
              <span class="encrypted__icon" alt=""></span>
              <span class="encrypted__label">256-bit encrypted</span>
            </div>
            <BasicInput
              label="Driver's license/State ID"
              id="driversLicense"
              name="driversLicense"
              autocomplete="off"
              v-model="driversLicense"
              v-validate="'required'"
              :exval="validationAttempt"
              errormsg="This is a required field"
              :error="errors.first('driversLicense')"
              extracss="dont-mark"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-row__inner">
            <Select
              label="State issued"
              id="driversLicenseState"
              name="driversLicenseState"
              v-model="driversLicenseState"
              v-validate="'required'"
              :options="statesList"
              errormsg="This is a required field"
              :error="errors.first('driversLicenseState')"
            />
          </div>
        </div>
        <div class="control-group__optional-field-helper">
          Lenders use this information to help prove your identity. Enter accurately to increase your chances of
          acceptance.
        </div>
      </fieldset>
      <ESignatureNote class="light txt-grey p14 mb-5" :phone="formData.phone "/>

      <form-button class="form-button_flex-wrap" id="form-submit-long"
                   :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Sending Your Request...' : 'Submit Loan Request'"
                   :isProcessing="saving">
        <template v-slot:loader>
          <div v-if="saving" class="form-button__loader">
            <div class="dots">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </template>
        <template v-slot:errorMessage>
          <div v-if="errorMessage" class="alert alert-danger w-100 mt-4">
            There was an error submitting your request. Please try again later.
          </div>
        </template>
      </form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'DriversLicense',
  title: 'Apply | Step 24',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      errorMessage: false,
      chatLoading: true,

      driversLicense: '',
      driversLicenseState: '',

    };
  },
  methods: {
    ...mapActions(['saveFormData']),

    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            driversLicense: this.driversLicense,
            driversLicenseState: this.driversLicenseState,
          });

          this.completeStep();
        } else {
          this.saving = false;
        }
      });
    },
  },
  computed: {
    ...mapGetters(['formData', 'statesList']),
    VUE_APP_SITE_URLNAME() {
      return process.env.VUE_APP_SITE_URLNAME;
    },
  },
  created() {
    setTimeout(() => {
      this.chatLoading = false;
    }, 3000);
    this.driversLicense = this.formData.driversLicense || '';
    this.driversLicenseState = this.formData.driversLicenseState || '';
  },
};
</script>
