<template>
  <wizard-wrapper>
    <template v-slot:message>
      Welcome back {{ auth.userDetails.name }}! It looks like we already have your email
      ({{ auth.userDetails.email }}) on file, please enter the last four digits of your <abbr
      title="Social Security Number">SSN</abbr> to confirm and we'll pull up your details for a super fast loan
      submission!
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <div class="form-row">
          <div class="form-row__inner">
            <CleaveInput
              label="Last 4 digits of your SSN"
              id="ssnLast4"
              name="ssnLast4"
              type="tel"
              maxlength="4"
              autocomplete="off"
              v-model="ssnLast4"
              v-validate="{ required: true, regex: /^([0-9]{4})$/ }"

              :exval="validationAttempt"
              :cleaveoptions="masks.ssnLast4"

              extracss="dont-mark"

              errormsg="This is a required field"
              :error="errors.first('ssnLast4')"
            />
          </div>
        </div>
      </fieldset>
      <div v-if="errorMessage" class="alert alert-danger">
        Unfortunately that number doesn't match the records in our system. Try again or
        <router-link to="/registration/7">Click here to continue with a new request</router-link>
        .
      </div>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                  :buttonLabel="loading ? 'Sending...' : 'Continue'"></form-button>

      <div class="text-primary">
        <small><a href="#" @click.prevent="closeLoginForm">Not you? Click here to start a new
          request</a></small>
      </div>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import tagManagerMixin from '@/code/Core/Bundle/GoogleTagManager/view/tagManagerMixin';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';

export default {
  name: 'EmailRecogniseHome',
  title: 'Welcome Back To Your Account',
  components: { WizardWrapper, FormButton },
  mixins: [tagManagerMixin],
  data() {
    return {
      ssnLast4: '',
      validationAttempt: false,
      loading: false,
      errorMessage: false,
      masks: {
        ssnLast4: { numericOnly: true, blocks: [4] },
      },
    };
  },

  computed: {
    ...mapGetters(['auth', 'isUserAuthorized']),
  },

  methods: {
    ...mapActions(['closeLoginForm', 'verifyEmail']),
    validateBeforeSubmit() {
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.sendData();
        }
      });
    },
    async sendData() {
      this.loading = true;
      try {
        await this.verifyEmail({ email: this.auth.userDetails.email, ssn_last4: this.ssnLast4 });
        if (this.isUserAuthorized) {
          this.addTagManagerData({
            event: 'registerLogin',
            entry: 'homeEmail',
          });
          this.$router.push({ name: 'returning' });
        } else {
          this.loading = false;
          this.errorMessage = true;
        }
      } catch (err) {
        this.loading = false;
      }
    },
  },

};
</script>
