<template>
  <!-- eslint-disable max-len -->
  <BasicInput
    :label="label"
    :id="id"
    :name="name"
    :errormsg="errormsg"
    :help="help"
    :extracss="extracss"
    :exval="exval"
    :type="type"
    :maxlength="maxlength"
    :value="value"
    :error="error"
    v-on="$listeners"
    autocomplete="off"
    @focusin="toggleShowMenu(true)"
    @keydown.down.prevent="onkeypress(hoveredIndex+1)"
    @keydown.up.prevent="onkeypress(hoveredIndex-1)"
    @keydown.enter.prevent="onEnterPress"
  >
    <div v-if="isVisibleMenu" class="combobox-list">
      <div
        v-for="(item,index) in items"
        :key="item"
        class="combobox-list__item"
        @click="onSelectItem(item)"
        :class="{'combobox-list__item_selected': item===value,'combobox-list__item_hovered':index===hoveredIndex }"
      >
        {{item}}
      </div>
      <div v-if="totalCount && totalCount!==items.length">
        type more for more results
      </div>
    </div>
  </BasicInput>
</template>

<script>

export default {
  name: 'Combobox',
  inheritAttrs: false,
  props: {
    items: Array,
    label: String,
    id: String,
    name: String,
    errormsg: String,
    help: String,
    extracss: String,
    exval: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    totalCount: {
      type: Number,
      default: null,
    },
  },

  data(){
    return {
      isFocused: false,
      hoveredIndex: -1,
      handleOutsideClick: null
    }
  },

  computed:{
    isVisibleMenu(){
      return this.isFocused && this.items.length!=0 && this.items.indexOf(this.value)===-1;
    }
  },
  watch:{
    items(){
      this.hoveredIndex == -1;
    }
  },
  methods:{
    onEnterPress(){
      if(this.isVisibleMenu && this.hoveredIndex!=-1) {
        this.onSelectItem(this.items[this.hoveredIndex])
      }
    },
    onkeypress(newItem) {
      if(newItem===-1) {
        this.hoveredIndex = this.items.length-1;
      } else if(newItem === this.items.length) {
        this.hoveredIndex = 0;
      } else {
        this.hoveredIndex=newItem
      }
      return false
    },
    onSelectItem(item) {
      this.$emit('input', item);
      this.hoveredIndex = -1;
    },
    toggleShowMenu(value) {
      this.hoveredIndex = -1;
      this.isFocused = value;
    }
  },

  mounted(){
    const self = this;
    this.handleOutsideClick = document.addEventListener('click', (e) => {
      if(!self.$el.contains(e.target)) {
        self.isFocused = false;
      }
    })
  },
  beforeDestroy (){
    document.removeEventListener('click', this.handleOutsideClick)
  },

};
</script>
