const subscribers = {};
let clientEvents = {};
let counter = 0;

const bus = {
  on: (eventName, handler) => {
    if (clientEvents[eventName]) {
      if (!subscribers[eventName]) {
        subscribers[eventName] = [];
      }

      const id = `EventBusHandler_${counter}`;
      counter += 1;

      subscribers[eventName].push({
        id,
        handler,
      });

      return id;
    }
    // eslint-disable-next-line no-throw-literal
    throw (`Event bus doesn't support event '${eventName}'.`);
  },
  emit: (eventName, payload) => {
    if (subscribers[eventName]) {
      subscribers[eventName].forEach((i) => {
        i.handler(payload);
      });
    }
  },
  off: (eventName, handlerId) => {
    if (subscribers[eventName]) {
      const index = subscribers[eventName].findIndex((i) => i.id === handlerId);
      subscribers[eventName].splice(index, 1);
    }
  },
};

export default {
  install(Vue, eventsList) {
    if (Array.isArray(eventsList)) {
      clientEvents = eventsList.reduce((result, item) => ({ ...result, [item]: item }), {});
    }
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$EventBus = bus;
  },
};
