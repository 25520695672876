import getters from './getters';
import mutations from './mutations';

const state = () => ({
    pageContext: {},
    loanAmounts: [],
    partners: [],
    website: null,
    csrfToken: null,
    logo: {},
    config: null,
    labels: {
      whyChooseList: [
        {
          id: 1,
          title: 'Fast Real Time Results',
          // eslint-disable-next-line max-len,vue/max-len
          text: 'Submit your request using $site_name and we will search our network to find you a loan provider in real time, no expired offers, no waiting.',
          iconClass: 'icon-rocket',
        },
        {
          id: 2,
          title: 'Funds Could be Yours by Next Business Day',
          // eslint-disable-next-line max-len,vue/max-len
          text: 'Our service could connect you with a lender to get you your funds as soon as possible!',
          iconClass: 'icon-credit',
        },
        {
          id: 3,
          title: 'All Credit Types Welcome',
          // eslint-disable-next-line max-len,vue/max-len
          text: 'Our lender network allows your request to be seen by multiple lenders, which could increase your odds of acceptance.',
          iconClass: 'icon-check',
        },
        {
          id: 4,
          title: 'Safe and Secure',
          // eslint-disable-next-line max-len,vue/max-len
          text: 'Your personal data is encrypted when using our service.',
          iconClass: 'icon-lock',
        },
      ],
      howItWorksSteps: [
        {
          id: 1,
          stepNo: 'Step 01',
          title: `$site_name’s easy-bake two minute form`,
          // eslint-disable-next-line max-len,vue/max-len
          text: 'You only need to answer a few basic questions about your identity, employment and income. Our simple form makes for fast, secure and easy processing. In just a few minutes if approved, you’ll be ready to review your offer.',
          iconClass: 'icon-bulb',
        },
        {
          id: 2,
          stepNo: 'Step 02',
          title: 'We\'ll search for a loan for you',
          // eslint-disable-next-line max-len,vue/max-len
          text: 'Grab yourself a coffee, while we shake the money tree for you. In a couple of minutes we’ll search for a loan from our network of lenders. If you are accepted by a lender, you’ll be forwarded to their acceptance page.',
          iconClass: 'icon-search',
        },
        {
          id: 3,
          stepNo: 'Step 03',
          title: 'Review the terms and sign an agreement',
          // eslint-disable-next-line max-len,vue/max-len
          text: 'Carefully review the terms and conditions of the offer. An E-Sign page will be presented by the lender to complete the process. If you do not like the terms of the loan, you are under no obligation to accept.',
          iconClass: 'icon-pencil',
        },
        {
          id: 4,
          stepNo: 'Step 04',
          title: 'Receive the funds to your account and start spending',
          // eslint-disable-next-line max-len,vue/max-len
          text: 'After you sign your agreement your funds will be transferred to your account usually within 24-48 hours depending on the conditions of the loan agreement. Remember to use any loan responsibly.',
          iconClass: 'icon-cash',
        },
      ],
    }
  });

export default {
  state,
  getters,
  mutations
}
