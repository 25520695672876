import content from './modules/content'
import data from './modules/data'
import ui from './modules/ui'
import core from './core'
export default {
  modules: {
    content,
    data,
    ui,
  },
  ...core,
}
