<script>
export default {
  name: 'BackgroundImage',
  props: {
    src:{
      type: String,
    },
    tag:{
      type: String,
      default: 'div'
    },
    cover: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    imgSrc() {
      return this.src ? `url(${this.src})`: 'none'
    }
  },
  render: function (h) {
    return h(
      this.tag,
      {
        attrs: this.$attrs,
        on: this.$listeners,
        props: this.$options.propsData ,
        style: {
          backgroundImage: this.cover ? `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), ${this.imgSrc}` : this.imgSrc,
        },
      },
      this.$slots.default
    )
  },
}
</script>

