export default {
  toggleLeadForm: (state) => state.ui.showLeadForm = !state.ui.showLeadForm,
  setColorScheme: (state, data) => {
    state.ui.colorScheme = data;
  },
  toggleDeviceScheme: (state, data) => {
    state.ui.deviceScheme = data;
  },
  markStepComplete: (state, data) => {
    state.filledSteps[data] = true;
  },
  setProgressAmount: (state, data) => {
    state.ui.formProgress = data;
  },
  setFormSteps: (state, data) => {
    state.steps = data;
  },
  setColorSwitch: (state, data) => {
    state.ui.withColorSwitch = data;
  }
}
