<template>
  <!-- eslint-disable max-len -->
  <section id="why-choose-us" class="why-choose-us bg-grey">
    <div class="why-choose-us__container">
      <h2 class="why-choose-us__header uc">Why Choose {{ pageContext.site_name }}?</h2>
      <div class="why-choose-us__content-wrapper">
        <div class="why-choose-us__content">
          <div class="paragraph bg-white" v-for="(paragraph, index) in whyChooseList" :key="paragraph.id"
               v-bind:class="{'paragraph_not-last': index != whyChooseList.length-1 }">
            <div class="paragraph__content">
              <div>
                <h3 class="paragraph__header">{{paragraph.title}}</h3>
                {{paragraph.text}}
              </div>
              <div class="paragraph__icon  paragraph__icon_color">
                <i :class="paragraph.iconClass"></i>
              </div>
            </div>
          </div>
        </div><!--End Col 8-->
      </div><!--End Row-->
      <CallToActionButton :mode="mode"/>
    </div><!--End Container-->
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CallToActionButton from './CallToActionButton';

export default {
  name: 'WhyChoose',
  components: {
    CallToActionButton,
  },
  props: {
    mode: {
      type: String,
      default: 'history',
    },
  },
  data() {
    return {
      whyChooseList: [],
    };
  },
  computed: mapGetters(['pageContext', 'labels']),
  created() {
    this.whyChooseList = this.getWhyChooseList();
  },
  methods: {
    getWhyChooseList() {
      const list = this.labels.whyChooseList;
      list[0].text = list[0].text.replace('$site_name', this.pageContext.site_name);
      return list;
    },
  },
};
</script>
