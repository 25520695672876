<template>
  <!-- eslint-disable max-len -->
  <div class="control-group">
    <label class="control-group__label" :for="id" :class="{'control-group__label_focused' : this.focused || value}">{{label}}</label>
    <input
      class="control-group__input"
      :class="[ ( error && (exval || dirty) ) && 'control-group__input_invalid', ( !error && value && dirty ) && 'control-group__input_valid', extracss ]"
      :id="id"
      :name="name"
      :type="type"
      v-cleave="cleave || {}"
      :maxlength="maxlength"
      ref="input"
      @input="$emit('input', $event.target.value);"
      @focus="focusField"
      @blur="blurField"
    >

    <div v-if="help" class="control-group__optional-field-helper" v-html="help"></div>
    <div v-if="( error && (exval || dirty) )" class="control-group__input-error">{{ errormsg || error }}</div>
  </div>
</template>

<script>
import Cleave from "cleave.js";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    el._vCleave = new Cleave(el, binding.value);
  },
  unbind(el) {
    el._vCleave.destroy();
  }
};

export default {
  name: "TextInput",
  directives: { cleave },
  $_veeValidate: {
    // value getter
    value() {
      return this.$el.value;
    },
    // name getter
    name() {
      return this.name;
    }
  },
  props: {
    label: String,
    id: String,
    name: String,
    errormsg: String,
    help: String,
    extracss: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    maxlength: {
      type: String,
      default: ""
    },
    value: {
      type: null,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    cleave: {
      type: Object
    },
    exval: {
      type: Boolean
    }
  },
  data() {
    return {
      focused: false,
      touched: false,
      dirty: false
    };
  },
  mounted() {
    // sync the input to the initial value
    this.$refs.input.value = this.value;
  },
  methods: {
    focusField() {
      this.focused = true;
      this.touched = true;
    },
    blurField() {
      this.focused = false;
      this.dirty = true;
    }
  }
};
</script>
