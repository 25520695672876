export default {
  path: '/signin',
  components: {
    default: () => import(/* webpackChunkName: "signin" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Signin.vue'),
    wrapper: () => import(/* webpackChunkName: "signin" */ '../../Blank/views/one-column-right.vue'),
  },
  props: {
    default: { loanSelectType: 'hidden' },
    wrapper: { leftColumnClass: 'align-items-center justify-content-center', rightColumnClass: 'auth-page' },
  },
}
