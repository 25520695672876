<template>
  <wizard-wrapper>
    <template v-slot:message>
      <div class="d-block d-lg-none">
        <!-- eslint-disable-next-line max-len,vue/max-len -->
        Hi {{formData.firstName || 'there'}}, I’m {{ operatorName }}. I’m here to help you find your loan.
      </div>
      <div class="d-none d-lg-block">
        <!-- eslint-disable-next-line max-len,vue/max-len -->
        Hi {{formData.firstName || 'there'}}, I’m {{ operatorName }}. I’m here to help you find your loan. Let me know how much
        you're looking to borrow and we'll get started with your request
      </div>
    </template>

    <form>
      <fieldset class="form__fieldset">
        <LoanSelect
          label="How much do you want to borrow?"
          id="loan_amount"
          name="loanAmount"
          type="hidden"
          :value="loanAmount"
          @input="onSelectOption"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'LoanAmount',
  title: 'Apply | Step 1',
  mixins: [formsRouterHooks],

  components: {
    WizardWrapper,
  },
  data() {
    return {
      loanAmount: null,
    };
  },

  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.loanAmount = value;
      if (this.loanAmount !== null) {
        this.saveFormData({
          loanAmount: this.loanAmount,
        });
        this.completeStep(null, 'apply1');
      }
    },
  },

  computed: mapGetters(['formData', 'pageContext', 'operatorName']),
};
</script>
