<template>
  <wizard-wrapper
    message="What do you estimate is your credit score?">
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions :value="creditScore"
                      @input="onSelectOption"
                      label="Estimated Credit Score"
                      :optionsList="[
                        {id: 'excellent', label: '720+'},
                        {id: 'good', label: '660-719'},
                        {id: 'fair', label: '600-659'},
                        {id: 'poor', label: '599 or less'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'CreditScore',
  title: 'Apply | Step 3',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      creditScore: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'markStepComplete']),
    onSelectOption(value) {
      this.creditScore = value;
      this.saving = true;
      this.saveFormData({
        creditScore: this.creditScore,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.creditScore = this.formData.creditScore || '';
  },
};
</script>
