<template>
  <!-- eslint-disable max-len -->
  <div class="loading-cover justify-content-center align-items-center bg-grey">
      <div class="bg-white p-4">
          <Logo outerClass="text-center" type="png" defaultColor="dark" innerClass="py-4" innerStyle="width:206px;" />
            <div class="dots ml-auto mr-auto">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="mt-4 w-100 px-4 text-center">
                <small>{{message}}</small>
            </div>
      </div>
  </div>
</template>

<script>
import Logo from '.././Logo';

export default {
  name: "Loading",
  components: {
      Logo,
  },
  props: ['message']
};
</script>
