<template>
  <!-- eslint-disable max-len -->
  <section class="footer-cta bg-darkgrey txt-white">
    <div class="footer-cta__container">
      <div class="footer-cta__content">
        <div class="footer-cta__header-outer">
          <h2 class="footer-cta__header uc">Solve your financial emergency quickly using our <span class="grad-text">easy to use</span>
            form</h2>
        </div><!--End Col 8-->
      </div><!--End Row-->
      <CallToActionButton :mode="mode"/>
    </div><!--End Container-->
  </section>
</template>

<script>
import CallToActionButton from './CallToActionButton';

export default {
  name: 'FooterCTA',
  components: {
    CallToActionButton,
  },
  props: {
    mode: {
      type: String,
      default: 'history',
    },
  },
};
</script>

<style>

</style>
