import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
  computed: {
    USER_AGENT() {
      return navigator.userAgent;
    },
  },
  methods: {
    ...mapActions(['saveVisitData', 'checkUserAgentHistory', 'checkEmail', 'getIP']),
    async registerVisit() {
      try {
        this.ip = await this.getIP();
      } catch (err) {
        // console.log(err);
      }
      const params = {
        ip: this.ip,
        agent: this.USER_AGENT,
        subid1: this.subid1,
        subid2: this.subid2,
        subid3: this.subid3,
        subid4: this.subid4,
        utm_source: this.utm_source,
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_content: this.utm_content,
        sc: this.sc,
        sk: this.sk,
        webmasterID: this.webmasterID,
        subaccount: this.subaccount,
      };

      // This may need some refactoring
      this.saveVisitData(params).then(() => {
        if (!_.isEmpty(this.email)) {
          this.checkEmail({ email: this.email, name: this.firstName });
        } else if (localStorage.getItem('user')) {
          const localUser = JSON.parse(localStorage.getItem('user'));
          this.checkEmail(localUser);
        } else {
          this.checkUserAgentHistory();
        }
      });
    },
  },
};
