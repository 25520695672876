<template>
  <wizard-wrapper
    message="Tell us a little bit about your income and employment"
  >
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions :value="monthlyIncome"
                      @input="onSelectOption"
                      label="What is your monthly income?*"
                      :optionsList="[
                        {id: '1000', label: '$1000 or less'},
                        {id: '1500', label: '$1001 - $1500'},
                        {id: '2000', label: '$1501 - $2000'},
                        {id: '2500', label: '$2001 - $2500'},
                        {id: '3000', label: '$2501 - $3000'},
                        {id: '3500', label: '$3001 - $3500'},
                        {id: '4000', label: '$3501 - $4000'},
                        {id: '4500', label: '$4001 - $4500'},
                        {id: '5000', label: '$4501 - $5000'},
                        {id: '6000', label: 'More than $5,000'}]"
        />
      </fieldset>
      <small>* You are not required to disclose income that is alimony, child support,
        or separate maintenance unless you want to use that income to qualify for a loan.</small>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'MonthlyIncome',
  title: 'Apply | Step 13',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      monthlyIncome: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.monthlyIncome = value;
      this.saving = true;
      this.saveFormData({
        monthlyIncome: this.monthlyIncome,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.monthlyIncome = this.formData.monthlyIncome || '';
  },
};
</script>
