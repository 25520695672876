<template>
  <main>
    <section class="one-column-right">
      <background-image :src="getImageSrcByPlace(formType)" class="one-column-right__photo" :class="leftColumnClass"/>
      <background-image :src="getImageSrcByPlace('one-col-right')" class="one-column-right__content" :class="rightColumnClass"> 
        <div class="one-column-right__container">
          <Logo link="/" outerClass="one-column-right__logo-outer" :class="logoClass" defaultColor="dark" innerClass="logo" type="png"/>
          <slot>
            <router-view/>
          </slot>
        </div>
        <Footer class="one-column-right__footer"/>
        <Disclaimers/>
      </background-image>
    </section>
  </main>
</template>

<script>
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import Disclaimers from '@/code/FastCash/Bundle/LTConnector/view/frontend/base/components/home/Disclaimers';
import { mapGetters } from 'vuex'
import BackgroundImage from '@/design/frontend/LeapTheory/Blank/components/BackgroundImage';

export default {
  name: 'one-column-right',
  props: {
    leftColumnClass: {
      type: String,
    },
    rightColumnClass: {
      type: String,
    },
    logoClass: {
      type: String,
      default:'one-column-right__logo-outer_padding'
    },
    formType: {
      type: String,
      default: 'form'
    },
  },
  computed: mapGetters(['getImageSrcByPlace']),
  components: {
    Logo,
    Footer,
    Disclaimers,
    BackgroundImage,
  },
}
</script>
