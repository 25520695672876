<template>
  <main>
    <div class="bg-grey" style="min-height: 100vh;">
      <header-component>
      </header-component>
      <background-image :src="heroImage" tag="section" id="wizard" class="bg-grey py-3 py-lg-5" >
        <div class="container">
          <h2 class="d-none d-lg-block text-center mb-4">{{ headerLabel }}</h2>
          <router-view  />
        </div>
      </background-image>
    </div>
    <Footer />
    <Disclaimers />
  </main>

</template>

<script>
import HeaderComponent from '../components/Header.vue';
import Footer from '../../Blank/components/Footer.vue';
import Disclaimers from '@/code/FastCash/Bundle/LTConnector/view/frontend/base/components/home/Disclaimers';
import { mapGetters } from 'vuex'
import heroBGMixin from '../../Blank/mixins/responsiveBackgroundMixin';


export default {
  name: 'Wizard',
  mixins: [heroBGMixin],
  components: {
    HeaderComponent,
    Footer,
    Disclaimers,
  },
  computed: {
    ...mapGetters(['labels']),
    headerLabel() {
      return this.labels[this.$route.name] ? this.labels[this.$route.name]: this.labels.formWrapperText;
    },
  },
}
</script>
