var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-wrapper',{attrs:{"message":"Tell us a little bit about your income and employment"}},[_c('form',[_c('fieldset',{staticClass:"form__fieldset"},[_c('RadioOptions',{attrs:{"value":_vm.monthsEmployed,"label":_vm.labels.monthsEmployedLabel,"optionsList":[
                      {id: '12', label: 'One year or less'},
                      {id: '24', label: '2 years'},
                      {id: '36', label: '3 years'},
                      {id: '48', label: '4 years'},
                      {id: '60', label: '5 years'},
                      {id: '72', label: '6 years'},
                      {id: '84', label: '7 years or more'}]},on:{"input":_vm.onSelectOption}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }