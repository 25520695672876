<template>
  <!-- eslint-disable max-len -->
  <div>
    <h3 class="cms-page__header">Privacy Policy</h3>
    <div class="cms-page__content">
        <p>{{pageContext.url_name}} is owned by {{pageContext.brand_name_llc_caps}} (“{{pageContext.brand_name_llc}}”) (“we,” “our,” or “us”). We provide this privacy policy to help you understand how we collect, use, share, and otherwise process the personal information of individuals visiting our website.</p>
        <h4 class="my-4" id="1">What information do we collect?</h4>
        <p>When you visit our websites, you voluntarily provide personal information such as your name, email address, mailing address, phone number, social security number, driver’s license number, bank information other contact details, and information about your income, employment history and credit. We collect this information from you when you register on our website, submit a request to be considered for a loan, sign up for a newsletter or other communication, respond to a survey or marketing communication, contact us, or otherwise use our website.</p>
        <p>We also collect technical information, including, but not limited to, browser type, IP address, pages visited, and other activities on the website, device type, and time and date of visit. We collect location information if permitted by your browser settings. We use cookies or similar technologies to collect such information for advertising and other purposes. See the <a href="#4">Digital Advertising & Analytics</a> section of this privacy policy to learn more about the use of this information and the choices available to you.</p>
        <h4 class="my-4" id="2">How do we use your information?</h4>
        <p>We use the information that we collect from you in the following ways: </p>
        <ul>
            <li>To provide our services;</li>
            <li>To personalize your experience and to allow us to deliver the type of content and offerings in which you are most interested;</li>
            <li>To communicate with you via mail, email, telephone, text message/SMS (in which case data and messaging rates may apply), or otherwise even if your number appears on a do-not-call list or other similar registry;</li>
            <li>To provide to third-party lenders to verify the information you provide to us such as social security number, employment history, banking information and address;</li>
            <li>To send information and respond to inquiries, requests, or questions;</li>
            <li>To provide to third-party lenders, who then determine your eligibility for specific products;</li>
            <li>For business purposes, such as analytics, research, advertising and marketing, payment processing, and operational purposes;</li>
            <li>To comply with law enforcement and maintain the security of our website; and</li>
            <li>As otherwise disclosed at the time of collection or use.</li>
        </ul>
        <h4 class="my-4" id="3">How do we share your information?</h4>
        <p>By submitting your information on our form or otherwise using our websites, you opt-in to receive communications from us, one of the lenders that we partner with and/or marketing partners. If you wish to opt out of marketing communications, please see below.</p>
        <p>We share personal information with third parties, including the following:</p>
        <ul>
            <li>Potential lenders;</li>
            <li>Third-parties that provide services on our behalf, such as website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users;</li>
            <li>Marketing partners that provide both financial non-finance related products;
                   As part of a sale, merger or acquisition, or other transfer of all or part of our assets including as part of a bankruptcy proceeding;</li>
            <li>Pursuant to a subpoena, court order, or other legal process or as otherwise required or requested by law, regulation, or government authority programs, or to protect our rights or the rights or safety of third parties; or</li>
            <li>With your consent or as otherwise disclosed at the time of data collection or sharing.</li>
            <li>We may share information that has been de-identified or aggregated without restriction.</li>
        </ul>
        <p>If you wish to opt-out from us sharing your information for marketing communications, please <router-link :tag="internalLink ? 'a': 'span'" :event="internalLink ? 'click': ''" to="/unsubscribe">click here</router-link>. However, if you do not want your information shared with lenders, you may not use our service. To opt out of disclosure of your information to lenders, DO NOT provide your information on our form.</p>
        <h4 class="my-4" id="4">Digital Advertising & Analytics</h4>
        <p>We may partner with ad networks and other ad serving providers (“Advertising Providers”) that serve ads on behalf of us and others on non-affiliated platforms. Some of those ads may be personalized, meaning that they are intended to be relevant to you based on information Advertising Providers collect about your use of our website and other sites or apps over time, including information about relationships among different browsers and devices. This type of advertising is known as interest-based advertising.</p>
        <p>We may also work with third parties that collect information about your use of our website and other websites or apps over time for non-advertising purposes. We use Google Analytics and other third-party services to improve the performance of the website and for analytics and marketing purposes. For more information about how Google Analytics collects and uses information when you use our website, visit <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a>, and to opt out of Google Analytics, visit <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>.
        <h4 class="my-4" id="5">Data Security</h4>

        <p>We aim to protect the security of your data and use generally accepted industry standards to do so including using SSL encryption for sensitive data. However, no method of storing and/ or transmitting electronic data is 100% secure or effective, and we are unable to guarantee the security of your personal information.</p>
        <h4 class="my-4" id="6">Links to Other Websites</h4>

        <p>Our website may contain links to other websites that are not owned or operated by us, such as lender websites or social media websites like Facebook or Twitter. We do not endorse nor are we responsible for the content or products offered on any of these websites. You should carefully review the privacy policies and practices of these websites before visiting them, as we cannot control and are not responsible for their privacy policies or practices.</p>
        <h4 class="my-4" id="7">Children’s Information</h4>
        <p>We do not knowingly collect, maintain, disclose, or otherwise process information from minors below the age of 18.</p>
        <h4 class="my-4" id="8">Changes to our Privacy Policy</h4>
        <p>Occasionally, we make changes to our privacy policy. When we do, we will post the changes on this page. You should visit this privacy policy periodically to learn of any updates.</p>
        <h4 class="my-4" id="9">Your Choices</h4>
        <p>If you no longer wish to receive communications from us,you can make changes to the personal information provided to us at any time by visiting our <router-link :tag="internalLink ? 'a': 'span'" :event="internalLink ? 'click': ''" to="/unsubscribe">unsubscribe page</router-link>. If you no longer wish to receive email communications from us, you can also unsubscribe by using the link provided at the bottom of each email. Please note that you may continue to receive transaction-related communications regarding services that you have requested, including contacting you by email. If you wish to unsubscribe from lender or third party communications, please contact them directly.</p>
        <h4 class="my-4" id="12">Additional Disclosures for California Residents</h4>
        Additional disclosures for California residents are available at our <router-link :tag="internalLink ? 'a': 'span'" :event="internalLink ? 'click': ''" to="/legal/california">California - Do Not Sell My Info & Privacy Policy page</router-link>.
        <h4 class="my-4" id="10">Electronic Disclosure</h4>
        <p>By providing your information on our form or otherwise using our websites, you consent to receive certain notifications, disclosures and/or documents electronically (“Electronic Disclosures”). Your consent to receive Electronic Disclosures applies to any information that you provide on our websites and to any communications that we or third party lenders may send to you. You agree and intend the federal Electronic Signatures in Global and National Commerce Act to apply to (i) your consent to receive notifications, disclosures and/or documents in electronic form; (ii) the information you provide, and (iii) our and/or any third party lenders' ability to conduct business with you by electronic means.</p>
        <h4 class="my-4" id="11">Contact Us</h4>
        <p>If there are any questions regarding this privacy policy, you may contact us using the information below:</p>
        <p>{{pageContext.brand_name_llc}}<br>
        <span v-html="pageContext.brand_address"></span><br>
        <a :href="`mailto:${pageContext.brand_email}`">{{pageContext.brand_email}}</a></p>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PrivacyContent',
  props: {
    internalLink: {
      default: true,
      type: Boolean,
    },
  },
  computed: mapGetters(['pageContext']),
};
</script>
