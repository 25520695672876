import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  visitData: {
    ip: '',
    referrer: '',
    agent: '',
    utm_source: '',
    utm_campaign: '',
    utm_medium: '',
    utm_content: '',
    subid1: '',
    subid2: '',
    subid3: '',
    subid4: '',
    clickID: '',
    sessionID: '',
    sc: '',
    sk: '',
    webmasterID: '',
    subaccount: '',
  },
  auth: {
    useragentRecognised: false,
    recognisedEmail: false,
    recognisedUser: false,
    confirmedUser: false,
    userDetails: {},
  },
  formData: {},
  splitTest: {},
  abasList: [],
  // eslint-disable-next-line max-len,vue/max-len
  statesList: ['AL','AK','AZ','CA','CO','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NC','ND','OH','OK','OR','PA','RI','SC','TN','TX','UT','VA','WA','WI','WY'],
  formType: 'ourSite'
});

export default {
  state,
  getters,
  actions,
  mutations
}
