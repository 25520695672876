import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['config']),
  },

  mounted() {
    const self = this;
    const key = this.config?.jornaya?.campaignKey;
    const scriptID = 'LeadiDscript_campaign';

    if (key && !document.getElementById(scriptID)) {
      window.JournayaCallback = (token) => {
        self.saveFormData({
          jornayaLeadID: token,
        });
      };

      const script = document.createElement('script');
      script.id = scriptID;
      script.type = 'text/javascript';
      script.async = true;
      // eslint-disable-next-line max-len,vue/max-len
      script.src = `//create.lidstatic.com/campaign/${key.toLowerCase()}.js?snippet_version=2&callback=JournayaCallback`;

      document.head.appendChild(script);
    }
  },

  methods: {
    ...mapActions(['saveFormData']),
  },
};
