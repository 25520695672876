<template>
  <wizard-wrapper
    message="Tell us a little bit about your income and employment"
  >
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions :value="payFrequency"
                      @input="onSelectOption"
                      label="How frequently are you paid?"
                      :optionsList="[
                        {id: 'weekly', label: 'Weekly'},
                        {id: 'biweekly', label: 'Bi-Weekly'},
                        {id: 'semi-monthly', label: 'Twice Monthly'},
                        {id: 'monthly', label: 'Monthly'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'PayFrequency',
  title: 'Apply | Step 15',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      payFrequency: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.payFrequency = value;
      this.saving = true;
      this.saveFormData({
        payFrequency: this.payFrequency,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.payFrequency = this.formData.payFrequency || '';
  },
};
</script>
