<template>
  <label><input type="hidden" id="leadid_tcpa_disclosure"/>
    Clicking “Submit Loan Request” is my e-signature whereby I consent to the <a
      href="/legal/privacy" target="_blank">Privacy Policy</a>, <a href="/legal/tos" target="_blank">Terms of Use</a>,
      <a href="/legal/e-consent" target="_blank">E-Consent</a>, <a href="/legal/disclaimer" target="_blank">Disclaimer</a>
      and <a href="/legal/tos#4" target="_blank">Credit Authorization</a>
      and to allow you and <a href="/legal/partners" target="_blank">these companies</a> to use automated methods
      (automatic dialing systems, artificial or prerecorded voice messages, or text messages) to send marketing calls or
      texts to <strong>{{ phone }}</strong>, my number that I provided, even if it's on a do not call list. I agree my
      consent is not a condition to get a loan or other services offered by lenders and related (like pre-paid card, debt
      relief) providers in your network. I can <a href="/unsubscribe/phone" target="_blank">opt-out of your telemarketing</a>
      at any time.
  </label>
</template>

<script>
import journayaMixin from '@/code/FastCash/Bundle/Jornaya/view/frontend/mixins/journaya'

export default {
  name: "ESignatureNote",
  mixins: [journayaMixin],

  props:{
    phone:{
      type: String
    }
  }
}
</script>
