<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper :percentageProp="33">
    <template v-slot:message>
      <h3 class="mb-3">Welcome back, {{ auth.userDetails.firstName }}</h3>
      Thanks for confirming your identity. Check the information below is correct then submit your loan request.
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <LoanSelect
          label="How much do you want to borrow?"
          id="loan_amount"
          name="loanAmount"
          type="hidden"
          :value="loanAmount"
          @input="onSelectOption"
        />
    </form>
  </wizard-wrapper>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../../components/WizardWrapper';
import formsRouterHooks from '../../../base/mixins/formsRouterHooks';

export default {
  name: 'Returning1',
  title: 'Welcome Back',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      loanAmount: null,
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'registerPoint']),
    onSelectOption(value) {
      this.loanAmount = value;
      if (this.loanAmount !== null) {
        this.saveFormData({
          loanAmount: this.loanAmount,
        });
        this.$router.push({ path: '/users/2' });
      }
    },
  },
  computed: {
    ...mapGetters(['formData', 'auth']),
  },
  created() {
    this.loanAmount = this.formData.loanAmount || null;
  },
  mounted() {
    this.registerPoint('shortFormStart');
  },
};
</script>
