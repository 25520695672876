export default [
  {
    name: 'tos',
    path: 'tos',
    component: () => import(/* webpackChunkName: "legal" */ '../view/frontend/Tos.vue'),
    alias: ['/legal'],
  },
  {
    name: 'privacy',
    path: 'privacy',
    component: () => import(/* webpackChunkName: "legal" */ '../view/frontend/Privacy.vue'),
  },
  {
    name: 'california',
    path: 'california',
    component: () => import(/* webpackChunkName: "legal" */ '../view/frontend/California.vue'),
  },
  {
    name: 'disclaimer',
    path: 'disclaimer',
    component: () => import(/* webpackChunkName: "legal" */ '../view/frontend/Disclaimer.vue'),
  },
  {
    name: 'e-consent',
    path: 'e-consent',
    component: () => import(/* webpackChunkName: "legal" */ '../view/frontend/Econsent.vue'),
  },
];
