import { mapGetters } from 'vuex';
import BackgroundImage from '@/design/frontend/LeapTheory/Blank/components/BackgroundImage';

export default {
  components: {
    BackgroundImage,
  },
  data(){
    return {
      isSmallDisplay: false,
      smallDisplayMaxWidth: 640,
      largeImg: 'hero',
      smallImg: 'hero/sm', 
    }
  },
  methods: {
    checkDisplaySize(){
      this.isSmallDisplay = window.innerWidth < this.smallDisplayMaxWidth;
    },
  },
  computed: {
    ...mapGetters(['getImageSrcByPlace']),
    heroImage(){ 
      let url = null;
      if(this.isSmallDisplay) {
        url = this.getImageSrcByPlace(this.smallImg);
      }
      return url ? url : this.getImageSrcByPlace(this.largeImg);
    },
  },
  mounted () {
    this.checkDisplaySize();
    window.addEventListener('resize', this.checkDisplaySize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDisplaySize);
  },
}