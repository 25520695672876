<template>
  <!-- eslint-disable max-len -->
  <div class="control-group select-control-group">
      <label v-if="label" class="control-group__label" :for="id" v-show="value" :class="{'control-group__label_focused' : this.focused || value}">{{label}}</label>
      <div class="select-container" :class="[ error && 'invalid', value && 'valid' ]">
        <select
            v-bind="$attrs"
            v-bind:value="value"
            v-on="inputListeners"

            class="defaultoption dontmark"
            :class="[ error && 'invalid', value && 'valid', extracss ]"
            :id="id"
            :name="name"
        >
            <option selected disabled hidden value=''>{{label}}</option>
            <option v-for="option in formattedOptions" :value="option.value" :key="option.value">{{option.label}}</option>
        </select>
        <div v-if="help" class="control-group__optional-field-helper" v-html="help"></div>
        <div v-if="error" class="help control-group__input-error">{{ errormsg || error }}</div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Select",
  inheritAttrs: false,
  $_veeValidate: {
    // value getter
    value() {
      return this.$el.value;
    },
    // name getter
    name() {
      return this.name;
    }
  },
  props: {
    label: String,
    id: String,
    name: String,
    errormsg: String,
    help: String,
    extracss: String,
    value: {
      type: null,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    options: {
        type: Array,
        default: []
    }
  },
  data() {
    return {
      focused: false,
      touched: false,
      formattedOptions: []
    };
  },
  created(){
    if(typeof this.options[0] !== 'object'){
      this.options.forEach(option => {
        this.formattedOptions.push({
          value: option,
          label: option
        });
      });
    } else {
      this.formattedOptions = this.options;
    }
  },
  computed: {
    inputListeners: function() {
      var vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: function(event) {
            vm.$emit("input", event.target.value);
          },
          focus: function(){
              vm.focused = true;
              vm.touched = true;
          },
          blur: function() {
              vm.focused = false;
              vm.dirty = true;
          }
        }
      );
    }
  }
};
</script>
