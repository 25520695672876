<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper
    :chatLoading="chatLoading"
    loaderMessage="I'm just checking your eligibility now..."
    message="Congratulations you're eligible to make a request, so let's continue. We'll start by collecting your contact information">
    <form v-if="!chatLoading" @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <BasicInput
          label="What's your email?"
          id="email"
          name="email"
          type="email"
          v-model="email"
          :exval="validationAttempt"
          v-validate="'required|email'"
          :error="errors.first('email')"
        />
      </fieldset>

      <AgreeTerms class="form__note form__note_narrow txt-grey"/>

      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'Email',
  title: 'Apply | Step 6',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      chatLoading: true,

      email: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'markStepComplete', 'checkEmail']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            email: this.email.toLowerCase(),
          });
          this.sendData();
          return;
        }
        this.saving = false;
      });
    },
    async sendData() {
      try {
        this.loading = true;
        await this.checkEmail({ email: this.email.toLowerCase() });
        if (this.isEmailRecognized) {
          this.markStepComplete('apply6');
          this.$router.push({ name: 'welcome' });
        } else {
          this.completeStep();
        }
      } catch (err) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(['formData', 'isEmailRecognized']),
  },
  created() {
    setTimeout(() => {
      this.chatLoading = false;
    }, 300);
    this.email = this.formData.email || '';
  },
};
</script>
