<template>
  <!-- eslint-disable max-len -->
    <article>
        <div class="cms-page">
            <div class="cms-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <a href="#">Legal</a> &raquo; <a href="#">E-Consent</a>
            </div>
            <EconsentContent/>
            <div class="cms-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>
import { mapGetters } from 'vuex';
import EconsentContent from './contents/Econsent';

export default {
  name: 'Econsent',
  title: 'E-Consent',
  computed: mapGetters(['pageContext']),
  components: { EconsentContent },
};
</script>

<style>

</style>
