export default {
  setServerContext:(state, context)=>{
    state.logo = context.logo;
    state.config = context.config;

    state.pageContext = {
      url_name: context.website.url_name,
      site_name: context.website.name,
      brand_name_llc: context.brand.name_llc,
      brand_name: context.brand.name,
      brand_name_llc_caps: context.brand.name_llc.toUpperCase(),
      brand_name_caps: context.brand.name.toUpperCase(),
      brand_address: context.brand.address,
      brand_email: context.brand.email,
      brand_unsubscribe: context.brand.unsubscribe_email,
      brand_compliance: context.brand.compliance_email,
      website_slogan: context.website.website_slogan,
      loans_slogan: context.website.loans_slogan,
      chat_agent: context.website.chat_agent,
      webmasterId: context.webmasterId,
      callCenter: context.callCenter,
    };
    state.website = context.website;
    state.loanAmounts = context.loanAmounts;
    state.partners = context.partners;
    state.csrfToken = context.csrfToken;
  },
}
