import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loanAmount: null,
    };
  },
  computed: mapGetters(['loanAmounts']),
  created() {
    const parsedQueryAmount = parseInt(this.$route.query.amount, 10);
    if (!Number.isNaN(parsedQueryAmount)) {
      const amount = this.loanAmounts.find((o) => parsedQueryAmount >= o.min && parsedQueryAmount <= o.max);
      if (amount) {
        this.loanAmount = amount.value;
      } else {
        this.loanAmount = parsedQueryAmount < this.loanAmounts[0].min
          ? this.loanAmounts[0].value
          : this.loanAmounts[this.loanAmounts.length - 1].value;
      }
    }
  },
};
