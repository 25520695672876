export default {
  path: "*",
  components: {
    default: () =>
      import(
        /* webpackChunkName: "error404" */ "../../Blank/views/Error404.vue"
      ),
    wrapper: () =>
      import(
        /* webpackChunkName: "error404" */ "../../Blank/views/one-column-right.vue"
      ),
  },
  props: {
    wrapper: { formType: "form_interstitial" },
  },
};
