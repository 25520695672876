<template>
  <wizard-wrapper
    message="Let's get to know you a little better, what's your name?"
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <BasicInput
          label="What's your first name?"
          id="firstName"
          name="firstName"
          v-model="firstName"
          v-validate="'required'"
          :exval="validationAttempt"
          errormsg="This is a required field"
          :error="errors.first('firstName')"
        />

        <BasicInput
          label="What's your last name?"
          id="lastName"
          name="lastName"
          v-model="lastName"
          v-validate="'required'"
          :exval="validationAttempt"
          errormsg="This is a required field"
          :error="errors.first('lastName')"
        />
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'CustomerName',
  title: 'Apply | Step 7',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,

      firstName: '',
      lastName: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'registerPoint']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            firstName: this.firstName,
            lastName: this.lastName,
          });
          this.completeStep();
          return;
        }

        this.saving = false;
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.firstName = this.formData.firstName || '';
    this.lastName = this.formData.lastName || '';
  },
  mounted() {
    this.registerPoint('longFormStart');
  },
};
</script>
