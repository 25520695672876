<template>
  <!-- eslint-disable max-len -->
  <section id="faq" class="faq bg-grey">
    <div class="faq__container">
      <h2 class="faq__header uc">Frequently Asked Questions</h2>
      <div class="faq__content-wrapper">
        <div class="faq__content">
          <div v-for="question in questions" :key="question.id" class="bg-white question">
            <h3
              class="question__title"
              :class="{'question__title_active':question.active}"
              @click="setActive(question.id)"
            >{{question.title}}</h3>
            <div class="question__answer"
                 :class="{'question__answer_open':question.active}">
              {{question.answer}}
            </div>
          </div><!--End Question-->

        </div><!--End Col 8-->
      </div><!--End Row-->
      <CallToActionButton :mode="mode"/>
    </div><!--End Container-->
  </section>
</template>

<script>
import CallToActionButton from './CallToActionButton';

export default {
  name: 'FAQ',
  components: {
    CallToActionButton,
  },
  props: {
    mode: {
      type: String,
      default: 'history',
    },
  },
  methods: {
    setActive(id) {
      const index = this.questions.findIndex((question) => question.id === id);
      const { active } = this.questions[index];
      this.questions[index].active = !active;
    },
  },
  data() {
    return {
      questions: [
        {
          id: 1,
          title: 'What is a Personal Loan?',
          // eslint-disable-next-line max-len,vue/max-len
          answer: 'Personal loans are generally loans without collateral (hence the name personal). Personal loans can be used for any purpose, but common uses include debt consolidation, credit card payoffs, etc.. It may go without saying but personal loans should be used responsibly. Be sure to read the terms before accepting.',
          active: true,
        },
        {
          id: 2,
          title: 'What is a Short Term Loan?',
          // eslint-disable-next-line max-len,vue/max-len
          answer: 'Short term loans are designed around addressing an immediate financial need or emergency. This could be an unexpected medical bill, a broken down car, etc. The loan amount range is usually up to $1,000 and the term can range from under 30 days for a payday style loan to monthly or weekly installments for an installment style loan.',
          active: false,
        },
        {
          id: 3,
          title: 'Why are you collecting my SSN and Bank Details?',
          // eslint-disable-next-line max-len,vue/max-len
          answer: 'Our network of lenders use your SSN and bank details to help them make a decision about whether or not to extend you a loan offer. The bank details also help the lender that you are connected to know how to send money to your account. Our websites are encrypted using industry recognized encryption technology to help keep your information safe. Additionally, lenders use the SSN to help ensure the identity of the applicant is really the person they say they are.',
          active: false,
        },
        {
          id: 4,
          title: 'Are you a lender?',
          // eslint-disable-next-line max-len,vue/max-len
          answer: "No, we are not a lender. We work with several lenders to make up a network that can help you to find a loan. After submitting a request, if accepted, we will forward you to the lender's website where you will be able to learn more about your specific loan offer.",
          active: false,
        },
      ],
    };
  },
};
</script>
