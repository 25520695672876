<template>
  <!-- eslint-disable max-len -->
    <article>
        <div class="cms-page">
            <div class="cms-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <a href="#">Legal</a> &raquo; <a href="#">Privacy Policy</a>
            </div>
            <PrivacyContent/>
            <div class="cms-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>
import { mapGetters } from 'vuex';
import PrivacyContent from './contents/Privacy';

export default {
  name: 'Privacy',
  title: 'Privacy Policy',
  computed: mapGetters(['pageContext']),
  components: { PrivacyContent },
};

</script>
