<template>
  <wizard-wrapper>
    <template v-slot:message>
      Great, we're almost finished. Next we need to collect your banking information for the
      <strong>{{ formData.bankAccountType }} account</strong> you will receive funds if your request is successful
    </template>
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions :value="monthsAtBank"
                      @input="onSelectOption"
                      label="How long have you had the account?"
                      :optionsList="[
                        {id: '12', label: 'One year or less'},
                        {id: '24', label: '2 years'},
                        {id: '36', label: '3 years'},
                        {id: '48', label: '4 years'},
                        {id: '60', label: '5 years'},
                        {id: '72', label: '6 years'},
                        {id: '84', label: '7 years or more'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'MonthsAtBank',
  title: 'Apply | Step 22',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      monthsAtBank: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.monthsAtBank = value;
      this.saving = true;
      this.saveFormData({
        monthsAtBank: this.monthsAtBank,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.monthsAtBank = this.formData.monthsAtBank || '';
  },
};
</script>
