export default {
  loanAmounts: (state) => state.loanAmounts,
  partners: (state) => state.partners,
  repostLoanAmount:(state) => state.loanAmounts.find(a => a.isRepost),
  pageContext: (state) => state.pageContext,
  getBoostUrl: (state) => {
    return (payload)=> {
      const defaultBoostData = {
        a: payload.a || '150',
        oc: payload.oc || '524',
        c: payload.c || '7255',
        m: payload.m || '26',
        load: payload.load || '',
        s1: payload.s1 || state.website.code.toLowerCase(),
        s2: payload.s2 || '',
        s3: payload.s3 || '',
        s4: payload.s4 || '',
        s5: payload.s5 || '',
      };
      const url = new URL('https://ecdtrk.com');

      Object.entries(defaultBoostData).forEach((item) => {
        if (item[1] !== -1) {
          url.searchParams.set(...item);
        }
      });

      return url.toString();
    }
  },
  csrfToken: (state) => state.csrfToken,
  logo: (state) => state.logo,
  config: (state) => state.config,
  getImageSrcByPlace: (state) => {
    return (place_code) => {
      const image = state.website.images.find(i => i.place_code === place_code);
      return image ? image.url: null;
    }
  },
  operatorImageSrc: (state) => state.website ? state.website.operator.imageUrl : null,
  operatorName: (state) => state.website ? state.website.operator.name : "",
  labels: (state) => state.labels,
  oneSignalAppId: (state) => { return state.config?.push?.oneSignal?.appId }
}
