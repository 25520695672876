<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper :percentageProp="98">
    <template v-slot:message>
      <h3 class="mb-3">This is the final step</h3>
      Please check the information below is correct then submit your loan request.
    </template>

    <form @submit.prevent="validateBeforeSubmit">

      <fieldset class="mb-3">
        <Select
          label="What is your monthly income?"
          id="monthlyIncome"
          name="monthlyIncome"
          v-model="monthlyIncome"
          v-validate="'required'"
          :options="incomeAmounts"
          errormsg="This is a required field"
          :error="errors.first('monthlyIncome')"
        />
      </fieldset>

      <div class="summary-info" v-if="!editing">
        <div class="summary-info__header">Your Details</div>
        <div class="summary-info__content">
          <div class="summary-info__row">
            <div class="summary-info__column">
              <div class="summary-info__field_mb-4">
                <div class="p12">Email</div>
                <div class="p16">{{ auth.userDetails.email }}</div>
              </div>

              <div class="summary-info__field_mb-4">
                <div class="p12">Phone</div>
                <div class="p16">{{ formattedHomePhone }}</div>
              </div>

              <div>
                <div class="p12">Address</div>
                <div class="p16">{{ auth.userDetails.city }}, {{ auth.userDetails.state }} {{ auth.userDetails.zip }}
                </div>
              </div>
            </div><!--End Col-->
            <div class="summary-info__column">
              <div class="summary-info__field_mb-4">
                <div class="p12">Routing Number / Bank Account</div>
                <div class="p16">{{ auth.userDetails.bankRoutingNumber }} / {{ auth.userDetails.bankAccountNumber }}
                </div>
              </div>

              <div class="summary-info__field_mb-4">
                <div class="p12">Employer</div>
                <div class="p16">{{ auth.userDetails.employerName }}</div>
              </div>

              <div class="">
                <div class="p12">Monthly Income</div>
                <div class="p16">${{ auth.userDetails.monthlyIncome }}</div>
              </div>
            </div><!--End Col-->
          </div><!--End Row-->
        </div><!--End Card Body-->
        <div class="summary-info__footer card-footer light p14">
          <strong>Something not accurate?</strong> &nbsp;<a href="#" @click.prevent="editDetails">Click here to update
          your details</a>
        </div>
      </div><!--End Card-->

      <div v-if="editing" class="pt-4">
        <h4 class="uc">Personal Information</h4>
        <fieldset class="form__fieldset">
          <BasicInput
            label="What's your first name?"
            id="firstName"
            name="firstName"
            v-model="firstName"
            v-validate="'required'"
            :exval="validationAttempt"
            errormsg="This is a required field"
            :error="errors.first('firstName')"
          />

          <BasicInput
            label="What's your last name?"
            id="lastName"
            name="lastName"
            v-model="lastName"
            v-validate="'required'"
            :exval="validationAttempt"
            errormsg="This is a required field"
            :error="errors.first('lastName')"
          />

          <CleaveInput
            label="What's your phone number?"
            id="phone"
            name="phone"
            type="tel"
            maxlength="12"
            help="Format: 999-999-9999"
            :cleaveoptions="masks.phone"
            v-model="phone"
            v-validate="{ required: true, validateAreaCode: true, regex: /^[0-9]{3}\-[0-9]{3}\-[0-9]{4}$/ }"
            :exval="validationAttempt"
            :error="errors.first('phone')"
          />

          <DisabledInput
            id="ssn"
            name="ssn"
            label="What's your SSN?"
            :value="ssn"
            autocomplete="off"
          />

          <div class="form-row">
            <div class="form-row__inner form-row__inner_size_lg-6 ">
              <DisabledInput
                id="dob"
                name="dob"
                label="Date of Birth"
                value="**/**/****"
              />
            </div>
          </div>
        </fieldset>

        <h4 class="uc">Address Information</h4>
        <fieldset class="form__fieldset">
          <BasicInput
            label="What's your address?"
            id="address"
            name="address"
            v-model="address"
            v-validate="'required'"
            :exval="validationAttempt"
            errormsg="This is a required field"
            :error="errors.first('address')"
          />
          <div class="form-row">
            <div class="form-row__inner form-row__inner_size_lg-6 ">
              <CleaveInput
                label="What's your zipcode?"
                id="zipcode"
                name="zipcode"
                maxlength="5"
                type="tel"
                v-model="zipcode"
                v-validate="{ required: true, regex: /^[0-9]{5}$/ }"
                :exval="validationAttempt"
                :cleaveoptions="masks.zipcode"
                errormsg="This is a required field"
                :error="errors.first('zipcode')"
              />
            </div>
            <div class="form-row__inner form-row__inner_size_lg-6 ">
              <Select
                label="What's your state?"
                id="address_state"
                name="address_state"
                v-model="address_state"
                v-validate="'required'"
                :options="statesList"
                errormsg="This is a required field"
                :error="errors.first('address_state')"
              />
            </div>
          </div>
          <BasicInput
            label="What's your city?"
            id="city"
            name="city"
            v-model="city"
            v-validate="'required'"
            :exval="validationAttempt"
            errormsg="This is a required field"
            :error="errors.first('city')"
          />
          <Select
            label="Time spent at this address?"
            id="monthsAtAddress"
            name="monthsAtAddress"
            v-model="monthsAtAddress"
            v-validate="'required'"
            :options="[{value: '84', label: '7 years or more'}, {value: '72', label: '6 years'}, {value: '60', label: '5 years'}, {value: '48', label: '4 years'}, {value: '36', label: '3 years'}, {value: '24', label: '2 years'}, {value: '12', label: 'One year or less'}]"
            errormsg="This is a required field"
            :error="errors.first('monthsAtAddress')"
          />

          <RadioOptions v-model="homeOwnership"
                        label="Do you own or rent?"
                        :optionsList="[
                        {id: 'rent', label: 'I rent'},
                        {id: 'own', label: 'I own'}]"
          />
        </fieldset>

        <h4 class="uc">License/ID Information</h4>
        <fieldset class="form__fieldset">
          <div class="form-row">
            <div class="form-row__inner form-row__inner_size_lg-6 ">
              <DisabledInput
                id="driversLicense"
                name="driversLicense"
                label="Driver's license/State ID"
                :value="driversLicense"
                autocomplete="off"
              />
            </div>
            <div class="form-row__inner form-row__inner_size_lg-6 ">
              <DisabledInput
                id="driversLicenseState"
                name="driversLicenseState"
                label="State issued"
                :value="driversLicenseState"
              />
            </div>
          </div>
        </fieldset>

        <h4 class="uc">Employment Information</h4>
        <fieldset class="form__fieldset">
          <RadioOptions v-model="employmentType"
                        label="What's your income type?"
                        :optionsList="[
                        {id: 'employed', label: 'Employed'},
                        {id: 'benefits', label: 'Benefits'},
                        {id: 'self-employed', label: 'Self-Employed'},
                        {id: 'military', label: 'Military or Dependent'}]"
          />

          <RadioOptions v-model="payFrequency"
                        label="How frequently are you paid?"
                        :optionsList="[
                        {id: 'weekly', label: 'Weekly'},
                        {id: 'biweekly', label: 'Bi-Weekly'},
                        {id: 'semi-monthly', label: 'Twice Monthly'},
                        {id: 'monthly', label: 'Monthly'}]"
          />

          <RadioOptions v-model="payType"
                        label="How are you paid?"
                        :optionsList="[
                        {id: 'direct_deposit', label: 'Direct Deposit'},
                        {id: 'check', label: 'Paper Check'}]"
          />
        </fieldset>
        <fieldset class="form__fieldset">
          <BasicInput
            label="What's your employer's name?"
            id="employerName"
            name="employerName"
            v-model="employerName"
            v-validate="'required'"
            :exval="validationAttempt"
            errormsg="This is a required field"
            :error="errors.first('employerName')"
          />
          <Select
            :label="labels.monthsEmployedLabel"
            id="monthsEmployed"
            name="monthsEmployed"
            v-model="monthsEmployed"
            v-validate="'required'"
            :options="[{value: '84', label: '7 years or more'}, {value: '72', label: '6 years'}, {value: '60', label: '5 years'}, {value: '48', label: '4 years'}, {value: '36', label: '3 years'}, {value: '24', label: '2 years'}, {value: '12', label: 'One year or less'}]"
            errormsg="This is a required field"
            :error="errors.first('monthsEmployed')"
          />
          <div class="form-row">
            <div class="form-row__inner form-row__inner_size_lg-6 ">
              <CleaveInput
                label="Work phone number"
                id="workPhone"
                name="workPhone"
                type="tel"
                maxlength="12"
                help="Lenders require this to verify employment, you must provide an accurate number to obtain a loan.<br><br>Format: 999-999-9999."
                :cleaveoptions="masks.workPhone"
                v-model="workPhone"
                v-validate="{ required: true, validateAreaCode: true, regex: /^[0-9]{3}\-[0-9]{3}\-[0-9]{4}$/ }"
                :exval="validationAttempt"
                :error="errors.first('workPhone')"
              />
            </div>
            <div class="form-row__inner form-row__inner_size_lg-3">
              <BasicInput
                label="Extension"
                id="workExt"
                name="workExt"
                type="tel"
                v-model="workExt"
                :exval="validationAttempt"
                :error="errors.first('workExt')"
              />
            </div>
          </div>
        </fieldset>

        <h4 class="uc">Bank Information</h4>
        <fieldset class="form__fieldset">
          <DisabledInput
            id="bankAccountType"
            name="bankAccountType"
            label="What's your account type?"
            :value="bankAccountType"
          />
          <div class="form-row">
            <div class="form-row__inner form-row__inner_size_lg-6">
              <DisabledInput
                id="bankRoutingNumber"
                name="bankRoutingNumber"
                :value="bankRoutingNumber"
                label="ABA / Bank routing number"
              />
            </div>
            <div class="form-row__inner form-row__inner_size_lg-6">
              <DisabledInput
                id="bankAccountNumber"
                name="bankAccountNumber"
                :value="bankAccountNumber"
                label="Bank account number"
                autocomplete="off"
              />
            </div>
          </div>
          <DisabledInput
            id="bankName"
            name="bankName"
            :value="bankName"
            label="What's your bank's name?"
          />
        </fieldset>
      </div>
      <fieldset>
        <RepostCheckbox
          class="mb-3"
          id="repost"
          v-model="repost"
          :currentLoanAmount="loanAmount"
        />
      </fieldset>
      <input
        type="hidden"
        name="additionalField"
        id="additionalField"
        ref="additionalField"
        value=""
      >

      <ESignatureNote class="light txt-grey p14" :phone="phone"/>

      <form-button class="form-button_my-5 form-button_flex-wrap" id="form-submit-short"
                   :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Sending Your Request...' : 'Submit Loan Request'"
                   :isProcessing="saving">
        <template v-slot:loader>
          <div v-if="saving" class="form-button__loader">
            <div class="dots">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </template>
        <template v-slot:errorMessage>
          <div v-if="errorMessage" class="alert alert-danger w-100 mt-4">
            There was an error submitting your application. Please try again later.
          </div>
        </template>
      </form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import WizardWrapper from '../../components/WizardWrapper';
import FormButton from '../../../base/components/FormButton';
import formsRouterHooks from '../../../base/mixins/formsRouterHooks';
// eslint-disable-next-line import/no-extraneous-dependencies
require('@root/node_modules/cleave.js/dist/addons/cleave-phone.us.js');

export default {
  name: 'Returning7',
  title: 'Welcome Back',
  mixins: [formsRouterHooks],
  components: {
    FormButton,
    WizardWrapper,
  },
  data() {
    return {
      validationAttempt: false,
      loading: false,
      editing: false,
      errorMessage: false,
      saving: false,
      repost: true,

      clientKey: '',

      email: '',
      loanAmount: null,

      firstName: '',
      lastName: '',
      phone: '',
      dob: '',

      address: '',
      zipcode: '',
      city: '',
      address_state: '',
      monthsAtAddress: '',
      homeOwnership: 'rent',
      driversLicense: '',
      driversLicenseState: '',

      employmentType: 'employed',
      employerName: '',
      monthsEmployed: '',
      monthlyIncome: '',
      payFrequency: 'biweekly',
      nextPaydate: '',
      secondPayDate: '',
      workPhone: '',
      workExt: '',
      payType: 'direct_deposit',

      debtRelief: false,

      bankAccountType: 'checking',
      bankRoutingNumber: '',
      bankAccountNumber: '',
      bankName: '',
      monthsAtBank: '',
      ssn: '',

      sid: '',
      bid: '',
      did: '',

      incomeAmounts: [
        {
          value: '6000', label: 'More than $5,000',
        },
        {
          value: '5000', label: '$4000 - $5000',
        },
        {
          value: '4000', label: '$3000 - $4000',
        },
        {
          value: '3000', label: '$2000 - $3000',
        },
        {
          value: '2000', label: '$1000 - $2000',
        },
        {
          value: '1000', label: '$1000 or less',
        },
      ],

      masks: {
        zipcode: { numericOnly: true, blocks: [5] },
        date: { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] },
        phone: { numericOnly: true, delimiter: '-', blocks: [3, 3, 4] },
        workPhone: { phone: true, phoneRegionCode: 'US', delimiter: '-' },
        ssn: { numericOnly: true, delimiter: '-', blocks: [3, 2, 4] },
        aba: { numericOnly: true, blocks: [9] },
      },
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'sendToAPI', 'registerPoint', 'markStepComplete', 'getCityState']),
    editDetails() {
      this.editing = true;
    },
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({

            clientKey: this.clientKey,

            email: this.email.toLowerCase(),

            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            dob: this.dob,

            address: this.address,
            zipcode: this.zipcode,
            city: this.city,
            address_state: this.address_state,
            monthsAtAddress: this.monthsAtAddress,
            homeOwnership: this.homeOwnership,
            driversLicense: this.driversLicense,
            driversLicenseState: this.driversLicenseState,

            employmentType: this.employmentType,
            employerName: this.employerName,
            monthsEmployed: this.monthsEmployed,
            monthlyIncome: this.monthlyIncome,
            payFrequency: this.payFrequency,
            nextPaydate: this.nextPaydate,
            secondPayDate: this.secondPayDate,
            workPhone: this.workPhone,
            workExt: this.workExt,
            payType: this.payType,

            bankAccountType: this.bankAccountType,
            bankRoutingNumber: this.bankRoutingNumber,
            bankAccountNumber: this.bankAccountNumber,
            bankName: this.bankName,
            monthsAtBank: this.monthsAtBank,
            ssn: this.ssn,
            additionalField: this.$refs.additionalField.value,
            repost: this.repost,

            sid: this.sid,
            bid: this.bid,
            did: this.did,
          });

          this.markStepComplete('returning');

          // Send to API replaced with push to Processing
          this.$router.push({ path: '/users/processing' });
        } else {
          this.saving = false;
        }
      });
    },
    formatPhoneNumber(phoneString = '') {
      const formatted = phoneString.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (formatted) {
        return `${formatted[1]}-${formatted[2]}-${formatted[3]}`;
      }
      return '';
    },
    formatBirthDate(dateString = '') {
      const formatted = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
      if (formatted) {
        return `${formatted[2]}/${formatted[3]}/${formatted[1]}`;
      }
      return '';
    },
    async setCityState(zipcode) {
      if (zipcode.length !== 5) {
        return;
      }
      try {
        const result = await this.getCityState(zipcode);
        if (result) {
          this.city = result.city;
          this.address_state = result.state;
        }
      } catch (err) {
        // console.log(err);
      }
    },
    isWeekend(date) {
      if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
        return true;
      }
      return false;
    },
    // eslint-disable-next-line consistent-return
    calculateSecondPaymentDate() {
      if (this.nextPaydate === '' || this.payFrequency === '') {
        return false;
      }
      const secondPayDate = moment(this.nextPaydate, 'YYYY-MM-DD');

      switch (this.payFrequency) {
      case 'weekly':
        secondPayDate.add(1, 'weeks');
        break;
      case 'biweekly':
        secondPayDate.add(2, 'weeks');
        break;
      case 'semi-monthly':
        secondPayDate.add(15, 'days');
        break;
      case 'monthly':
        secondPayDate.add(1, 'months');
        break;
      default:
        secondPayDate.add(1, 'months');
      }

      while (this.isWeekend(secondPayDate)) {
        secondPayDate.subtract(1, 'days');
      }
      this.secondPayDate = secondPayDate.format('YYYY-MM-DD');
    },
    snapIncome() {
      const income = Math.round(this.auth.userDetails.monthlyIncome / 1000) * 1000;
      const lv = this.incomeAmounts.length - 1;
      if (income < Number(this.incomeAmounts[lv].value)) {
        return this.incomeAmounts[lv].value;
      }
      if (income > Number(this.incomeAmounts[0].value)) {
        return this.incomeAmounts[0].value;
      }
      return income;
    },
  },
  watch: {
    zipcode: 'setCityState',
    nextPaydate: 'calculateSecondPaymentDate',
    payFrequency: 'calculateSecondPaymentDate',
  },
  computed: {
    ...mapGetters(['formData', 'auth', 'labels', 'statesList']),
    formattedHomePhone() {
      return this.formatPhoneNumber(this.auth.userDetails.homePhone);
    },
    formattedWorkPhone() {
      return this.formatPhoneNumber(this.auth.userDetails.workPhone);
    },
    formattedBirthDate() {
      return this.formatBirthDate(this.auth.userDetails.dob);
    },
    birthdate() {
      const date = new Date();
      const d = date.getDate() + 1 < 10 ? `0${date.getDate() + 1}` : date.getDate() + 1;
      const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      return `${m}/${d}/${date.getFullYear() - 18}`;
    },
    paymentDates() {
      let now = moment();
      const paymentDates = [];
      let thisDay = now.isoWeekday();
      const daysThisWeek = 5 - thisDay;
      const daysNextWeek = (7 - thisDay) + 5;
      let prefix = '';
      let count = 0;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 30; i++) {
        // Make it next day
        now = now.add(1, 'days');
        thisDay = thisDay === 7 ? 1 : thisDay + 1;
        switch (true) {
        case i === 0:
          prefix = 'Tomorrow, ';
          break;
        case count <= daysThisWeek:
          prefix = 'This ';
          break;
        case count > daysThisWeek && count <= daysNextWeek:
          prefix = 'Next ';
          break;
        default:
          prefix = '';
        }
        if (thisDay !== 6 && thisDay !== 7) {
          paymentDates.push({ value: now.format('YYYY-MM-DD'), label: prefix + now.format('dddd, MM-DD-YYYY') });
        }
        // eslint-disable-next-line no-plusplus
        count++;
      }
      return paymentDates;
    },
  },
  created() {
    this.clientKey = this.auth.userDetails.clientKey || '';

    this.email = this.auth.userDetails.email || '';
    this.zipcode = this.auth.userDetails.zip || '';
    this.loanAmount = this.formData.loanAmount || null;

    this.firstName = this.auth.userDetails.firstName || '';
    this.lastName = this.auth.userDetails.lastName || '';
    this.phone = this.formattedHomePhone || '';
    this.dob = this.formattedBirthDate || '';

    this.address = this.auth.userDetails.address || '';
    this.city = this.auth.userDetails.city || '';
    this.address_state = this.auth.userDetails.state || '';
    this.monthsAtAddress = this.auth.userDetails.monthsAtAddress || '';
    this.homeOwnership = this.auth.userDetails.homeOwnership || 'rent';
    this.driversLicense = this.auth.userDetails.driversLicense || '';
    this.driversLicenseState = this.auth.userDetails.driversLicenseState || '';

    this.employmentType = this.auth.userDetails.employmentType || 'employed';
    this.employerName = this.auth.userDetails.employerName || '';
    this.monthsEmployed = this.auth.userDetails.monthsEmployed || '';
    this.monthlyIncome = this.snapIncome();
    this.payFrequency = this.auth.userDetails.payFrequency || 'biweekly';
    this.workPhone = this.formattedWorkPhone || '';
    this.workExt = this.auth.userDetails.workExt || '';
    this.payType = this.auth.userDetails.payType || 'direct_deposit';

    this.bankAccountType = this.auth.userDetails.bankAccountType || 'checking';
    this.bankRoutingNumber = this.auth.userDetails.bankRoutingNumber || '';
    this.bankAccountNumber = this.auth.userDetails.bankAccountNumber || '';
    this.bankName = this.auth.userDetails.bankName || '';
    this.monthsAtBank = this.auth.userDetails.monthsAtBank || '';
    this.ssn = this.auth.userDetails.ssn || '';

    this.sid = this.auth.userDetails.sid || '';
    this.bid = this.auth.userDetails.bid || '';
    this.did = this.auth.userDetails.did || '';
  },
};
</script>
