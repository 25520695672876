export default {
  state: () => ({
    layoutPath: null,
  }),
  mutations: {
    layoutPath (state, payload) {
      state.layoutPath = payload
    },
  },
  actions: {
    parseServerContext: ({commit}, context) => {
      commit('layoutPath',context.layoutPath);
      commit('setServerContext',context);
    },
  },
}
