<template>
  <wizard-wrapper>
    <template v-slot:message>
      <div class="d-block d-lg-none">
        <h3 class="mb-1">Welcome Back {{ name }}!</h3>
        We may already have your information on file, please enter the last four digits of your <abbr
        title="Social Security Number">SSN</abbr> and zipcode and we'll pull up your details for a super fast
        loan submission!
      </div>
      <div class="d-none d-lg-block">
        <h3 class="mb-3">Welcome Back {{ name }}!</h3>
        We may already have your information on file, please enter the last four digits of your <abbr
        title="Social Security Number">SSN</abbr> and zipcode and we'll pull up your details for a super fast
        loan submission!
      </div>
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <div class="row">
        <div class="col-lg-10">
          <CleaveInput
            label="Last 4 digits of your SSN"
            id="ssnLast4"
            name="ssnLast4"
            type="tel"
            maxlength="4"
            autocomplete="off"
            v-model="ssnLast4"
            v-validate="{ required: true, regex: /^([0-9]{4})$/ }"

            :exval="validationAttempt"
            :cleaveoptions="masks.ssnLast4"

            extracss="dont-mark"

            errormsg="This is a required field"
            :error="errors.first('ssnLast4')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10">
          <CleaveInput
            label="What's your zipcode?"
            id="zipcodeR"
            name="zipcodeR"
            maxlength="5"
            type="tel"
            v-model="zipcode"
            :cleaveoptions="masks.zipcode"

            extracss="dont-mark"

            v-validate="{ required: true, regex: /^[0-9]{5}$/ }"
            :exval="validationAttempt"
            :error="errors.first('zipcode')"
          />
        </div>
      </div>
      <div v-if="errorMessage" class="alert alert-danger p14">
        Unfortunately we were unable to retrieve your record.
        <router-link to="/registration/1">Click Here to start a new request</router-link>
        .
      </div>

      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="sending ? 'Sending...' : 'Continue'"></form-button>

      <div class="text-primary">
        <small><a href="#" @click.prevent="closeLoginForm">Not you? Click here to start a new
          request</a></small>
      </div>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions } from 'vuex';
import tagManagerMixin from '@/code/Core/Bundle/GoogleTagManager/view/tagManagerMixin';
import WizardWrapper from '../WizardWrapper';
import FormButton from '../../../base/components/FormButton';

export default {
  name: 'HomeLoginForm',
  props: ['name'],
  components: { WizardWrapper, FormButton },
  mixins: [tagManagerMixin],

  data() {
    return {
      ssnLast4: '',
      zipcode: '',
      validationAttempt: false,
      sending: false,
      errorMessage: false,
      masks: {
        ssnLast4: { numericOnly: true, blocks: [4] },
        zipcode: { numericOnly: true, blocks: [5] },
      },
    };
  },
  methods: {
    ...mapActions(['closeLoginForm', 'verifyAgent']),
    validateBeforeSubmit() {
      this.validationAttempt = true;
      this.sending = true;
      this.$validator.validate().then((result) => {
        // @click="closeLoginForm"
        if (result) {
          this.verifyAgent({ zipcode: this.zipcode, ssnLast4: this.ssnLast4 }).then((response) => {
            if (response === true) {
              this.addTagManagerData({
                event: 'registerLogin',
                entry: 'homeAgent',
              });
              this.$router.push({ name: 'returning' });
            } else {
              this.errorMessage = true;
            }
          }).catch(() => {
            this.errorMessage = true;
          }).finally(() => {
            this.sending = false;
          });
        } else {
          this.sending = false;
        }
      });
    },
  },
};
</script>
