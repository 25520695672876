<template>
  <!-- eslint-disable max-len -->
    <button type="button" class="button-cta button-cta_color uc" @click="toggleLeadForm">Get Started</button>
</template>

<script>
export default {
  name: 'HomeLeadFormButton',
  methods: {
    toggleLeadForm() {
      this.$EventBus.emit('home.GetStarted');
    },
  },
};
</script>

<style>

</style>
