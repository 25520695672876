<template>
  <!-- eslint-disable max-len -->
  <div class="form">
    <h3 class="mb-5">ONE LAST STEP – Raise your FICO<sup>®</sup> Score+ instantly, for free with Experian Boost&#8482;*.</h3>
    <div class="light mb-5">
      <p>Although we have been unable to connect you with a lender so far, your odds of approval may increase if you are able to raise your <strong>FICO<sup>®</sup> Score.</strong> We recommend visiting Experian<sup>®</sup> to take immediate action to improve your FICO Score.</p>
      <p>We are proud to offer you a completely <strong>FREE</strong> way to check your <strong>FICO<sup>®</sup> Score</strong> and potentially increase it right now. <strong>Experian Boost&#8482;</strong> is <strong>COMPLETELY FREE</strong> with no credit card required. On average, people instantly raise their FICO Score by 12 points.</p>
      <p><strong>Click "Boost My FICO<sup>®</sup> Score"</strong> below to see what <strong>Experian Boost</strong> can do for you, then come back and tell us what your FICO Score is.</p>
      <div class="py-5">
        <a :href="url" id="form-submit-boost" class="form-button__button form-button__button_color">Boost My FICO Score</a>
      </div>
      <p>*Results may vary. Some may not see improved scores or approval odds. Not all lenders use Experian credit files, and not all lenders use scores impacted by Experian Boost.</p>
      <p>+ Credit score calculated based on FICO<sup>®</sup> Score 8 model. Your lender or insurer may use a different FICO<sup>®</sup> Score than FICO<sup>®</sup> Score 8, or another type of credit score altogether. <a :href="url">Learn more</a></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Boost',
  title: 'Boost',
  data() {
    return {
      url: '',
    };
  },
  computed: {
    ...mapGetters(['getBoostUrl']),
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    this.url = this.getBoostUrl({
      load: urlParams.get('key'),
      s1: urlParams.get('s1'),
      s2: urlParams.get('s2'),
      s3: urlParams.get('s3'),
      s4: urlParams.get('s4'),
      s5: urlParams.get('s5'),
    });
  },
};
</script>
