<template>
  <background-image :src="heroImage" tag="section" id="wizard" class="hero" >
    <div class="hero__solid ">
      <div class="hero__headers">
        <h1 class="hero__main-header uc grad-text" id="home-title">
          {{ pageContext.website_slogan || 'The Funds You Need Fast.' }}
        </h1>
        <h2 class="hero__sub-header uc">
          {{pageContext.loans_slogan || 'Loans from $100 - $5,000 are only moments away'}}
        </h2>
      </div>
      <div class="hero__content">
        <HomeLoginForm v-if="auth.useragentRecognised" :name="auth.userDetails.name" />
        <div v-else>
          <WizardEmailRecogniseHome v-if="auth.recognisedEmail" />
          <WizardStep1 v-else />
        </div>
      </div>
    </div>
  </background-image>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import HomeLoginForm from '@/code/FastCash/Bundle/LTConnector/view/frontend/chatBot/components/home/HomeLoginForm'
  import WizardStep1 from '@/code/FastCash/Bundle/LTConnector/view/frontend/chatBot/wizard/LoanAmount'
  import WizardEmailRecogniseHome
    from '@/code/FastCash/Bundle/LTConnector/view/frontend/chatBot/wizard/EmailRecogniseHome'
import heroBGMixin from '../../Blank/mixins/responsiveBackgroundMixin';

  export default {
    name: 'SectionHero',
    mixins: [heroBGMixin],
    components: {
      HomeLoginForm,
      WizardStep1,
      WizardEmailRecogniseHome,
    },
    computed: {
      ...mapGetters(['pageContext', 'auth']),
    },
    methods: {
      ...mapActions(['toggleLeadForm', 'saveSplitTestData']),
    },
    mounted () {
      this.saveSplitTestData({
        'frontendType': 'v2',
        'longFormType': 'chatbot-dynamic',
      })
    },
  }
</script>
