<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper :percentageProp="75">
    <template v-slot:message>
      Would you like to speak with a debt relief specialist about reducing your debt?
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <fieldset>
        <RadioOptions
          :value="debtRelief"
          @input="onSelectOption"
          label="Yes, I would like to consolidate my debt"
          :optionsList="[{id: 'true', label: 'Yes'}, {id: 'false', label: 'No'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import WizardWrapper from '../../components/WizardWrapper';
import formsRouterHooks from '../../../base/mixins/formsRouterHooks';

export default {
  name: 'Returning4',
  title: 'Welcome Back',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      debtRelief: false,
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    formatBirthDate(dateString = '') {
      const formatted = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
      if (formatted) {
        return `${formatted[2]}/${formatted[3]}/${formatted[1]}`;
      }
      return '';
    },
    onSelectOption(value) {
      this.debtRelief = value;
      if (this.debtRelief !== null) {
        this.saveFormData({
          additionalVerticals: {
            ...this.formData.additionalVerticals,
            debt_relief: this.debtRelief,
          },
        });
        if (this.age >= 64) {
          this.$router.push({ path: '/users/5b' });
        } else {
          this.$router.push({ path: '/users/6' });
        }
      }
    },
  },
  computed: {
    ...mapGetters(['auth', 'formData']),
    formattedBirthDate() {
      return this.formatBirthDate(this.auth.userDetails.dob);
    },
    age() {
      const dob = moment(this.formatBirthDate(this.auth.userDetails.dob), 'MM/DD/YYYY');
      return moment().diff(dob, 'years');
    },
  },
};
</script>
