import leapMessageConnectorChildren from '@/code/FastCash/Bundle/Unsubscribe/Resources/vue-routing'

export default {
  path: '/unsubscribe',
  children: leapMessageConnectorChildren,
  components: {
    wrapper: () => import(/* webpackChunkName: "unsubscribe" */ '../../Blank/views/one-column-right.vue'),
  },
  props: {
    wrapper: {
      logoClass: 'one-column-right__logo-outer_small-padding',
      formType: 'form_internal',
    },
  },
};
