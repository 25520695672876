<template>
  <!-- eslint-disable max-len -->
  <div class="wizard">
    <div class="wizard__inner">
      <StepsPercentage v-if="percentage!==0" :percentage="percentage" outerClass="wizard__progress" />
      <div class="form ">
        <div class="form__inner">
          <div v-if="chatLoading" :class="!chatLoading ? 'faded' : ''" class="chat-help">
            <div>
              <background-image :src="operatorImageSrc" class="chat-help__avatar chat-help__avatar_form"/>
            </div>
            <div class="chat-help__bubble">
              {{ loaderMessage }}
            </div>
          </div>

          <div class="chat-help">
            <div>
              <background-image :src="operatorImageSrc" class="chat-help__avatar chat-help__avatar_form"/>
            </div>
            <div class="chat-help__bubble">
              <div v-if="chatLoading" class="my-2">
                <div class="dots">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div v-else>
                <slot name="message">{{message}}</slot>
              </div>
            </div>
          </div>

          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BackgroundImage from '@/design/frontend/LeapTheory/Blank/components/BackgroundImage';

export default {
  name: 'WizardWrapper',
  components: { BackgroundImage },
  props: {
    loaderMessage: String,
    chatLoading: {
      type: Boolean,
      default: false,
    },
    message: String,
    percentageProp: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      percentage: 0,
    };
  },
  computed: {
    ...mapGetters(['steps', 'operatorImageSrc']),
  },
  mounted() {
    if (this.percentageProp !== null) {
      this.percentage = this.percentageProp;
    } else {
      this.percentage = Math.floor(((this.steps.indexOf(this.$route.name) + 1) * 100) / this.steps.length);
    }
  },
};
</script>
