<template>
  <wizard-wrapper
    message="Unfortunately without an active bank account we are unable to continue with your request.">
    <div class="offer-widget">
      <div class="offer-widget__content">
        <div class="offer-widget__row">
          <div class="offer-widget__col-right">
            <img src="../../base/assets/images/debit_card_phone.png" alt="Debit Card Mobile" class="offer-widget__img">
          </div>
          <div class="offer-widget__col-left">
            <h3>{{ greeting }}</h3>
            <p>We have an option for you to potentially get an online banking account through one of our partners.</p>
            <a :href="url" class="form-button__button form-button__button_color uc" id="ca-link">Get Started</a>
          </div>
        </div>
      </div>
    </div>
  </wizard-wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'WithoutBankAccount',
  title: 'Apply | Step 4',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  computed: {
    ...mapGetters(['formData', 'visitData', 'pageContext']),
    greeting() {
      return this.formData.firstName ? `Good News, ${this.formData.firstName}!` : 'Good News!';
    },
    url() {
      const urlParams = {
        utm_source: 'LeapTheoryRFNB',
        utm_medium: 'Affiliate',
        utm_content: 'afflink',
        utm_campaign: this.visitData.sessionID,
        utm_userid: this.visitData.webmasterID || this.pageContext.webmasterId || '',
      };
      const url = new URL('https://www.revolvefinance.com/affiliates/vC_NOBON/');

      Object.entries(urlParams).forEach((item) => {
        url.searchParams.set(...item);
      });
      return url.toString();
    },
  },
};
</script>
