import { Validator } from 'vee-validate';
import _ from 'lodash';
import { validators } from './helpers/validators';

export function registerValidator(validator) {
  _.each(validator, (v, k) => {
    Validator.extend(k, v);
  });
}

export function registerDefaultValidators() {
  registerValidator(validators);
}
