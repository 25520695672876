<template>
  <div class="form-group mb-0">
    <div class="mt-3 mb-2 check-label">{{label}}</div>
    <div class="radio-options" :class="[optionsList[0].icon ? 'radio-options-icons' : '', extracss]">
      <div class="radio-option"
           v-for="option in optionsList" :key="option.id"
           :class="value == option.id ? 'selected' : ''"
           @click="$emit('input', option.id)">
        <div v-if="option.icon" class="radio-option-icon">
          <i :class="`icon-${option.icon}`"></i>
        </div>
        <div class="radio-option-label">
          <slot v-bind:option="option">{{option.label}}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioOptions",
  props:{
    value:{
      type: null,
      require: true,
    },
    label: {
      type: String,
    },
    optionsList: {
      type: Array,
      require: true,
    },
    extracss: String,
  }
}
</script>
