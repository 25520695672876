<template>
  <!-- eslint-disable max-len -->
  <div>
    <h3 class="cms-page__header">Terms of Service</h3>
    <div class="cms-page__content">
        <p>{{pageContext.url_name}} is owned by {{pageContext.brand_name_llc_caps}} (“{{pageContext.brand_name_llc}}”) (“we, our, or us”). “{{pageContext.brand_name}}”, “us” or “we” means {{pageContext.brand_name_llc_caps}} (“{{pageContext.brand_name_llc}}”), its subsidiaries, affiliates, and any website operated by {{pageContext.brand_name_llc}}.</p>
        <p> We require that you carefully read, understand and agree to the following terms and conditions contained in this Terms of Use Agreement (“Terms of Use”). By accessing, browsing or using this website, and the websites of the below listed companies (“Websites”), or any page thereof, through any direct or indirect means, or by using the goods, facilities or services offered in or through the Websites (including through alternative methods such as telephone, mail, text, email or facsimile), you accept and agree to be bound by these Terms of Use.</p>
        <p><strong>THESE TERMS OF USE CONTAINS AN AGREEMENT TO ARBITRATE ALL CLAIMS AND CONTAINS DISCLAIMERS OF WARRANTIES AND LIABILITY (please see sections 9, 11 and 15). EXCEPT IF YOU OPT-OUT AND EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND {{pageContext.brand_name_llc}} WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. These provisions form an essential basis of our bargain.</strong></p>
        <p>If you do not agree to these Terms of Use, you are not authorized to access or use the Websites and you shall not access or otherwise use the Websites.<p>
        <p>For purposes of these Terms of Use, “You” or “Your” means the person(s) using the Websites, and/or the goods, facilities or services of {{pageContext.brand_name}} (“Services”) and its affiliates. “You” or “Your” also includes the person(s) that have given consent to others to provide information about themselves to {{pageContext.brand_name}} (as defined below), the banks, lenders, financial institutions, providers, brokers, aggregators, and providers of other goods and services (“Providers”).</p>
        <p>“{{pageContext.brand_name}}”, “us” or “we” means {{pageContext.brand_name_llc}}, its subsidiaries, affiliates, and any website operated by {{pageContext.brand_name}}. </p>
        <h4 class="my-4" id="1">1. Modification of Terms of Use</h4>
        <p>
        We have the right at any time to change or discontinue any aspect or feature of the Websites and the right to modify the terms and conditions applicable to users of the Websites, including these Terms of Use. Such changes, modifications, additions or deletions shall be effective immediately upon posting on the Websites. Any use by You of the Websites or the goods, facilities or services offered in or through the Websites shall be deemed to constitute acceptance of such changes.</p>
        <h4 class="my-4" id="2">2. Access and Use of Website</h4>
        <p>
        By accessing the Websites, you agree to be bound by these Terms of Use. The Websites are intended for individuals who are at least 18 years of age and reside in the United States and the District of Columbia. If you are under 18 years of age or reside outside of the United States or the District of Columbia, You are not authorized to use the Websites or to utilize. {{pageContext.brand_name}} Services.</p>
        <h4 class="my-4" id="3">3. Consumer Privacy Policy</h4>
        <p>
        The {{pageContext.brand_name}} Privacy Policy, hereby incorporated by reference into these Terms of Use, explains the policies applicable to the information that is collected through the Websites, received directly from You or transmitted to or from third parties.</p>
        <h4 class="my-4" id="4">4. Authorization to Obtain a Credit Report and Credit Score and to Verify Your Information</h4>
        <p>
        You understand and agree that by submitting Your loan request, You are providing written instruction under the Fair Credit Reporting Act authorizing us and/or the lenders and partners with whom Your request is shared to obtain your consumer credit report, credit score and/or other information from any consumer reporting agency in order to provide you with quotes and verify your identity. These lenders and partners may obtain your credit report, credit score and other information to make a final decision regarding extending a loan to you. Additionally, you understand and agree that Providers may use third party services to obtain information relating to you and to verify any information that you provide including, without limitation your social security number, address, phone number, bank account information and employment history.</p>
        <h4 class="my-4" id="5">5. Services Offered on the Site</h4>
        <h6>a. Marketplace for Lenders and Other Providers</h6>
        <p>
        The Website acts as an online marketplace for You to find third parties for the purpose of providing You products or services displayed on the Website. By clicking “submit” (or any button indicating acceptance), You are certifying that all of the information You have provided in Your loan request form is true, accurate and complete. Lenders and non-lenders (“Providers”) that participate in our network may use specific criteria, or filters, to identify the consumers with whom they would like to connect (for example, some Providers may limit their offers to consumers in certain states). The Providers also specify the prices they will pay {{pageContext.brand_name}} for the opportunity to present an offer to a consumer that meets the Providers’ criteria.</p>
        <p>When You submit a loan request, {{pageContext.brand_name}} transmits the information You entered in the form through the network in real time, presenting the information to Providers electronically until a Provider (often, the highest bidding Provider) accepts Your loan request. You will then be directed to that Provider’s website where it presents an offer for a loan (or other goods and services). </p>
        <p>If no Providers accept your loan request, we may present you with an offer to submit another loan request or an offer for a different product. We also may also share your contact information with third-party marketers that may contact you by email or SMS (in which case data and messaging rates may apply) to offer you loans or other products.</p>
        <p><strong>NOTE ABOUT TRIBAL LENDERS: By using this Website, You may be offered a loan by a tribal or marketplace lender. Such lenders may claim exemptions from state licensing and usury caps, based on tribal immunity or bank preemption. Please be aware that the rates and fees offered by these lenders may be higher than those offered by state-licensed lenders. In addition, for tribal lenders, You may be required to agree to resolve any disputes in a tribal jurisdiction. You are urged to read and understand the terms of any loan offered by any lender, regardless of its lending model, and to reject any particular loan offer that You cannot afford to repay or that includes terms that are not acceptable to You. By submitting your loan request, You agree that you have read and understand the statement above.</strong></p>
        <p><strong>{{pageContext.brand_name}} IS NOT A LENDER</strong>, does not broker loans to lenders and does not make credit decisions. This Website does not constitute an offer or solicitation to lend. By providing Your information on this Website, You are not guaranteed to be approved for a loan. Not all lenders can provide loans in the amounts advertised on the Websites. The amount of time to transfer funds may vary between lenders and may depend on Your individual financial institution. For details, questions or concerns regarding Your loan, please contact your lender directly. Lender services may not be available to residents of all states based on individual lender restrictions.</p>
        <h6>b. No Endorsement or Recommendation</h6>
        <p>We do not endorse or recommend the services or products of any particular Provider. You should rely on your own judgment in deciding which available loan product, terms, lender and/or Provider best suits Your needs and financial situation.</p>
        <p>We do not guarantee that the loan terms or rates offered and made available by lenders are the best terms or lowest rates available in the market.</p>
        <p><strong>{{pageContext.brand_name}} IS NOT RESPONSIBLE OR LIABLE FOR ANY PROVIDER'S ACTS OR OMISSIONS INCLUDING FOR ANY QUOTES OR SERVICES THAT ANY SUCH SERVICE PROVIDER MAY PROVIDE, FOR ANY PROVIDER'S CONTACTING OR FAILURE TO CONTACT YOU, FOR ANY PROVIDER'S PERFORMANCE OR FAILURE TO PERFORM ANY SERVICES, OR FOR ANY AGREEMENT OR TRANSACTION BETWEEN YOU AND ANY PROVIDER.</strong></p>
        <h6>c. Data Sharing with Lenders, Provider and Third Parties</h6>
        <p>When You submit a loan request, You understand that we share the information in Your loan request form with Providers. Providers may keep the information in your loan request form, and any other information provided by us or received by them in the processing of your loan request, or other product or service. Providers may retain this information, whether or not you qualify for or take a loan with them, and whether or not you are qualified for the goods or services offered. You agree to notify any particular Provider directly if you no longer want to receive communications from them.</p>
        <p>In addition, {{pageContext.brand_name}} and Providers may share the information You provide to them with state licensing authorities and government regulators.</p>
        <p>Pursuant to contractual relationships between Providers and {{pageContext.brand_name}}, {{pageContext.brand_name}} may receive information from your Provider regarding the status of your application including, but not limited to, the amount or disposition of your loan. Information specific to your Lender and/or Providers use of your information will be found in your Lender and/or Provider’s specific Privacy Policy. NOTE: You are providing express written consent for {{pageContext.brand_name}} and the selected Provider to share this private personally identifiable information for the following purposes: litigation, regulatory request(s), law enforcement requests, internal analytics and/or for marketing purposes.</p>
        <h6>d. Consent to Receive Communications via E-mail, Telephone Calls and/or SMS Messaging</h6>
        <p>By submitting a loan request through the Websites, You consent to be contacted by our marketing partners and by each Provider that we transmit Your information to, and their affiliates and service providers, by: (i) telephone at the numbers You have provided whether landline or cellular, even if your phone number is on any Do Not Call list and You agree to receive pre-recorded calls and/or calls made with an automatic dialing system; (ii) by email at the email address You provided or at another address that may be associated with You that we receive from other parties; You agree that any such email will not be considered spam or unauthorized by any local, state or federal law or regulation; and/ or (iii) by SMS messaging to the mobile phone number that You provide, in which case data and messaging rates may apply. Consent is not required in order to purchase goods and services from {{pageContext.brand_name}} or the Providers that reach out to You. <strong>By submitting a loan request through the Websites, You have established a business relationship between You and {{pageContext.brand_name}}.</strong></p>
        <p>By saving Your information with the Websites or by making a submission, You give us permission to retain all such provided information and to make live or recorded calls to discuss, provide or remind You of any information in regards to Your submission, including incomplete loan requests, the delivery of loan request offers, deadlines, quality of services or other matters in connection with your loan request or any other good or service. </p>
        <p><strong>If at any time you do not wish to continue to receive communications from {{pageContext.brand_name}} or its affiliates, you may contact {{pageContext.brand_name}} to opt out at <a :href="`mailto:${pageContext.brand_unsubscribe}`">{{pageContext.brand_unsubscribe}}</a>. If you wish to discontinue communications from Providers, contact them directly.</strong></p>
        <h6>e. Identity Theft Prevention</h6>
        <p>{{pageContext.brand_name}} and/or Providers may obtain, verify and record information, such as name, Social Security Number, address, telephone number and date of birth, that will allow them to properly identify You. During the Provider’s formal application process, they also may ask to see Your driver’s license or other identifying documentation to further assist in certifying Your identity.</p>
        <h6>f. Compensation to {{pageContext.brand_name}}</h6>
        <p>{{pageContext.brand_name}} is paid a marketing lead generation fee by Providers, including lenders, for the goods, facilities and services provided. Your use of the Websites and/or {{pageContext.brand_name}}’s services constitutes Your agreement with this compensation arrangement. {{pageContext.brand_name}} does not charge You a fee for its service in connecting You with Providers.</p>
        <h6>g. Not Professional Advice</h6>
        <p>No substitute for professional advice. We provide Services solely for Your convenience, and such Services (i) are not intended as a substitute for professional advice; (ii) should not be construed as the provision of advice or recommendations; and (iii) should not be relied upon as the basis for any financial decision or action. We are not responsible for the accuracy or reliability of any Services. It is Your responsibility to evaluate the accuracy, completeness or usefulness of any Services available through the Websites. The relationship between You and us is not a professional or similar relationship; always seek the advice of a qualified professional with respect to any questions that You may have, and never disregard professional advice or delay in seeking it because of something that You have read on the Website.</p>
        <h4 class="my-4" id="6">6. Consent to Receive Electronic Disclosures and Communications</h4>
        <p>Although we may communicate with You by mail, we may also communicate with you by email or by posting notices on the Website. You agree to receive communications from us and our affiliates electronically and that all agreements, notices, disclosures and other communications that we provide to You electronically (“Disclosures and Communications”) satisfy any legal requirement that such communications be in writing. </p>
        <p>Providers also may be required by law to provide you with certain Disclosures and Communications. These Providers must obtain your consent in order to provide you with Disclosures and Communications electronically. By submitting a request on or through the Websites, You affirmatively consent and agree to receive all Disclosures and Communications required by law electronically in accordance with the terms of the <router-link :tag="internalLink ? 'a': 'span'" :event="internalLink ? 'click': ''" to="/legal/e-consent">Consent to Receive Electronic Disclosures and Communications</router-link>. PLEASE READ THE TERMS OF THE CONSENT CAREFULLY</p>
        <p>Disclosures and Communications may transmit or convey information about action taken on Your request, portions of Your request that may be incomplete or require additional explanation, any notices required under applicable law, which may include any Federal Truth-in-Lending disclosures, State disclosures, other terms, conditions and documents, and the privacy policies of {{pageContext.brand_name}}, and Providers.You have the right to receive any document in non-electronic form and to withdraw your consent to electronic delivery at any time by contacting {{pageContext.brand_name}} at {{pageContext.brand_unsubscribe}}</p>
        <p>By submitting a loan request through the Websites, You consent to be contacted by our marketing partners and by each Provider that we transmit Your information to, and their affiliates and service providers, by: (i) telephone at the numbers You have provided whether landline or cellular, even if your phone number is on any Do Not Call list and You agree to receive pre-recorded calls and/or calls made with an automatic dialing system; (ii) by email at the email address You provided or at another address that may be associated with You that we receive from other parties; You agree that any such email will not be considered spam or unauthorized by any local, state or federal law or regulation; and/ or (iii) by SMS messaging to the mobile phone number that You provide, in which case data and messaging rates may apply. Consent is not required in order to purchase goods and services from {{pageContext.brand_name}} or the Providers that reach out to You. <strong>By submitting a loan request through the Websites, You have established a business relationship between You and {{pageContext.brand_name}}.</strong></p>
        <p>By saving Your information with the Websites or by making a submission, You give us permission to retain all such provided information and to make live or recorded calls to discuss, provide or remind You of any information in regards to Your submission, including incomplete loan requests, the delivery of loan request offers, deadlines, quality of services or other matters in connection with your loan request or any other good or service. </p>
        <p><strong>If at any time you do not wish to continue to receive communications from {{pageContext.brand_name}} or its affiliates, you may contact {{pageContext.brand_name}} to opt out at <a :href="`mailto:${pageContext.brand_unsubscribe}`">{{pageContext.brand_unsubscribe}}</a>. If you wish to discontinue communications from Providers, contact them directly.</strong></p>
        <h4 class="my-4" id="7">7. Request for Other Products and Services (Including Finder/Locator Services)</h4>
        <p>{{pageContext.brand_name}} may allow You to request products and services other than loans on the Websites.</p>
        <h4 class="my-4" id="8">8. Use of the Website in the United States</h4>
        <p>The Website is controlled and/or operated from the United States, and are not intended to subject us to non-U.S. jurisdiction or laws, except as otherwise expressly stated in this Agreement. The Website may not be appropriate or available for use in some jurisdictions outside of the United States. In choosing to access the Website, You do so on Your own initiative and at Your own risk, and You are responsible for complying with all local laws, rules and regulations, including any export controls, embargoes or other rules and regulations restricting exports. We may limit the Website’s availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in our sole discretion.</p>
        <h4 class="my-4" id="9">9. Copyright, Trademark and Service Mark Notices</h4>
        <p>All contents of the Websites are subject to copyright and other protection. {{pageContext.brand_name}} is a trademark of {{pageContext.brand_name_llc}}. Other product and company names mentioned herein, including the names of Providers, may be the trademarks of their respective owners.</p>
        <p>Nothing on the Websites should be construed as granting, by implication, estoppel or otherwise, any license or right to use any service mark or trademark (individually and collectively, “Mark” or “Marks”) displayed on the Websites, without the prior written permission of {{pageContext.brand_name}} or the applicable Mark holder specific for each such use. The Marks may not be used to disparage {{pageContext.brand_name}}, the Providers, the applicable third party or the products or services of such parties, nor shall they be used in any manner that may damage any goodwill in the Marks. Use of any Mark as part of a link to or from any site is prohibited unless establishment of such a link is approved in advance by {{pageContext.brand_name}} in writing.</p>
        <p>Everything that appears on the Websites is protected under the copyright laws of the United States and other countries and may not be used except as provided in these Terms of Use. {{pageContext.brand_name}} neither represents nor warrants that Your use of materials displayed at the Websites will not infringe rights of third parties.</p>
        <h4 class="my-4" id="10">10. Disclaimers and Liability</h4>
        <p>The Websites and Services are subject to change without prior notice, and {{pageContext.brand_name}} has no ability to control the availability of any Provider’s good and services, which may change without prior notice. UNDER NO CIRCUMSTANCES WILL {{pageContext.brand_name}} BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED THROUGH THE WEBSITES. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION, OPINION, ADVICE OR OTHER CONTENT AVAILABLE THROUGH THE WEBSITES.</p>
        <p>THE WEBSITES AND THE INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ASSOCIATED WITH IT ARE PROVIDED “AS IS”. {{pageContext.brand_name}} AND/OR ITS SUPPLIERS, SERVICE PROVIDERS, LENDERS, OR PROVIDERS DISCLAIM ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO ANY MATTER WHATSOEVER RELATING TO THE WEBSITES AND ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES PROVIDED HEREIN, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. USE OF THE WEBSITES AND/OR {{pageContext.brand_name}}'s SERVICES IS AT YOUR OWN RISK. {{pageContext.brand_name}} AND/OR ITS SUPPLIERS, SERVICE PROVIDERS, LENDERS, OR PROVIDERS ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR THE INABILITY TO USE THE WEBSITES AND/OR {{pageContext.brand_name}}'s SERVICES OR WITH THE DELAY OR INABILITY TO USE THE WEBSITES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES OBTAINED THROUGH THE WEBSITES, DATA OR PERSONAL INFORMATION, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO {{pageContext.brand_name}}'s RECORDS, PROGRAMS OR SERVICES, OR OTHERWISE ARISING OUT OF THE USE OF THE WEBSITES, WHETHER RESULTING IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF {{pageContext.brand_name}} AND/OR ITS SUPPLIERS, OR PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>
        <h4 class="my-4" id="11">11. Indemnity</h4>
        <p>As a condition of use of the Websites and/or {{pageContext.brand_name}}'s services, You agree to indemnify {{pageContext.brand_name}} and its suppliers, and Providers from and against any and all liabilities, expenses (including attorneys' fees) and damages arising out of claims resulting from Your use of the Websites, including without limitation any claims alleging facts that if true would constitute a breach by You of these Terms of Use.</p>
        <h4 class="my-4" id="12">12. Limitation on Damages</h4>
        <p>In no event will {{pageContext.brand_name}} have any liability to You in excess of actual compensatory damages. You irrevocably waive any claim to consequential, punitive or exemplary damages.</p>
        <h4 class="my-4" id="13">13. Links to Third-Party Websites</h4>
        <p>{{pageContext.brand_name}} has not reviewed any websites that may be linked to the Websites and is not responsible for the content of any off-site pages or any other sites linked to the Website. Your linking to any other off-site pages or other sites is at Your own risk.</p>
        <h4 class="my-4" id="14">14. Errors and Delays</h4>
        <p>{{pageContext.brand_name}} is not responsible for any errors or delays in responding to a request or referral form caused by any reason, including but not limited to, an incorrect email address provided by You or other technical problems beyond our reasonable control.</p>
        <h4 class="my-4" id="15">15. Release and Discharge</h4>
        <p>YOU HEREBY RELEASE, REMISE AND FOREVER DISCHARGE {{pageContext.brand_name}} AND ITS SERVICE PROVIDERS, AND EACH OF THEIR RESPECTIVE AGENTS, DIRECTORS, OFFICERS, EMPLOYEES, INFORMATION PROVIDERS, SERVICE PROVIDERS, SUPPLIERS, LICENSORS AND LICENSEES, AND ALL OTHER RELATED, ASSOCIATED, OR CONNECTED PERSONS FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE AND KIND WHATSOEVER AND HOWSOEVER ARISING, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER EXIST, WHICH ARISE FROM, RELATE TO, OR ARE CONNECTED WITH YOUR USE OF THE WEBSITES.</p>
        <h4 class="my-4" id="16">16. Dispute Resolution</h4>
        <p>THIS AGREEMENT PROVIDES THAT ALL DISPUTES BETWEEN YOU AND {{pageContext.brand_name}} WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND NOT IN COURT OR BY JURY TRIAL. IF A DISPUTE IS ARBITRATED, YOU GIVE UP YOUR RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS CLAIM YOU MAY HAVE AGAINST {{pageContext.brand_name}} INCLUDING ANY RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS. DISCOVERY AND RIGHTS TO APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER RIGHTS AVAILABLE IN COURT MAY NOT BE AVAILABLE IN ARBITRATION.</p>
        <p>Except if You opt out or for disputes relating to Your relationship with the Websites, including disputes related to these Terms of Use (as provided for below), Your use of the Websites and/or rights of privacy and/or publicity will be resolved by binding, individual arbitration under the American Arbitration Association's rules for arbitration of consumer-related disputes and You and {{pageContext.brand_name}} hereby expressly waive trial by jury. </p>
        <p>As an alternative, You may bring your claim in your local “small claims” court, if permitted by that small claims court's rules. You may bring claims only on your own behalf. Neither You nor {{pageContext.brand_name}} will participate in a class action or class-wide arbitration for any claims covered by these Terms of Use. You also agree not to participate in claims brought in a private attorney general or representative capacity, or consolidated claims involving another person's account, if {{pageContext.brand_name}} is a party to the proceeding. </p>
        <p>This dispute resolution provision will be governed by the Federal Arbitration Act. In the event the American Arbitration Association is unwilling or unable to set a hearing date within one hundred and sixty (160) days of filing the case, then either {{pageContext.brand_name}} or You can elect to have the arbitration administered instead by the Judicial Arbitration and Mediation Services. Judgment on the award rendered by the arbitrator may be entered in any court having competent jurisdiction. Any provision of applicable law notwithstanding, the arbitrator will not have authority to award damages, remedies or awards that conflict with this Agreement.</p>
        <p>You may opt out of this agreement to arbitrate. If You do so, neither You nor {{pageContext.brand_name}} can require the other to participate in an arbitration proceeding. To opt out, You must notify {{pageContext.brand_name}} in writing within 30 days of the date that You first became subject to this arbitration provision. You must use this address to opt out:</p>
        <p>{{pageContext.brand_name_llc}}<br>
        ATTN: Arbitration Opt-out<br>
        <span v-html="pageContext.brand_address"></span></p>
        <p>You must include your name and residence address, the email address You used, and a clear statement that You want to opt out of this arbitration agreement.</p>
        <p>The arbitrator's award is final and binding on all parties, except that the losing party may request a new arbitration under the rules of the arbitration organization by a three-arbitrator panel.</p>
        <p>If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Clause will be null and void. This arbitration agreement will survive the termination of Your relationship with {{pageContext.brand_name}}.</p>
        <p>In any arbitration, {{pageContext.brand_name}} will pay the filing, administration, service or case management fee, plus the costs associated with the first day of arbitration, with the remaining costs to be paid by the non-prevailing party. {{pageContext.brand_name}} will pay additional arbitration expenses to the extent that the arbitrator determines that {{pageContext.brand_name}} must do so in order to ensure the enforceability of this Arbitration Clause. Each party shall be responsible for its own attorney, expert and other fees, unless awarded by the arbitrator under applicable law.</p>
        <p>Should a dispute arise and should the arbitration provisions herein become inapplicable or unenforceable, or in any instance of any lawsuit between You and {{pageContext.brand_name}}, the parties agree that jurisdiction over and venue of any suit shall be exclusively in the state and federal courts sitting in Los Angeles, CA, except in the event of a claim in small claims court as provided for herein. If either party employs attorneys to enforce any right in connection with any dispute or lawsuit, the prevailing party shall be entitled to recover reasonable attorneys' fees.</p>
        <h4 class="my-4" id="17">17. Other Terms</h4>
        <p>These Terms of Use constitute the entire agreement between You and {{pageContext.brand_name}} and supersede all prior or contemporaneous communications, promises and proposals, whether oral, written or electronic, between You and {{pageContext.brand_name}}.</p>
        <p>These Terms of Use shall be subject to and construed in accordance with the laws of the State of Delaware, excluding its conflict of laws principles. If any part of these Terms of Use is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid enforceable provision that most closely matches the intent of the original provision, and the remainder of these Terms of Use shall continue in effect. </p>
        <p>A printed version of these Terms of Use and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms of Use to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. All rights not expressly granted herein are reserved.</p>
        <p><strong>PLEASE PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS.</strong></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TosContent',
  props: {
    internalLink: {
      default: true,
      type: Boolean,
    },
  },
  computed: mapGetters(['pageContext']),
};
</script>
