<template>
  <!-- eslint-disable max-len -->
  <main>

    <div class="bg-grey" style="min-height: 100vh;">
      <Header/>
      <slot>
        <router-view/>
      </slot>
    </div>

    <slot name="content">
      <HowItWorks/>
      <WhyChoose/>
      <FooterCTA/>
      <FAQ/>
    </slot>

    <slot name="footer">
      <Footer/>
    </slot>

    <Disclaimers/>
  </main>
</template>

<script>
  // @ is an alias to /src
  import WhyChoose from '@/code/FastCash/Bundle/LTConnector/view/frontend/base/components/home/WhyChoose';
  import HowItWorks from '@/code/FastCash/Bundle/LTConnector/view/frontend/base/components/home/HowItWorks';
  import FAQ from '@/code/FastCash/Bundle/LTConnector/view/frontend/base/components/home/FAQ';
  import FooterCTA from '@/code/FastCash/Bundle/LTConnector/view/frontend/base/components/home/FooterCTA';
  import Footer from '../../Blank/components/Footer';
  import Disclaimers from '@/code/FastCash/Bundle/LTConnector/view/frontend/base/components/home/Disclaimers';
  import Header from '../components/Header.vue'

  export default {
    name: 'home',
    components: {
      FAQ,
      WhyChoose,
      HowItWorks,
      FooterCTA,
      Footer,
      Disclaimers,
      Header
    }
  };
</script>
