<template>
  <div>
    <div class="color-switch">
      <span @click="setColorScheme('light')" class="color-switch__icon color-switch__icon_light-mode" :class="{'color-switch__icon_active' : colorScheme == 'light'}"></span>
      <span @click="setColorScheme('dark')" class="color-switch__icon color-switch__icon_dark-mode" :class="{'color-switch__icon_active' : colorScheme == 'dark'}"></span>
      <span @click="setColorScheme('device')" class="color-switch__icon color-switch__icon_device-mode" :class="{'color-switch__icon_active' : colorScheme == 'device' || colorScheme == ''}"></span>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";

export default {
    name: 'ColorSwitch',
    methods: {
      ...mapActions(['setColorScheme']),
    },
    computed: {
        ...mapGetters(['colorScheme']),
    }
}
</script>
