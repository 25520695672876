<template>
  <wizard-wrapper
    message="How do you plan to use the money you'll receive?">
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions :value="loanPurpose"
                      @input="onSelectOption"
                      label="What’s the purpose of your loan?"
                      :optionsList="[
                        {id: 'debt consolidation', label: 'Debt Consolidation'},
                        {id: 'medical', label: 'Medical'},
                        {id: 'home improvement', label: 'Home Improvement'},
                        {id: 'credit card consolidation', label: 'Credit Card Consolidation'},
                        {id: 'auto purchase', label: 'Auto Purchase'},
                        {id: 'major', label: 'Major Purchase'},
                        {id: 'business', label: 'Start a Business'},
                        {id: 'vacation', label: 'Vacation'},
                        {id: 'wedding', label: 'Wedding'},
                        {id: 'other', label: 'Other'},]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'LoanPurpose',
  title: 'Apply | Step 2',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      loanPurpose: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.loanPurpose = value;
      this.saveFormData({
        loanPurpose: this.loanPurpose,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.loanPurpose = this.formData.loanPurpose || '';
  },
};
</script>
