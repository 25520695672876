var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-wrapper',{attrs:{"message":"How do you plan to use the money you'll receive?"}},[_c('form',[_c('fieldset',{staticClass:"form__fieldset"},[_c('RadioOptions',{attrs:{"value":_vm.loanPurpose,"label":"What’s the purpose of your loan?","optionsList":[
                      {id: 'debt consolidation', label: 'Debt Consolidation'},
                      {id: 'medical', label: 'Medical'},
                      {id: 'home improvement', label: 'Home Improvement'},
                      {id: 'credit card consolidation', label: 'Credit Card Consolidation'},
                      {id: 'auto purchase', label: 'Auto Purchase'},
                      {id: 'major', label: 'Major Purchase'},
                      {id: 'business', label: 'Start a Business'},
                      {id: 'vacation', label: 'Vacation'},
                      {id: 'wedding', label: 'Wedding'},
                      {id: 'other', label: 'Other'} ]},on:{"input":_vm.onSelectOption}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }