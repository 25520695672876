export default {
  data() {
    return {
      utm_source: '',
      utm_campaign: '',
      utm_medium: '',
      utm_content: '',
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.utm_source = urlParams.has('utm_source') ? urlParams.get('utm_source') : 'none';
    this.utm_campaign = urlParams.has('utm_campaign') ? urlParams.get('utm_campaign') : 'none';
    this.utm_medium = urlParams.has('utm_medium') ? urlParams.get('utm_medium') : 'none';
    this.utm_content = urlParams.has('utm_content') ? urlParams.get('utm_content') : 'none';
  },
};
