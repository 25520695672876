<template>
  <!-- eslint-disable max-len -->
    <article>
        <div class="cms-page">
            <div class="cms-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <a href="#">Legal</a> &raquo; <a href="#">Terms of Service</a>
            </div>
            <TosContent/>
            <div class="cms-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>
import { mapGetters } from 'vuex';
import TosContent from './contents/Tos';

export default {
  name: 'Tos',
  title: 'Terms of Service',
  computed: mapGetters(['pageContext']),
  components: { TosContent },
};
</script>

<style>

</style>
