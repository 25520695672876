<template>

  <div class="app-row">
    <input
      class="form-control"
      :class="{ 'invalid': error, 'valid': value }"
      :id="id"
      :name="name"
      :type="type"
      :value="displayValue"
      ref="input"
      @focus="focusField"
      @blur="blurField"
      @change="validateChange"
    >

    <div class="form-group mb-0">
      <div class="check-label">{{ label }}</div>
      <div class="radio-options">
        <div v-for="amount in loanAmounts"
             :key="amount.value"
             @click="selectAmount(amount)"
             class="radio-option active align-items-center"
             :class="value == amount.value ? 'selected' : ''"
        >
          {{ amount.label }}
        </div>
      </div>
      <div v-if="error" class="control-group__input-error">{{ errormsg || error }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoanSelect',
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    },
  },
  props: {
    label: String,
    id: String,
    name: String,
    errormsg: String,
    className: {
      type: String,
      default: 'form-control',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: null,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      focused: false,
    };
  },
  methods: {
    focusField(e) {
      this.focused = true;
      e.target.select();
    },
    blurField(e) {
      if (e.target.value === '') {
        this.selectAmount(this.loanAmounts[0])
      }

      setTimeout(() => {
        this.focused = false;
      }, 150);
    },
    selectAmount(amount) {
      this.$emit('input', amount.value);
    },
    validateChange(e) {
      const amounts = this.loanAmounts;
      const parsedValue = parseInt(e.target.value);
      if (!isNaN(parsedValue)) {
        if (parsedValue > amounts[amounts.length - 1].max) {
          this.selectAmount(amounts[amounts.length - 1])
        } else if (parsedValue < amounts[0].min) {
          this.selectAmount(amounts[0]);
        } else {
          const output = amounts.find((amount) => parsedValue <= amount.max && parsedValue >= amount.min);
          this.selectAmount(output ? output: amounts[0]);
        }
      } else if (amounts.find((amount) => value === amount.label)) {
        this.selectAmount(amounts.find((amount) => value === amount.label));
      } else {
        this.selectAmount(amounts[0]);
      }
    },
  },
  computed: {
    ...mapGetters(['loanAmounts']),
    displayValue(){
      const loanAmount = this.loanAmounts.find((obj) => obj.value === this.value);
      return  loanAmount ? loanAmount.label : '';
    },
  },
};
</script>
