<template>
  <!-- eslint-disable max-len -->
  <div class="form processing">
    <div v-if="apiRedirectURL">
      <div class="progress">
        <div class="progress-bar progress-bar-striped" style="width: 100%">
          Searching complete
        </div>
      </div>
      <div class="form__progress-message">
        <p class="mb-5">We couldn't find you a lender. But don't worry, our partners may be able to help!
          We'll redirect you to their site now!
          You'll automatically be redirected in {{ timer }} seconds.
        </p>
        <p><strong>Click “continue” to view your options!</strong></p>
        <a :href="apiRedirectURL" class="form-button__button form-button__button_color uc">Continue</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Decline',
  title: 'Decline',
  data() {
    return {
      timer: 5,
    };
  },
  computed: mapGetters(['apiRedirectURL']),
  mounted() {
    setTimeout(() => {
      if (this.apiRedirectURL) {
        window.location = this.apiRedirectURL;
      }
    }, (this.timer * 1000));
    const countDown = setInterval(() => {
      if (this.timer !== 0) {
        // eslint-disable-next-line no-plusplus
        this.timer--;
      } else {
        clearInterval(countDown);
      }
    }, 1000);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.getters.apiRedirectURL) {
        next('/');
      } else {
        next();
      }
    });
  },
};
</script>
