<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper :percentageProp="66">
    <template v-slot:message>
      If approved for a loan, please let us know what is the intended use of the funds
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <fieldset>
        <RadioOptions :value="loanPurpose"
                      @input="onSelectOption"
                      label="What’s the purpose of your loan?"
                      :optionsList="[
                        {id: 'debt consolidation', label: 'Debt Consolidation'},
                        {id: 'medical', label: 'Medical'},
                        {id: 'home improvement', label: 'Home Improvement'},
                        {id: 'credit card consolidation', label: 'Credit Card Consolidation'},
                        {id: 'auto purchase', label: 'Auto Purchase'},
                        {id: 'major', label: 'Major Purchase'},
                        {id: 'business', label: 'Start a Business'},
                        {id: 'vacation', label: 'Vacation'},
                        {id: 'wedding', label: 'Wedding'},
                        {id: 'other', label: 'Other'},]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../../components/WizardWrapper';
import formsRouterHooks from '../../../base/mixins/formsRouterHooks';

export default {
  name: 'Returning3',
  title: 'Welcome Back',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      loanPurpose: null,
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.loanPurpose = value;
      if (this.loanPurpose !== null) {
        this.saveFormData({
          loanPurpose: this.loanPurpose,
        });
        this.$router.push({ path: '/users/4' });
      }
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.loanPurpose = this.formData.loanPurpose || '';
  },
};
</script>
