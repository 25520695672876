<template>
  <wizard-wrapper>
    <template v-slot:message>
      We found multiple ABA’s for <strong>{{formData.bankName}} in {{formData.address_state}}</strong>,
      please choose the correct ABA from the list below. You can find your ABA number on a paper check or
      in your banks Online Banking or Mobile App.
    </template>
    <form @submit.prevent="validateBeforeSubmit">

      <fieldset class="form__fieldset">
        <Select
          v-if="abasList.length > countForSelect"
          label="Please select your ABA number"
          id="predefinedAba"
          name="predefinedAba"
          v-model="predefinedAba"
          v-validate="'required'"
          :options="abasList"
          errormsg="This is a required field"
          :error="errors.first('nextPaydate')"
        />

        <RadioOptions v-if="abasList.length <= countForSelect"
                      :value="predefinedAba"
                      @input="onSelectOption"
                      label="Please select your ABA number"
                      :optionsList="abasList.map((i) => ({ id:i, label:i }))"
        />
      </fieldset>

      <small>
        <label class="form__link" @click="onEnterManuallyClick">
          None of these are my ABA, click here to enter manually
        </label>
      </small>

      <form-button v-if="abasList.length > countForSelect"
                   :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'SelectAba',
  title: 'Apply | Step 21',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      countForSelect: 5,
      validationAttempt: false,
      saving: false,
      predefinedAba: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            bankRoutingNumber: this.predefinedAba,
          });
          this.completeStep();
          return;
        }

        this.saving = false;
      });
    },
    onSelectOption(value) {
      this.predefinedAba = value;

      this.validateBeforeSubmit();
    },
    onEnterManuallyClick() {
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData', 'abasList']),
  },
  created() {
    this.predefinedAba = this.formData.bankRoutingNumber || '';
  },
};
</script>
