import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['saveSplitTestData']),
    getCookie(cname) {
      const name = `${cname }=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
  },
  mounted() {
    const campaignId = this.getCookie('campaignId');
    const theme = this.getCookie('splitTestingThemeId');

    if (campaignId && theme) {
      this.saveSplitTestData({
        frontendSplitTestingCampaignId: campaignId,
        frontendSplitTestingThemeId: theme,
      });
    }
  },
};
