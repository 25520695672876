<template>
  <wizard-wrapper>
    <template v-slot:message>
      Great, we're almost finished. Next we need to collect your banking information for the
      <strong>{{ formData.bankAccountType }} account</strong> you will receive funds if your request is successful
    </template>
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <div class="bank-check">
          <img src="../../base/assets/images/check.png" alt="Bank Check" class="bank-check__image">
        </div>
        <div class="form-row">
          <div class="form-row__inner">
            <CleaveInput
              label="ABA / Bank routing number"
              id="bankRoutingNumber"
              name="bankRoutingNumber"
              maxlength="9"
              type="tel"
              :cleaveoptions="masks.aba"
              v-model="bankRoutingNumber"
              v-validate="'required|validateABA'"
              :exval="validationAttempt"
              errormsg="This is a required field"
              :error="errors.first('bankRoutingNumber')"
            />
          </div>
          <div class="form-row__inner">
            <BasicInput
              label="Bank account number"
              id="bankAccountNumber"
              name="bankAccountNumber"
              type="tel"
              autocomplete="off"
              v-model="bankAccountNumber"
              v-validate="'required'"
              :exval="validationAttempt"
              errormsg="This is a required field"
              :error="errors.first('bankAccountNumber')"
            />
          </div>
        </div>
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'BankAccountNumber',
  title: 'Apply | Step 21',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,

      bankRoutingNumber: '',
      bankAccountNumber: '',

      masks: {
        aba: { numericOnly: true, blocks: [9] },
      },

    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            bankRoutingNumber: this.bankRoutingNumber,
            bankAccountNumber: this.bankAccountNumber,
          });
          this.completeStep();
          return;
        }

        this.saving = false;
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.bankRoutingNumber = this.formData.bankRoutingNumber || '';
    this.bankAccountNumber = this.formData.bankAccountNumber || '';
  },
};
</script>
