import store from './store';
import CreateApp from '../Blank/CreateApp';
import requireComponents from './requireComponents'
import paths from './router/paths';

export default (context, params={}) => {
  return CreateApp({
      requireComponents: requireComponents,
      paths: paths,
      store,
      ...params
    },
    context
  );
};
