<template>
  <!-- eslint-disable max-len -->
  <div>
    <h3 class="cms-page__header">CONSENT TO RECEIVE ELECTRONIC DISCLOSURES AND COMMUNICATIONS</h3>
    <div class="cms-page__content">
        <p><strong> PLEASE PRINT AND RETAIN A COPY OF THIS CONSENT AGREEMENT FOR YOUR RECORDS.</strong></p>
        <p>You are submitting a request to be connected with third party lenders and other providers of good and services. To process your request {{pageContext.brand_name_llc}} (“{{pageContext.brand_name}}”); and these third party lenders to process your loan and/or providers of other goods and services, may be required by law to provide you with certain communications, notices, disclosures, information and other materials ("Disclosures and Communications"). These third party lenders must obtain your consent in order to provide you with these Disclosures and Communications electronically.</p>
        <p id="1"><strong>1. CONSUMER CONSENT.</strong> By submitting your request to be connected with lenders and/or other service providers, you affirmatively consent and agree to receive all Disclosures and Communications required under law electronically. These Disclosures and Communications may be delivered to you via email or online at the website of the third party lender and/or other service providers. You further affirmatively consent that your electronic signature on agreements and documents has the same effect as if you signed them in ink.</p>
        <p id="2"><strong>2. SCOPE OF CONSENT.</strong> You consents provided in Section 1 to receive Disclosures and Communications and to do business electronically applies to your request to {{pageContext.brand_name_llc}} to be connected with third party lenders and to those third party lenders and/or other providers of goods and services. Your consents also apply to all online interactions between you and the third party lenders and/or providers of good and services, including their service providers, including those conducted via mobile devices (including text message or recorded calls).</p>
        <p id="3"><strong>3. WHAT YOU NEED - HARDWARE AND SOFTWARE REQUIREMENTS.</strong> In order to access and retain the Communications electronically, you will need the following:</p>
        <ul>
            <li>A computer with access to internet, a working email address</li>
            <li>A printer or the ability to print the Communications</li>
            <li>Long term storage device (such as your computer's disk drive) to retain a copy of the Communications</li>
        </ul>
        <p id="4"><strong>4. REQUESTING PAPER RECORDS.</strong>You may request paper copies of any Disclosures and Communications by contacting the third party lender directly. Your lender may provide paper copies to you by request, some lenders may require a charge for these copies and some may not. Their contact information can be found on their website.</p>
        <p id="5"><strong>5. WITHDRAWING YOUR CONSENT.</strong> Because your consent may be required in order for some third party lenders and/or providers of good and services to respond to your connecting request and return a quote, your consent may not be withdrawn during the connecting process. However, if you are connected with one or more third party lenders and/or providers of products and services, you may withdraw your consent to do business electronically directly with those third party lender and/or third party product and service providers.</p>
        <p id="6"><strong>6. CONTACT INFORMATION.</strong> You should keep third party lenders and/or provider of good and services informed of any changes to your contact information. You may update such information by logging into the third party’s website or by sending the lender a written update by mail.</p>
        <p id="7"><strong>7. LEGAL EFFECT.</strong> BY CLICKING THE LINK, YOU ASSENT TO THE TERMS. YOU ACKNOWLEDGE YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC SIGNATURES, RECORDS, DISCLOSURES, AND DOING BUSINESS ELECTRONICALLY. YOU CONSENT TO USING ELECTRONIC SIGNATURES, HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM AND TO DOING BUSINESS WITH THE LENDER AND/OR PROVIDER ELECTRONICALLY. YOU ACKNOWLEDGE THAT YOU MAY REQUEST A PAPER COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WILL BE PROVIDED TO YOU AT NO CHARGE. IF YOU REFRAIN FROM PROCEEDING THEN YOU NEITHER WISH TO USE ELECTRONIC SIGNATURES NOR CONDUCT THIS TRANSACTION ELECTRONICALLY. YOU ALSO ACKNOWLEDGE THAT YOUR CONSENT TO ELECTRONIC DISCLOSURES IS REQUIRED TO RECEIVE SERVICES FROM THIRD PARTY LENDERS AND/OR PROVIDERS OVER THE INTERNET.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EconsentContent',
  computed: mapGetters(['pageContext']),
};
</script>
