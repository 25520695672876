<template>
    <div class="main">
      <router-view name="wrapper">
        <router-view/>
      </router-view>
    </div>
</template>

<script>
import appMixin from "../Blank/mixins/appMixin";
import utmMixin from "@/code/FastCash/Bundle/LTConnector/view/frontend/base/mixins/utm";
import prePopulatedUserMixin from "@/code/FastCash/Bundle/LTConnector/view/frontend/base/mixins/prePopulatedUser";
import subscribeEventsMixin from "./mixins/subscribeEvents";

export default {
  name: 'App',
  mixins: [utmMixin, prePopulatedUserMixin, appMixin, subscribeEventsMixin],
};
</script>

<style lang="scss">
  @import 'assets/css/index';
</style>
