<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper :percentageProp="25">
    <template v-slot:message>
      Do you need more funds{{`, ${formData.firstName}?` || '?'}} At this time you may qualify for more money, confirm your details below.
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <div class="form-row">
          <div class="form-row__inner form-row__inner_size-12">
            <div class="encrypted">
              <span class="encrypted__icon" alt=""></span>
              <span class="encrypted__label">256-bit encrypted</span>
            </div>
            <CleaveInput
              label="Last 4 digits of your SSN"
              id="ssnLast4"
              name="ssnLast4"
              type="tel"
              maxlength="4"
              autocomplete="off"
              v-model="ssnLast4"
              v-validate="{ required: true, regex: /^([0-9]{4})$/ }"
              :exval="validationAttempt"
              :cleaveoptions="masks.ssnLast4"
              extracss="dont-mark"
              errormsg="This is a required field"
              :error="errors.first('ssnLast4')"
            />
          </div>
        </div>
      </fieldset>
      <div v-if="errorMessage" class="alert alert-danger">
        Unfortunately that email doesn't match the records in our system. Try again or
        <router-link to="/registration/1">Click here to start a new request</router-link>.
      </div>

      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Sending...' : 'Confirm My Info'"></form-button>

      <div class="text-primary">
        <small><router-link to="/registration/1">Not you? Click here to start a new request</router-link></small>
      </div>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import tagManagerMixin from '@/code/Core/Bundle/GoogleTagManager/view/tagManagerMixin';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
// TODO: PF-850 Refactor Extra component
export default {
  name: 'Extra',
  title: '★★★ Extra Funds May Be Available',
  components: {
    WizardWrapper,
    FormButton,
  },
  mixins: [tagManagerMixin],
  data() {
    return {
      fname: '',
      email: '',
      ssnLast4: '',
      validationAttempt: false,
      saving: false,
      errorMessage: false,
      masks: {
        ssnLast4: { numericOnly: true, blocks: [4] },
      },
    };
  },
  methods: {
    ...mapActions(['verifyEmail']),
    validateBeforeSubmit() {
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.sendData();
        }
      });
    },
    async sendData() {
      try {
        await this.verifyEmail({ email: this.formData.email, ssn_last4: this.ssnLast4 });
        if (this.isUserAuthorized) {
          this.addTagManagerData({
            event: 'registerLogin',
            entry: 'extra',
          });
          this.$router.push({ name: 'returning' });
        } else {
          this.loading = false;
          this.errorMessage = true;
        }
      } catch (err) {
        this.loading = false;
      }
    },
    titleSwitch() {
      const t1 = '★★★ Extra Funds May Be Available ★★★';
      const t2 = '☆☆☆ Extra Funds May Be Available ☆☆☆';
      if (document.title === t1) {
        document.title = t2;
      } else {
        document.title = t1;
      }
    },
  },
  computed: mapGetters(['formData', 'isUserAuthorized']),
  mounted() {
    setInterval(this.titleSwitch, 700);
    document.title = '★★★ Extra Funds May Be Available ★★★';
  },
};
</script>
