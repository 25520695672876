<template>
  <!-- eslint-disable max-len -->
    <div class="form processing">
        <input
          type="hidden"
          id="progressHidden"
          name="processingPage"
          value="Processing Page.Form submitted"
        />
        <label for="progressHidden" style="display: none">Processing Page.Form submitted</label>
        <div v-if="errorMessage">
            <div class="alert alert-danger w-100 mt-4">
                There was an error submitting your request. Please click below to try again.
            </div>
            <button class="processing__button processing__button_color uc" @click="resubmit" :disabled="sending" id="form-resubmit">
              {{sending ? 'Sending Your Request...' : 'Try Again'}}
            </button>
        </div>
        <div v-else>
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated"
                     role="progressbar"
                     :style="{width: progress + '%'}">
                  Searching...
                </div>
            </div>
            <div class="form__progress-message">
                Please wait while we search our network of lenders. This process may take up to 5-8 minutes.<br><br>
              <strong>DO NOT</strong> close your browser, refresh the page or press the back button
              as this will interfere with your search.
            </div>
        </div>
        <div class="modal" v-if="leaving">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Leave Page?</h5>
              </div>
              <div class="modal-body">
                Are you sure you want to leave? Navigating away will interfere with your search.
              </div>
              <div class="modal-footer">
                <button @click="forceLeave" class="btn btn-link">Leave</button>
                <button type="button" class="btn btn-primary" @click="leaving = false">Stay</button>
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import showPushOnMountMixin from '../../../../../../OneSignal/view/frontend/mixins/showPushOnMount';
import formsRouterHooks from '../../mixins/formsRouterHooks';

export default {
  name: 'Processing',
  title: 'Register | Processing',
  mixins: [formsRouterHooks, showPushOnMountMixin],
  components: {},
  data() {
    return {
      progress: 0,
      errorMessage: false,
      sending: false,
      exitPath: '/',
      forcedLeaving: false,
      leaving: false,
    };
  },
  methods: {
    ...mapActions(['sendToAPI', 'saveFormData']),
    setProgress() {
      const { progress } = this;
      switch (true) {
      case progress < 50:
        this.progress += Math.floor(Math.random() * 7) + 3;
        break;
      case progress < 90:
        this.progress += Math.floor(Math.random() * 4) + 2;
        break;
      case progress < 97:
        this.progress += 2;
        break;
      default:
        this.progress = 99;
      }
    },
    forceLeave() {
      this.forcedLeaving = true;
      this.$router.push({ path: this.exitPath });
    },
    resubmit() {
      this.sending = true;
      this.errorMessage = false;
      this.progress = 0;
      this.submitData();
    },
    submitData() {
      this.sendToAPI().then((response) => {
        window.onbeforeunload = null;
        this.sending = false;
        this.progress = 99;
        switch (response.status) {
        case 'accepted':
          if (response.isDeclined) {
            this.$router.push({ name: 'decline' });
          } else {
            // eslint-disable-next-line no-restricted-globals
            location.replace(response.redirectURL);
          }
          break;
        case 'rejected':
          if (this.formData.repost && this.repostLoanAmount && this.formData.loanAmount > this.repostLoanAmount.value) {
            this.progress = 25;
            this.sending = true;
            this.saveFormData({
              loanAmount: this.repostLoanAmount.value,
            });
            this.sendToAPI().then((res) => {
              this.sending = false;
              if (res.isDeclined) {
                this.$router.push({ name: 'decline' });
              } else if (res.redirectURL) {
                // eslint-disable-next-line no-restricted-globals
                location.replace(res.redirectURL);
              } else {
                // There has been an error... show error
                this.errorMessage = true;
              }
            }).catch(() => {
              // There has been an error show error
              this.sending = false;
              this.errorMessage = true;
            });
          } else if (this.repostLoanAmount && this.formData.loanAmount > this.repostLoanAmount.value) {
            this.$router.push({ name: 'repost' });
          } else {
            // eslint-disable-next-line no-restricted-globals
            location.replace(response.redirectURL);
          }
          break;
        case 'error':
          // Handle Error
          this.errorMessage = true;
          this.sending = false;
          break;
        default:
          // Catch All Error
          this.errorMessage = true;
          this.sending = false;
          break;
        }
      }).catch(() => {
        // There has been an error show error
        this.sending = false;
        this.errorMessage = true;
      });
    },
  },
  watch: {},
  computed: {
    ...mapGetters(['formData', 'loanAmounts', 'repostLoanAmount']),
  },
  mounted() {
    setTimeout(this.setProgress, 2500);
    setInterval(this.setProgress, 10000);
    this.sending = true;
    this.submitData();
    // eslint-disable-next-line func-names
    window.onbeforeunload = function () {
      return 'Are you sure you want to close? Closing the window now will interfere with your search.';
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.forcedLeaving) {
      next();
    } else if (this.sending) {
      this.leaving = true;
      this.exitPath = to.path;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style>
</style>
