<template>
  <wizard-wrapper
    message="Next we need to learn a bit about your living circumstances">
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions :value="homeOwnership"
                      @input="onSelectOption"
                      label="Do you own or rent?"
                      :optionsList="[
                        {id: 'rent', label: 'I rent'},
                        {id: 'own', label: 'I own'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'HomeOwnership',
  title: 'Apply | Step 10',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      homeOwnership: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.homeOwnership = value;
      this.saving = true;
      this.saveFormData({
        homeOwnership: this.homeOwnership,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.homeOwnership = this.formData.homeOwnership || '';
  },
};
</script>
