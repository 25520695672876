export default {
  welcome: {
    path: 'welcome',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/EmailRecognise.vue'),
  },
  returning: {
    path: '1',
    meta: {
      beforeRouteEnter: (store, to, from) => {
        if (!store.getters.auth.confirmedUser) {
          return '/';
        }
        if (from.name === 'returning2') {
          return '/users/1';
        }
        return store.getters.formData.loanAmount ? '/users/2' : null;
      },
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning1.vue'),
  },
  returning2: {
    path: '2',
    meta: {
      beforeRouteEnter: (store, to, from) => {
        if (!store.getters.auth.confirmedUser) {
          return '/';
        }
        if (from.name === 'returning2b' || from.name === 'returning3') {
          return '/users/2';
        }
        return store.getters.formData.creditScore ? '/users/3' : null;
      },
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning2.vue'),
  },
  returning3: {
    path: '3',
    meta: {
      beforeRouteEnter: (store, to, from) => {
        if (!store.getters.auth.confirmedUser) {
          return '/';
        }

        if (from.name === 'returning4') {
          return '/users/3';
        }
        return store.getters.formData.loanPurpose ? '/users/4' : null;
      },
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning3.vue'),
  },
  returning4: {
    path: '4',
    meta: {
      beforeRouteEnter: (store) => {
        if (!store.getters.auth.confirmedUser) {
          return '/';
        }
        // ToDo: Add check that route is not navigating via back button
        return store.getters.formData.debtAmount ? '/users/5' : null;
      },
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning4.vue'),
  },
  returning5: {
    path: '5',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.auth.confirmedUser ? '/' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning5.vue'),
  },
  returning5b: {
    path: '5b',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.auth.confirmedUser ? '/' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning5b.vue'),
  },
  returning6: {
    path: '6',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.auth.confirmedUser ? '/' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning6.vue'),
  },
  returning6b: {
    path: '6b',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.auth.confirmedUser ? '/' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning6b.vue'),
  },
  returning7: {
    path: '7',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.auth.confirmedUser ? '/' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "wReturning" */ '../../view/frontend/chatBot/wizard/short/Returning7.vue'),
  },
  processingShortMulti: {
    path: 'processing',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.auth.confirmedUser ? '/' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "processing" */ '../../view/frontend/base/components/form/Processing.vue'),
  },
};
