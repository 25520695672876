<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper
    message="Next we need to learn a bit about your living circumstances"
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <BasicInput
          label="What's your address?"
          id="address"
          name="address"
          v-model="address"
          v-validate="'required'"
          :exval="validationAttempt"
          errormsg="This is a required field"
          :error="errors.first('address')"
          :help="'Please take a moment to confirm that the details below based on your zipcode <strong>'+ zipcode +'</strong> are correct'"
        />
      </fieldset>

      <fieldset class="form__fieldset">
        <div class="form-row">
          <div class="form-row__inner">
            <CleaveInput
              label="Zipcode"
              id="zipcode"
              name="zipcode"
              maxlength="5"
              type="tel"
              v-model="zipcode"
              v-validate="{ required: true, regex: /^[0-9]{5}$/ }"
              :exval="validationAttempt"
              :cleaveoptions="masks.zipcode"
              errormsg="This is a required field"
              :error="errors.first('zipcode')"
            />
          </div>
          <div class="form-row__inner ">
            <Select
              label="State"
              id="address_state"
              name="address_state"
              v-model="address_state"
              v-validate="'required'"
              :options="statesList"
              errormsg="This is a required field"
              :error="errors.first('address_state')"
            />
          </div>
        </div>
        <BasicInput
          label="City"
          id="city"
          name="city"
          v-model="city"
          v-validate="'required'"
          :exval="validationAttempt"
          errormsg="This is a required field"
          :error="errors.first('city')"
        />
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'Address',
  title: 'Apply | Step 8',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,

      address: '',
      zipcode: '',
      city: '',
      address_state: '',

      masks: {
        zipcode: { numericOnly: true, blocks: [5] },
      },

    };
  },
  watch: {
    zipcode: 'setCityState',
  },
  methods: {
    ...mapActions(['saveFormData', 'getCityState']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            address: this.address,
            zipcode: this.zipcode,
            city: this.city,
            address_state: this.address_state,
          });
          this.completeStep();
          return;
        }

        this.saving = false;
      });
    },
    async setCityState(zipcode) {
      if (zipcode.length !== 5) {
        return;
      }
      try {
        const result = await this.getCityState(zipcode);
        if (result) {
          this.city = result.city;
          this.address_state = result.state;
        }
      } catch (err) {
        // console.log(err);
      }
    },
  },
  computed: {
    ...mapGetters(['formData', 'statesList']),
  },
  created() {
    this.zipcode = this.formData.zipcode || '';
    this.setCityState(this.zipcode);
    this.address = this.formData.address || '';
  },
  mounted() {
  },
};
</script>
