import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import Vue from 'vue';

export const getComponentName = (fileName) => {
  const replacedFileName = fileName.split('/').pop().replace(/\.\w+$/, '');

  return upperFirst(camelCase(replacedFileName));
};

export const registerComponents = (requireComponent) => {
  const dynamicComponents = requireComponent.dynamic;
  const components = [];

  dynamicComponents.forEach((rc) => {
    rc.keys().forEach((fileName) => {
      const componentConfig = rc(fileName);
      components.push({
        name: getComponentName(fileName),
        component: componentConfig.default || componentConfig,
      });
    });
  });

  Object.entries(requireComponent).forEach(([key, value]) => {
    if (key !== 'dynamic') {
      components.push({
        name: key,
        component: value,
      });
    }
  });

  components.forEach((c) => {
    // Register component globally
    Vue.component(
      c.name,
      c.component,
    );
  });
};
