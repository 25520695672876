<template>
  <div class="control-group">
    <label class="control-group__label control-group__label_focused" :for="id">{{label}}</label>
    <input class="control-group__input"
           :id="id"
           :name="name"
           disabled
           :value="value"
           :autocomplete="autocomplete"
    >
  </div>
</template>

<script>
export default {
  name: "DisabledInput",
  props: {
    label: String,
    id: String,
    name: String,
    value: {
      type: null,
    },
    autocomplete: String
  },
}
</script>
