export default {
  path: '/boost',
  components: {
    default: () =>
      import(
        /* webpackChunkName: "boost" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Boost.vue'
      ),
    wrapper: () =>
      import(
        /* webpackChunkName: "boost" */ '../../Blank/views/one-column-right.vue'
      ),
  },
  props: {
    wrapper: { formType: 'form_interstitial' },
  },
}
