<template>
  <!-- eslint-disable max-len -->
    <article>
        <div class="unsubscribe-page">
            <div class="unsubscribe-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <router-link to="/unsubscribe">Unsubscribe</router-link> &raquo; <a href="#">Email</a>
            </div>

            <div class="unsubscribe-page__content">
                <h3 class="unsubscribe-page__header">Email Unsubscribe</h3>
                <p class="unsubscribe-page__description">Use the form below to unsubscribe your email address.</p>
                <form class="unsubscribe-form" @submit.prevent="validateBeforeSubmit">
                    <div class="unsubscribe-form__row">
                        <div class="unsubscribe-form__row-inner">
                            <BasicInput
                                label="What's your email?"
                                id="email"
                                name="email"
                                type="email"
                                v-model="email"
                                :exval="validationAttempt"
                                v-validate="'required|email'"
                                :error="errors.first('email')"
                            />
                        </div>
                    </div>
                    <div class="unsubscribe-form__footer">
                        <div v-if="errorMessage" class="alert alert-danger unsubscribe-form__alert">
                            Unfortunately there was an error carrying out your action. Please try again later.
                        </div>
                        <div v-if="successMessage" class="alert alert-success unsubscribe-form__alert">
                            You have been successfully unsubscribed.
                        </div>
                        <div class="unsubscribe-form__button-wrapper">
                            <button class="unsubscribe-form__button unsubscribe-form__button_color uc" id="form-submit">{{sending ? 'Sending...' : 'Unsubscribe'}}</button>
                            <div>
                                <transition name="slide">
                                    <span class="unsubscribe-form__some-errors" v-if="(errors.items.length != 0 && validationAttempt)">You have some errors</span>
                                </transition>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="unsubscribe-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UnsubscribeEmail',
  data() {
    return {
      validationAttempt: false,
      sending: false,
      errorMessage: false,
      successMessage: false,

      email: '',
    };
  },
  methods: {
    ...mapActions(['unsubscribeEmail']),
    validateBeforeSubmit() {
      this.sending = true;
      this.successMessage = false;
      this.errorMessage = false;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.unsubscribe();
          return;
        }
        this.sending = false;
      });
    },
    async unsubscribe() {
      try {
        this.sending = true;
        const response = await this.unsubscribeEmail(this.email);
        if (response) {
          this.validationAttempt = false;
          this.sending = false;
          this.successMessage = true;
          this.email = '';
          this.$nextTick().then(() => {
            this.$validator.reset();
            this.errors.clear();
          });
        } else {
          this.sending = false;
          this.errorMessage = true;
        }
      } catch (err) {
        this.sending = false;
      }
    },
  },

};
</script>

<style>

</style>
