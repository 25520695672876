<template>
  <wizard-wrapper
    message="How much debt do you have?">
    <form @submit.prevent>
      <fieldset class="form__fieldset">
        <Select
            label="How much debt do you have?"
            id="debtAmount"
            name="debtAmount"
            v-model="debtAmount"
            :options="[
              {value: '0', label: 'I have no debt'},
              {value: '7500', label: 'Under $7500'},
              {value: '10000', label: '$7500 - $10,000'},
              {value: '15000', label: '$10,000 - $15,000'},
              {value: '20000', label: '$15,000 - $20,000'},
              {value: '25000', label: '$20,000 - $25,000'},
              {value: '30000', label: '$25,000 - $30,000'},
              {value: '35000', label: '$30,000 - $35,000'},
              {value: '40000', label: '$35,000 - $40,000'},
              {value: '45000', label: '$40,000 - $45,000'},
              {value: '50000', label: '$45,000+'}
            ]"
            @input="onSelectOption"
          />
      </fieldset>
      <button class="form-button__button form-button__button_color uc" id="form-submit" @click="onSelectOption">
        {{debtAmount === '' ? 'Skip' : 'Continue'}}
      </button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'DebtAmount',
  title: 'Apply | Step 2b',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      debtAmount: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption() {
      this.saveFormData({
        debtAmount: this.debtAmount === '0' ? '' : this.debtAmount,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.debtAmount = this.formData.debtAmount || '';
  },
};
</script>
