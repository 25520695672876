function getFilledStepName(stepName, fromName, store, router) {
  const prevIndex = store.getters.steps.indexOf(stepName) - 1;
  if (prevIndex < 0) {
    return stepName;
  }
  let prevStepName = store.getters.steps[prevIndex];
  const currentRouter = router.getRoutes().find((i) => i.name === stepName);

  if (currentRouter && currentRouter.meta.getPrevStepName) {
    prevStepName = currentRouter.meta.getPrevStepName(store, stepName, fromName);
  }

  if (prevStepName && !store.getters.isFilledStep(prevStepName)) {
    return getFilledStepName(prevStepName, fromName, store, router);
  }
  return stepName;
}

export default {
  methods: {
    completeStep(nextStepName, currentStepName) {
      // eslint-disable-next-line no-param-reassign
      currentStepName = currentStepName || this.$route.name;

      this.$store.dispatch('markStepComplete', currentStepName);
      if (!nextStepName) {
        const currentIndex = this.$store.getters.steps.indexOf(currentStepName);
        if (currentIndex === -1) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        nextStepName = this.$store.getters.steps[currentIndex + 1];
      }

      const nextRoute = this.$router.getRoutes().find((i) => i.name === nextStepName);
      if (nextRoute) {
        this.$router.push(nextRoute.path);
      }
    },
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      if (to.meta && to.meta.beforeRouteEnter) {
        const path = to.meta.beforeRouteEnter(vm.$store, to, from);
        if (path) {
          next(path);
        }
      } else {
        const stepName = getFilledStepName(to.name, from.name, vm.$store, vm.$router);
        if (stepName !== to.name) {
          const route = vm.$router.getRoutes().find((i) => i.name === stepName);
          if (route) {
            next(route.path);
          }
        }
      }
    });
  },
};
