<template>
  <!-- eslint-disable max-len -->
  <section id="how-it-works" class="how-it-works">
    <div class="how-it-works__container">
      <h2 class="how-it-works__header uc">How it works?</h2>
      <div class="how-it-works__content-wrapper">
        <div class="how-it-works__content">
          <div class="hiw-step" v-for="(step, index) in howItWorksSteps" :key="step.id"
               :class="{ 'hiw-step_content-end': index%2 }">
            <div class="hiw-step__image-wrapper" :class="{ 'hiw-step__image-wrapper_order-lg-last': !(index%2) }">
              <div class="hiw-step__image" :class="{ 'hiw-step__image_last': (index == howItWorksSteps.length-1)}">
                <div class="hiw-step__icon grad-text">
                  <i :class="step.iconClass"></i>
                </div>
                {{ step.stepNo }}
              </div>
            </div>
            <div class="hiw-step__text"><h3>{{ step.title }}</h3>
              {{ step.text }}
            </div>
          </div>
        </div><!--End Col 10-->
      </div><!--End Row-->
      <CallToActionButton :mode="mode"/>
    </div><!--End Container-->
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CallToActionButton from './CallToActionButton';

export default {
  name: 'HowItWorks',
  computed: mapGetters(['pageContext', 'labels']),
  components: {
    CallToActionButton,
  },
  props: {
    mode: {
      type: String,
      default: 'history',
    },
  },
  data() {
    return {
      howItWorksSteps: [],
    };
  },
  created() {
    this.howItWorksSteps = this.getHowItWorksSteps();
  },
  methods: {
    getHowItWorksSteps() {
      const steps = this.labels.howItWorksSteps;
      steps[0].title = steps[0].title.replace('$site_name', this.pageContext.site_name);
      return steps;
    },
  },
};
</script>
