var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-wrapper',{attrs:{"message":"How much debt do you have?"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('fieldset',{staticClass:"form__fieldset"},[_c('Select',{attrs:{"label":"How much debt do you have?","id":"debtAmount","name":"debtAmount","options":[
            {value: '0', label: 'I have no debt'},
            {value: '7500', label: 'Under $7500'},
            {value: '10000', label: '$7500 - $10,000'},
            {value: '15000', label: '$10,000 - $15,000'},
            {value: '20000', label: '$15,000 - $20,000'},
            {value: '25000', label: '$20,000 - $25,000'},
            {value: '30000', label: '$25,000 - $30,000'},
            {value: '35000', label: '$30,000 - $35,000'},
            {value: '40000', label: '$35,000 - $40,000'},
            {value: '45000', label: '$40,000 - $45,000'},
            {value: '50000', label: '$45,000+'}
          ]},on:{"input":_vm.onSelectOption},model:{value:(_vm.debtAmount),callback:function ($$v) {_vm.debtAmount=$$v},expression:"debtAmount"}})],1),_c('button',{staticClass:"form-button__button form-button__button_color uc",attrs:{"id":"form-submit"},on:{"click":_vm.onSelectOption}},[_vm._v(" "+_vm._s(_vm.debtAmount === '' ? 'Skip' : 'Continue')+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }