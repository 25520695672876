<template>
  <div>
    By submitting your information, you agree to the terms of our
    <a href="/legal/privacy" target="_blank">Privacy Policy</a>,
    <a href="/legal/tos" target="_blank">Terms of Use</a>
    and <a href="/legal/disclaimer" target="_blank">Disclaimer</a>.
  </div>
</template>

<script>
export default {
  name: "AgreeTerms"
}
</script>
