<template>
  <wizard-wrapper
    message="Please enter your date of birth to confirm your age. You must be over 18 to continue."
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <CleaveInput
          label="When is your date of birth?"
          id="dob"
          name="dob"
          type="tel"
          :cleaveoptions="masks.date"
          v-model="dob"
          maxlength="10"
          v-validate="'required|date_format:MM/dd/yyyy|validateAge'"
          :exval="validationAttempt"
          help="Format: mm/dd/yyyy"
          :error="errors.first('dob')"
        />
        <input name="age_target" ref="ageTarget" type="hidden" :value="birthdate">
      </fieldset>

      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'DateOfBirth',
  title: 'Apply | Step 12',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,

      dob: '',

      masks: {
        date: { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] },
      },

      conditionalRouting: {
        nextStep: 'apply12c',
      },
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'markStepComplete']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            dob: this.dob,
          });
          if (this.age >= 64) {
            this.completeStep();
          } else {
            this.markStepComplete('apply12b');
            this.completeStep(this.conditionalRouting.nextStep);
          }
        }
        this.saving = false;
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
    birthdate() {
      const date = new Date();
      const d = date.getDate() + 1 < 10 ? `0${date.getDate() + 1}` : date.getDate() + 1;
      const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      return `${m}/${d}/${date.getFullYear() - 18}`;
    },
    age() {
      const dob = moment(this.dob, 'MM/DD/YYYY');
      return moment().diff(dob, 'years');
    },
  },
  created() {
    if (typeof this.$route.meta.conditionalRouting !== 'undefined') {
      this.conditionalRouting = this.$route.meta.conditionalRouting;
    }
    this.dob = this.formData.dob || '';
  },
};
</script>
