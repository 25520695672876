<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper
    message="Enter your Social Security Number to complete your request"
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <div class="form-row">
          <div class="form-row__inner form-row__inner_size-12">
            <div class="encrypted">
              <span class="encrypted__icon" alt=""></span>
              <span class="encrypted__label">256-bit encrypted</span>
            </div>
            <CleaveInput
              label="What's your SSN?"
              id="ssn"
              name="ssn"
              type="tel"
              maxlength="11"
              autocomplete="off"
              :cleaveoptions="masks.ssn"
              v-model="ssn"
              v-validate="{ required: true, regex: /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/ }"
              :exval="validationAttempt"
              errormsg="This is a required field"
              :error="errors.first('ssn')"
              extracss="dont-mark"
              help="Lenders use your SSN to verify your identity. You will be rejected if lender verification fails so it is extremely important that you enter this information accurately."
            />
            <input
              type="hidden"
              name="additionalField"
              id="additionalField"
              ref="additionalField"
              value=""
            >
          </div>
        </div>
      </fieldset>

      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'SSN',
  title: 'Apply | Step 23',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      errorMessage: false,

      ssn: '',

      masks: {
        ssn: { numericOnly: true, delimiter: '-', blocks: [3, 2, 4] },
      },

    };
  },
  methods: {
    ...mapActions(['saveFormData']),

    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            ssn: this.ssn,
            additionalField: this.$refs.additionalField.value,
          });
          this.completeStep();
        } else {
          this.saving = false;
        }
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.ssn = this.formData.ssn || '';
  },
};
</script>
