<template>
  <!-- eslint-disable max-len -->
    <div>
        <form @submit.prevent="validateBeforeSubmit">
            <div class="form-row">
                <div class="form-row__inner">
                    <BasicInput
                        label="What's your email?"
                        id="email"
                        name="email"
                        type="email"
                        v-model="email"
                        :exval="validationAttempt"
                        v-validate="'required|email'"
                        errormsg="This is a required field"
                        :error="errors.first('email')" />
                </div>
            </div>

            <div class="form-row">
                <div class="form-row__inner">
                    <CleaveInput
                        label="Last 4 digits of your SSN"
                        id="ssnLast4"
                        name="ssnLast4"
                        type="tel"
                        maxlength="4"
                        autocomplete="off"
                        v-model="ssnLast4"
                        v-validate="{ required: true, regex: /^([0-9]{4})$/ }"

                        :exval="validationAttempt"
                        :cleaveoptions="masks.ssnLast4"

                        extracss="dont-mark"

                        errormsg="This is a required field"
                        :error="errors.first('ssnLast4')"
                    />
                </div>
            </div>

            <div class="form-row">
                <div class="form-row__inner">
                    <LoanSelect
                        label="How much do you want to borrow?"
                        id="loan_amount"
                        name="loanAmount"
                        :type="loanSelectType"
                        v-model="loanAmount"
                        v-validate="'required'"
                        errormsg="This is a required field"
                        :error="errors.first('loanAmount')" />
                </div>
            </div>

            <AgreeTerms class="form__note form__note_narrow txt-grey"/>

            <div v-if="errorMessage" class="alert alert-danger">
                Unfortunately that email doesn't match the records in our system. Try again or
              <router-link to="/registration/1">Click here to start a new request</router-link>.
            </div>

          <FormButton :hasErrors="errors.items.length != 0 && validationAttempt"
                      :buttonLabel="loading ? 'Sending...' : 'Continue'"></FormButton>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import tagManagerMixin from '@/code/Core/Bundle/GoogleTagManager/view/tagManagerMixin';
import FormButton from './FormButton';
import loanAmountFilter from '../mixins/loanAmountFilter';

export default {
  name: 'LoginLeadForm',
  components: {
    FormButton,
  },
  mixins: [loanAmountFilter, tagManagerMixin],
  props: {
    loanSelectType: String,
    show: Boolean,
  },
  methods: {
    ...mapActions(['saveFormData', 'verifyEmail', 'markStepComplete']),
    validateBeforeSubmit() {
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            email: this.email.toLowerCase(),
            loanAmount: this.loanAmount,
          });

          this.markStepComplete('step1');
          this.sendData();
        }
      });
    },
    async sendData() {
      this.loading = true;
      try {
        await this.verifyEmail({ email: this.email, ssn_last4: this.ssnLast4 });
        if (this.isUserAuthorized) {
          this.addTagManagerData({
            event: 'registerLogin',
            entry: 'login',
          });
          this.$router.push({ name: 'returning' });
        } else {
          this.loading = false;
          this.errorMessage = true;
        }
      } catch (err) {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      validationAttempt: false,
      errorMessage: false,
      masks: {
        ssnLast4: { numericOnly: true, blocks: [4] },
      },
      email: '',
      ssnLast4: '',
      loading: false,
    };
  },
  computed: mapGetters(['isUserAuthorized']),
  created() {
    this.email = this.$route.query.email;
  },
};
</script>
