export default {
  apply1: {
    path: '1',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/LoanAmount'),
    alias: ['/registration'],
  },
  apply2: {
    path: '2',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/LoanPurpose.vue'),
  },
  apply2b: {
    path: '2b',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/DebtAmount.vue'),
  },
  apply3: {
    path: '3',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/CreditScore.vue'),
  },
  apply4: {
    path: '4',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/BankAccountType.vue'),
  },
  apply4reject: {
    path: '4/declined',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.isFilledStep('apply4') ? '/registration/4' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/WithoutBankAccount.vue'),
  },
  apply5: {
    path: '5',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/Zipcode.vue'),
  },
  apply6: {
    path: '6',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/Email.vue'),
  },
  apply7: {
    path: '7',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/CustomerName.vue'),
  },
  apply8: {
    path: '8',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/Address.vue'),
  },
  apply9: {
    path: '9',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/MonthsAtAddress.vue'),
  },
  apply10: {
    path: '10',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/HomeOwnership.vue'),
  },
  apply10b: {
    path: '10b',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/CarOwnership.vue'),
  },
  apply11: {
    path: '11',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/PhoneNumber.vue'),
  },
  apply12: {
    path: '12',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/DateOfBirth.vue'),
  },
  apply12b: {
    path: '12b',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/Medicare.vue'),
  },
  apply12c: {
    path: '12c',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/DebtRelief.vue'),
  },
  apply13: {
    path: '13',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/MonthlyIncome.vue'),
  },
  apply14: {
    path: '14',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/EmploymentType.vue'),
  },
  apply15: {
    path: '15',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/PayFrequency.vue'),
  },
  apply16: {
    path: '16',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/PayType.vue'),
  },
  apply17: {
    path: '17',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/EmployerName.vue'),
  },
  apply18: {
    path: '18',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/MonthsEmployed.vue'),
  },
  apply19: {
    path: '19',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/WorkPhone.vue'),
  },
  apply20: {
    path: '20',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/BankName.vue'),
  },
  apply21: {
    path: '21',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/SelectAba.vue'),
  },
  apply22: {
    path: '22',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/BankAccountNumber.vue'),
  },
  apply23: {
    path: '23',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/MonthsAtBank.vue'),
  },
  apply24: {
    path: '24',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/SSN.vue'),
  },
  apply25: {
    path: '25',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/DriversLicense.vue'),
  },
  processingLong: {
    path: 'processing',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "processing" */ '../../view/frontend/base/components/form/Processing.vue'),
  },
};
