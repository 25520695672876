<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper
    message="If you own a car, we can expand your search to include title loan options that may have better rates or terms"
  >
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions
                      :value="answer"
                      @input="onSelectOption"
                      label="Do you currently own a car?"
                      extracss="radio-options-questionairre"
                      :optionsList="[
                        {id: '1', label: 'Yes - It\'s paid off'},
                        {id: '2', label: 'Yes - I\'m making payments'},
                        {id: '3', label: 'No'},]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'CarOwnership',
  title: 'Apply | Step 10b',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      answer: false,
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.answer = value;
      switch (value) {
      case '1':
        this.saveAndComplete({
          car_ownership: 'true',
          car_paidoff: 'true',
        });
        break;
      case '2':
        this.saveAndComplete({
          car_ownership: 'true',
          car_paidoff: 'false',
        });
        break;
      default:
        this.saveAndComplete({
          car_ownership: 'false',
          car_paidoff: 'false',
        });
        break;
      }
    },
    saveAndComplete(params) {
      this.saveFormData({
        additionalVerticals: {
          ...this.formData.additionalVerticals,
          ...params,
        },
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    if (typeof this.formData.additionalVerticals !== 'undefined') {
      if (this.formData.additionalVerticals.car_paidoff === 'true') {
        this.answer = '1';
      } else {
        this.answer = this.formData.additionalVerticals.car_ownership === 'true' ? '2' : false;
      }
    }
  },
};
</script>
