<template>
  <wizard-wrapper
    message="Tell us a little bit about your income and employment"
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <BasicInput
          label="What's your employer's name?"
          id="employerName"
          name="employerName"
          v-model="employerName"
          v-validate="'required'"
          :exval="validationAttempt"
          errormsg="This is a required field"
          :error="errors.first('employerName')"
        />
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'EmployerName',
  title: 'Apply | Step 17',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,

      employerName: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'registerPoint']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            employerName: this.employerName,
          });
          this.completeStep();
          return;
        }

        this.saving = false;
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.employerName = this.formData.employerName || '';
  },
};
</script>
