<template>
  <wizard-wrapper
    message="Please enter your phone number as it may be used by some lenders to validate your account"
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <CleaveInput
          label="What's your phone number?"
          id="phone"
          name="phone"
          type="tel"
          maxlength="12"
          :cleaveoptions="masks.phone"
          v-model="phone"
          v-validate="{ required: true, validateAreaCode: true, regex: /^[0-9]{3}\-[0-9]{3}\-[0-9]{4}$/ }"
          :exval="validationAttempt"
          help="Format: 999-999-9999"
          :error="errors.first('phone')"
        />
      </fieldset>

      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'PhoneNumber',
  title: 'Apply | Step 11',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,

      phone: '',

      masks: {
        phone: { numericOnly: true, delimiter: '-', blocks: [3, 3, 4] },
      },
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            phone: this.phone,
          });
          this.completeStep();
        }
        this.saving = false;
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.phone = this.formData.phone || '';
  },
};
</script>
