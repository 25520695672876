import cmsChildren from '@/code/FastCash/Bundle/Cms/Resources/vue-routing'
import partnersChildren from '@/code/FastCash/Bundle/Partner/Resources/vue-routing'

export default {
  path: '/legal',
  children: [...cmsChildren, ...partnersChildren],
  components: {
    wrapper: () => import(/* webpackChunkName: "legal" */ '../../Blank/views/one-column-right.vue'),
  },
  props: {
    wrapper: {
      logoClass: 'one-column-right__logo-outer_small-padding',
      formType: 'form_internal',
    },
  },
}

