<template>
  <wizard-wrapper
    message="Please answer the following question about debt relief, if it applies to you">
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions
          :value="debtRelief"
          @input="onSelectOption"
          label="Would you like to speak with a debt relief specialist about reducing your debt?"
          :optionsList="[{id: 'true', label: 'Yes'}, {id: 'false', label: 'No'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'DebtRelief',
  title: 'Apply | Step 12c',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      debtRelief: false,
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.debtRelief = value;
      this.saveFormData({
        additionalVerticals: {
          ...this.formData.additionalVerticals,
          debt_relief: this.debtRelief,
        },
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    if (typeof this.formData.additionalVerticals !== 'undefined') {
      this.debtRelief = this.formData.additionalVerticals.debt_relief || '';
    }
  },
};
</script>
