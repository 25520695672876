<template>
  <header class="main-header">
    <div class="main-header__container">
      <Logo link="/" outerClass="main-header__logo-outer" innerClass="logo" defaultColor="dark" type="png"/>
    </div>
  </header>
</template>

<script>
  import Logo from '../../Blank/components/Logo';

  export default {
    name: 'Header',
    components: {
      Logo,
    },
  };
</script>
