<template>
  <div class="call-to-action">
    <HomeLeadFormButton v-if="mode != 'hash'"/>
    <div v-else>
      <a href="#step-1"
         class="call-to-action__button call-to-action__button_color uc"
         v-scroll-to="'#step-1'">Get Started</a>
    </div>
  </div>
</template>

<script>
import HomeLeadFormButton from './HomeLeadFormButton';

export default {
  name: 'CallToActionButton',
  components: {
    HomeLeadFormButton,
  },
  props: {
    mode: {
      type: String,
      default: 'history',
    },
  },
};
</script>

<style>
</style>
