export default {
  path: '/decline',
  children: [
    {
      name: 'decline',
      path: '',
      component: () => import(/* webpackChunkName: "decline" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Decline.vue'),
    },
  ],
  components: {
    wrapper: () => import(/* webpackChunkName: "form" */ '../../chatbot/views/FormsWrapper.vue'),
  },
};
