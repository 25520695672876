import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  ui: {
    showLeadForm: false,
    colorScheme: 'device',
    deviceScheme: 'light',
    withColorSwitch: true,
    formProgress: 0,
  },
  steps: [],
  filledSteps: {},
});

export default {
  state,
  getters,
  actions,
  mutations
}