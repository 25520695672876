import { mapActions } from 'vuex';
import googleTagManagerMixin from '@/code/Core/Bundle/GoogleTagManager/view/mixin';
import subidMixin from "@/code/FastCash/Bundle/LTConnector/view/frontend/base/mixins/subid";
import registerVisitMixin from "@/code/FastCash/Bundle/LTConnector/view/frontend/base/mixins/registerVisit";
import sourceCampaign from "@/code/FastCash/Bundle/LTConnector/view/frontend/base/mixins/sourceCampaign";
import splitTestingMixin from "@/code/FastCash/Bundle/LTConnector/view/frontend/base/mixins/splitTesting";
import oneSignalMixin from '@/code/FastCash/Bundle/OneSignal/view/frontend/mixins/oneSignal'

export default {
  mixins: [googleTagManagerMixin, splitTestingMixin, subidMixin, sourceCampaign, registerVisitMixin, oneSignalMixin],
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
    ],
  },
  methods: {
    ...mapActions(['saveVisitData', 'checkUserAgentHistory', 'checkEmail', 'saveFormData', 'toggleDeviceScheme', 'setColorScheme']),
    checkDeviceScheme(scheme){
      this.toggleDeviceScheme(scheme.matches ? 'dark' :'light');
    },
  },
  mounted() {
    if (localStorage.getItem('colorScheme')) {
      const cs = JSON.parse(localStorage.getItem('colorScheme'));
      this.setColorScheme(cs.scheme)
    }

    const ds = window.matchMedia("(prefers-color-scheme: dark)");
    this.checkDeviceScheme(ds);
    ds.addEventListener("change", (e) => {
      this.checkDeviceScheme(e);
    });

    this.registerVisit();
  }

};
