<template>
  <!-- eslint-disable max-len -->
    <article>
        <div class="unsubscribe-page">
            <div class="unsubscribe-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <a href="#">Unsubscribe</a>
            </div>

            <div class="unsubscribe-page__content">
                <p class="unsubscribe-page__description"></p>
                <div class="unsubscribe-index-content">
                    <div class="unsubscribe-index-content__card-wrapper">
                        <div class="unsubscribe-index-content__card bg-grey">
                            <div class="unsubscribe-index-content__card-body">
                                <h4 class="unsubscribe-index-content__header">Unsubscribe Email</h4>
                                <router-link to="/unsubscribe/email" class="unsubscribe-index-content__button uc">Choose</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="unsubscribe-index-content__card-wrapper">
                        <div class="unsubscribe-index-content__card bg-grey">
                            <div class="unsubscribe-index-content__card-body">
                                <h4 class="unsubscribe-index-content__header">Unsubscribe Phone</h4>
                                <router-link to="/unsubscribe/phone" class="unsubscribe-index-content__button uc">Choose</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="unsubscribe-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>

export default {
  name: 'UnsubscribeIndex',
};
</script>

<style>

</style>
