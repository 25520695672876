export default {
  data() {
    return {
      ip: '',
      sc: '',
      sk: '',
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.sc = urlParams.has('sc') ? urlParams.get('sc') : '';
    this.sk = urlParams.has('sk') ? urlParams.get('sk') : '';
  },
};
