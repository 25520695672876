<template>
  <!-- eslint-disable max-len, vue/max-len -->
    <article>
        <div class="cms-page">
            <div class="cms-page__breadcrumbs">
                <router-link to="/">Home</router-link> &raquo; <a href="#">Legal</a> &raquo; <a href="#">Partner List</a>
            </div>
            <h3 class="cms-page__header">Partner List</h3>
            <div class="cms-page__content">
                <p>{{ pageContext.brand_name_llc}}'s affiliated companies, its and its affiliated companies' service providers, and its and its affiliated companies' marketing partners</p>
                <div v-for="group in partnerGroups" :key="group.name" class="partners-group">
                  <h3 v-if="group.name">{{ group.name }}:</h3>
                  <p v-for="partner in group.partners" :key="partner">{{partner}}</p>
                </div>
                <p>If accepted for a loan, my personal loan lender</p>
                <p>If accepted for a loan, my personal loan lender's service providers and marketing partners</p>
                <p>If I choose, other non-lender providers in {{ pageContext.brand_name_llc}}'s network, and their service providers and marketing partners</p>
            </div>
            <div class="cms-page__footer-link"><router-link to="/">Return Home</router-link></div>
        </div>
    </article>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'Partners',
  title: 'Partners',
  computed: {
    ...mapGetters(['pageContext', 'partners']),
    partnerGroups() {
      /* name
        group: {
          name:
          sortNo:
        }
        sortNo:
      */
      return _.sortBy(_.map(_.groupBy(this.partners, (o) => (o.group ? o.group.name : null)),
        (val) => ({
          name: val[0].group ? val[0].group.name : '',
          sortNo: val[0].group ? val[0].group.sortNo : 999999,
          partners: _.sortBy(val, 'sortNo').map((p) => p.name),
        })), 'sortNo');
    },
  },
};
</script>

<style>

</style>
