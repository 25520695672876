import { mapGetters } from 'vuex';
import oneSignalUtils from '../utils';

export default {
  computed: {
    ...mapGetters(['oneSignalAppId', 'visitData']),
  },

  mounted() {
    if (this.oneSignalAppId) {
      const script = document.createElement('script');
      script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';

      script.addEventListener('load', () => {
        window.OneSignal = window.OneSignal || [];
        window.OneSignal.push(() => {
          window.OneSignal.init({
            appId: this.oneSignalAppId,
          });
        });
        oneSignalUtils.setExternalId(this.visitData.sessionID);
      }, false);
      document.head.appendChild(script);
    }
  },
};
