import legal from './legal'
import unsubscribe from './unsubscribe'
import repost from './repost'
import decline from './decline'
import boost from './boost'
import extra from './extra'
import signin from './signin'
import login from './login'
import Error404 from './Error404'

export default {
  legal: legal,
  unsubscribe: unsubscribe,
  repost: repost,
  decline: decline,
  boost: boost,
  extra: extra,
  signin: signin,
  login: login,
  Error404: Error404,
}
