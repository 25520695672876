export default {
  path: '/login',
  components: {
    default: () => import(/* webpackChunkName: "login" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Login.vue'),
    wrapper: () => import(/* webpackChunkName: "login" */ '../../Blank/views/one-column-right.vue'),
  },
  props: {
    default: { loanSelectType: 'hidden' },
    wrapper: { leftColumnClass: 'align-items-center justify-content-center', rightColumnClass: 'auth-page' },
  },
}
