<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper :percentageProp="45">
    <template v-slot:message>
      In order to look for the most suitable lenders please estimate your credit score
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <fieldset>
        <RadioOptions :value="creditScore"
                      @input="onSelectOption"
                      label="Estimated Credit Score"
                      :optionsList="[
                        {id: 'excellent', label: '720+'},
                        {id: 'good', label: '660-719'},
                        {id: 'fair', label: '600-659'},
                        {id: 'poor', label: '599 or less'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../../components/WizardWrapper';
import formsRouterHooks from '../../../base/mixins/formsRouterHooks';

export default {
  name: 'Returning2',
  title: 'Welcome Back',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      creditScore: null,
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.creditScore = value;
      if (this.creditScore !== null) {
        this.saveFormData({
          creditScore: this.creditScore,
        });
        this.$router.push({ path: '/users/3' });
      }
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.creditScore = this.formData.creditScore || null;
  },
};
</script>
