<template>
  <wizard-wrapper
    message="Tell us a little bit about your income and employment"
  >
    <form>
      <fieldset class="form__fieldset">

        <RadioOptions :value="employmentType"
                      @input="onSelectOption"
                      label="What's your income type?"
                      :optionsList="[
                        {id: 'employed', label: 'Employed'},
                        {id: 'benefits', label: 'Benefits'},
                        {id: 'self-employed', label: 'Self-Employed'},
                        {id: 'military', label: 'Military or Dependent'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'EmploymentType',
  title: 'Apply | Step 14',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      employmentType: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.employmentType = value;
      this.saving = true;
      this.saveFormData({
        employmentType: this.employmentType,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.employmentType = this.formData.employmentType || '';
  },
};
</script>
