<template>
  <!-- eslint-disable max-len -->
  <div :class="outerClass">
    <router-link :to="link" tag="span">
      <span style="font-family: 'Permanent Marker', cursive; font-size: 36px;"
            :style="{ color: (logoColor==='dark' ? 'black':'white')}">{{ text }}</span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const getInvertedColor = function (color) {
  return color === 'light' ? 'dark' : 'light'
}

export default {
  name: 'Logo',
  props: {
    type: {
      type: String,
      default: 'svg',
    },
    outerClass: {
      type: String,
      default: '',
    },
    innerClass: {
      type: String,
      default: '',
    },
    altText: {
      type: String,
      default: 'Brand Logo',
    },
    innerStyle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: null,
    },
    defaultColor: {
      type: String,
      default: 'light',
    },
    link: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['pageContext', 'websiteLogo', 'colorScheme', 'deviceScheme', 'logo']),
    text () {
      return document.location.hostname.slice(0, document.location.hostname.lastIndexOf('.'))
    },
    logoColor () {
      let logoColor = this.defaultColor

      if (this.color) {
        logoColor = this.color
      } else {
        const browserColor = this.colorScheme === 'device' ? this.deviceScheme : this.colorScheme
        logoColor = browserColor === 'light' ? this.defaultColor : getInvertedColor(this.defaultColor)
      }
      return logoColor
    },
    src () {
      let logoColor = this.defaultColor

      if (this.color) {
        logoColor = this.color
      } else {
        const browserColor = this.colorScheme === 'device' ? this.deviceScheme : this.colorScheme
        logoColor = browserColor === 'light' ? this.defaultColor : getInvertedColor(this.defaultColor)
      }

      return this.logo[logoColor]
    },
  },
}
</script>
