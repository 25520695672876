<template>
  <wizard-wrapper
    message="Next we need to learn a bit about your living circumstances"
  >
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions :value="monthsAtAddress"
                      @input="onSelectOption"
                      label="How long have you been living at this address?"
                      :optionsList="[
                        {id: '12', label: '1 year or less'},
                        {id: '24', label: '2 years'},
                        {id: '36', label: '3 years'},
                        {id: '48', label: '4 years'},
                        {id: '60', label: '5 years'},
                        {id: '72', label: '6 years'},
                        {id: '84', label: '7 years or more'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'MonthsAtAddress',
  title: 'Apply | Step 9',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      saving: false,
      monthsAtAddress: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.monthsAtAddress = value;
      this.saving = true;
      this.saveFormData({
        monthsAtAddress: this.monthsAtAddress,
      });
      this.completeStep();
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.monthsAtAddress = this.formData.monthsAtAddress || '';
  },
};
</script>
