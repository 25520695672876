<template>
  <wizard-wrapper
    message="In order to receive a loan you will need an active bank account"
  >
    <form>
      <fieldset class="form__fieldset">
        <RadioOptions v-if="viewType=='checkAccount'"
                      :value="bankAccountType"
                      @input="onSelectOption"
                      label="Do you have an active checking account?"
                      :optionsList="[
                        {id: 'checking', label: 'Yes'},
                        {id: 'noCheckAccount', label: 'No'}]"
        />

        <RadioOptions v-else
                      :value="bankAccountType"
                      @input="onSelectOption"
                      label="Do you have an active savings account?"
                      :optionsList="[
                        {id: 'savings', label: 'Yes'},
                        {id: 'none', label: 'No'}]"
        />
      </fieldset>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'BankAccountType',
  title: 'Apply | Step 4',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      bankAccountType: '',
      viewType: 'checkAccount',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    onSelectOption(value) {
      this.bankAccountType = value;
      if (this.bankAccountType === 'noCheckAccount') {
        this.viewType = 'savingAccount';
        return;
      }

      this.saving = true;
      this.saveFormData({
        bankAccountType: this.bankAccountType,
      });
      if (this.bankAccountType === 'none') {
        this.completeStep('apply4reject');
      } else {
        this.completeStep();
      }
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.bankAccountType = this.formData.bankAccountType || '';
  },
};
</script>
