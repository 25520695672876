export default {
  initializeUI({commit}, data) {
    commit('setFormSteps', data.steps);
    commit('setColorSwitch', data.switch);
  },
  toggleLeadForm({commit}) {
    commit('toggleLeadForm');
  },
  closeLoginForm({commit}) {
    commit('setUseragentRecogniseUser', {recognised: false, name: ''});
    commit('setRecogniseEmail', {recognised: false, name: '', email: ''});
  },
  setColorScheme({commit, state}, data){
    if(state.ui.withColorSwitch) {
      const attr = data === 'device' ? state.ui.deviceScheme : data;
      document.querySelector('html').dataset.theme = `theme-${attr}`;
      localStorage.setItem('colorScheme', JSON.stringify({scheme: data}));
      commit('setColorScheme', data);
    }
  },
  toggleDeviceScheme({commit, state}, data) {
    if(state.ui.withColorSwitch) {
      if(state.ui.colorScheme === 'device'){
        document.querySelector('html').dataset.theme = `theme-${data}`;
      }
      commit('toggleDeviceScheme', data);
    }
  },
  markStepComplete({commit},data) {
    commit('markStepComplete', data);
  },
  setProgressAmount({commit}, data) {
    commit('setProgressAmount', data);
  },
}