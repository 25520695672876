<template>
  <!-- eslint-disable max-len -->
  <div class="control-group">
    <label v-if="label" class="control-group__label" :for="id" :class="{'control-group__label_focused' : this.focused || value}">{{label}}</label>
    <input
      v-bind="$attrs"
      v-bind:value="value"
      v-on="inputListeners"
      class="control-group__input"
      :class="[ ( error && (exval || dirty) ) && 'control-group__input_invalid', ( !error && value && dirty ) && 'control-group__input_valid', extracss ]"
      :id="id"
      :name="name"
      :type="type"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
    >
    <slot></slot>
    <div v-if="help" class="control-group__optional-field-helper" v-html="help"></div>
    <div v-if="( error && (exval || dirty) )" class="control-group__input-error">{{ errormsg || error }}</div>
  </div>
</template>

<script>

export default {
  name: 'BasicInput',
  inheritAttrs: false,
  $_veeValidate: {
    // value getter
    value() {
      return this.$el.value;
    },
    // name getter
    name() {
      return this.name;
    },
  },
  props: {
    label: String,
    id: String,
    name: String,
    errormsg: String,
    help: String,
    extracss: String,
    exval: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
  },
  data() {
    return {
      focused: false,
      touched: false,
      dirty: false,
    };
  },
  methods: {
    makeDirty() {
      this.dirty = true;
    },
  },
  computed: {
    inputListeners() {
      const vm = this;
      return Object.assign(
        {},
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input(event) {
            vm.$emit('input', event.target.value);
          },
          focus() {
            vm.focused = true;
            vm.touched = true;
          },
          blur() {
            vm.focused = false;
            vm.dirty = true;
          },
        },
      );
    },
  },
  watch: {
    value: 'makeDirty',
  },
  mounted() {
    if (this.value) {
      this.dirty = true;
    }
  },
};
</script>
