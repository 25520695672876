import Api from '@/design/frontend/LeapTheory/Blank/services/Api';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const validators = {
  validateAge: {
    getMessage: () => 'You must be over 18 to apply.',
    validate: (value) => {
      // age18
      const checkDate = moment().subtract(18, 'years');
      // age100
      const checkDate2 = moment().subtract(100, 'years');
      const bd = moment(value, 'MM/DD/YYYY');
      return bd < checkDate && bd > checkDate2;
    },
  },
  validateABA: {
    getMessage: () => 'This value seems to be invalid.',
    validate: (value) => {
      let c = 0;
      let isValid = false;

      if (value && value.length === 9) { // don’t waste energy totalling if its not 9 digits
        for (let i = 0; i < value.length; i += 3) { // total the sums
          c += parseInt(value.charAt(i), 10) * 3
            + parseInt(value.charAt(i + 1), 10) * 7
            + parseInt(value.charAt(i + 2), 10);
        }
        isValid = c !== 0 && c % 10 === 0;// check if multiple of 10
      }
      return isValid;
    },
  },
  validateZip: {
    getMessage: () => 'Sorry we do not currently process requests from your state.',
    validate: async (value, validStates) => {
      if (value.length !== 5) {
        return false;
      }
      try {
        const response = await Api.get(`zipcode/${value}`);
        if (response.data.found) {
          return Array.isArray(validStates) ? validStates.includes(response.data.data.state) : false;
        }
        return false;
      } catch (err) {
        // console.log(err);
        return false;
      }
    },
  },
  validateAreaCode: {
    getMessage: () => 'Oops! It looks like that number is not real.',
    validate: async (value) => {
      if (value.length !== 12 && value.length !== 14) {
        return false;
      }
      if (value.substr(0, 3) === '1-8' || value.substr(0, 1) === '8') {
        return true;
      }
      try {
        const response = await Api.get(`areacode/${value}`);
        if (response.data.found) {
          return true;
        }
        return false;
      } catch (err) {
        // console.log(err);
        return false;
      }
    },
  },
};
