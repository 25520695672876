<template>
  <!-- eslint-disable max-len -->
  <footer class="footer bg-blue txt-white">
    <div class="footer__container">
      <div class="footer__content row">
        <div class="footer__logo-wrapper">
          <Logo outerClass="footer__logo-outer" innerClass="logo" type="png" color="light" />
          <div v-html="copyright"></div>
          <div v-if="withColorSwitch" class="footer__color-switch">
            <small>Display Mode:</small>
            <ColorSwitch />
          </div>
        </div><!--End Col 8-->
        <div class="footer__column footer__column_first">
          <h2 class="footer__column-header">Legal</h2>
          <router-link to="/legal/tos">Terms of Service</router-link>
          <br>
          <router-link to="/legal/privacy">Privacy Policy</router-link>
          <br>
          <router-link to="/legal/california">CCPA Privacy Notice</router-link>
          <br>
          <router-link to="/legal/disclaimer">Disclaimer</router-link>
          <br>
          <router-link to="/legal/e-consent">E-Consent</router-link>
          <br>
          <router-link to="/unsubscribe">Unsubscribe</router-link>
          <br>
        </div><!--End Col 2-->
        <div class="footer__column">
          <h2 class="footer__column-header">Company</h2>
          <router-link to="/">Home</router-link>
          <br>
          <router-link to="/#why-choose-us">Why Choose Us</router-link>
          <br>
          <router-link to="/#how-it-works">How it Works</router-link>
          <br>
          <router-link to="/#faq">FAQ</router-link>
          <br>
          <router-link to="/registration/1">Get Started</router-link>
        </div><!--End Col 2-->
      </div><!--End Row-->
    </div><!--End Container-->
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from './Logo';
import ColorSwitch from './ColorSwitch';

export default {
  name: 'Footer',
  components: {
    Logo,
    ColorSwitch,
  },

  computed: {
    ...mapGetters(['pageContext', 'withColorSwitch']),
    copyright() {
      if (this.pageContext.site_name === 'My Fast Cash') {
        return  `&copy; 2020 ${this.pageContext.site_name}&trade;`;
      }
      return `&copy; 2019 ${this.pageContext.site_name}&#8480;`;
    }
  },
};
</script>

<style>

</style>
