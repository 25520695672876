export default [
  {
    name: 'unsubscribe-index',
    path: 'index',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../../Unsubscribe/view/frontend/UnsubscribeIndex.vue'),
    alias: ['/unsubscribe'],
  },
  {
    name: 'unsubscribe-email',
    path: 'email',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../../Unsubscribe/view/frontend/UnsubscribeEmail.vue'),
  },
  {
    name: 'unsubscribe-phone',
    path: 'phone',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../../Unsubscribe/view/frontend/UnsubscribePhone.vue'),
  },
];
