<template>
<!-- eslint-disable max-len, invalid-first-character-of-tag-name, vue/no-parsing-error -->
  <div class="interstitial">
    <h3 class="interstitial__header">{{formData.firstName || 'Sorry'}}, unfortunately we were unable to connect you with a lender
      for your requested amount.</h3>
    <div class="interstitial__content">
      <p>However, we work with several lenders who may approve you for a <strong>short term loan for up to
        $1000.</strong></p>
      <p>Please note that loan amounts, interest rates and repayment terms will vary by lender.</p>
      <p>If you would like to apply for a short term loan of up to $1000, please click the
        <strong>“continue”</strong> button below before your session expires.</p>
      <p>Time left in your session:
        <strong class="text-danger" v-if="timer > 0">
          {{minutes}}:{{ (seconds < 10) ? `0${seconds}` : seconds}}
        </strong>
        <strong class="text-danger" v-else>Out of time</strong>
      </p>
    </div>

    <FormButton class="form-button_flex-wrap" id="form-submit-long"
                :hasErrors="false"
                @click="submit"
                :buttonLabel="saving ? 'Sending Your Request...' : 'Continue'"
                :isProcessing="saving">
      <template v-slot:loader>
        <div v-if="saving" class="form-button__loader">
          <div class="dots">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
      <template v-slot:errorMessage>
        <div v-if="errorMessage" class="alert alert-danger w-100 mt-4">
          There was an error submitting your request. Please try again later.
        </div>
      </template>
    </FormButton>
  </div>
</template>

<script>
import { setInterval, clearInterval } from 'timers';
import { mapGetters, mapActions } from 'vuex';
import FormButton from './components/FormButton';

export default {
  name: 'Interstitial',
  title: 'Re-Submit',
  components: {
    FormButton,
  },
  data() {
    return {
      errorMessage: false,
      saving: false,
      timer: 600000,
      minutes: 10,
      seconds: 0,
      exit: false,
      fromRoute: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'sendToAPI']),
    submit() {
      this.saving = true;
      if (this.repostLoanAmount) {
        this.saveFormData({
          loanAmount: this.repostLoanAmount.value,
        });
      }

      // Send to API replaced with push to Processing
      if (this.fromRoute) {
        this.$router.push({ path: this.fromRoute ? this.fromRoute.path : '/registration/processing' });
      }
    },

  },
  computed: {
    ...mapGetters(['formData', 'repostLoanAmount']),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.fromRoute = from;
    });
  },
  created() {
    if (process.env.VUE_ENV === 'client') {
      const countDown = setInterval(() => {
        if (!this.exit) {
          if (this.timer === 0) {
            // eslint-disable-next-line no-restricted-globals,no-alert
            if (confirm('Do you want to extend your session?')) {
              this.timer = 600000;
              // eslint-disable-next-line vue/max-len
              // eslint-disable-next-line no-restricted-globals,max-len,vue/max-len,no-alert
            } else if (confirm('Are you sure? Hitting OK will close this session, hit Cancel to continue with your loan submission.')) {
              this.exit = true;
            } else {
              this.timer = 600000;
            }
          } else {
            this.timer -= 1000;
            this.minutes = Math.floor((this.timer % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((this.timer % (1000 * 60)) / 1000);
          }
        } else {
          clearInterval(countDown);
        }
      }, 1000);
    }
  },
};
</script>
