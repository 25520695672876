export default {
  formData: (state) => state.formData,
  apiRedirectURL: (state) => state.formData.apiRedirectURL,
  splitTest: (state) => state.splitTest,
  auth: (state) => state.auth,
  visitData: (state) => state.visitData,
  abasList: (state) => state.abasList, 
  statesList: (state) => state.statesList, 
  isUserAuthorized: (state) => state.auth.confirmedUser,
  isEmailRecognized: (state) => state.auth.recognisedEmail,
};