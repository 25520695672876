var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-wrapper',{attrs:{"message":"Tell us a little bit about your income and employment"}},[_c('form',[_c('fieldset',{staticClass:"form__fieldset"},[_c('RadioOptions',{attrs:{"value":_vm.monthlyIncome,"label":"What is your monthly income?*","optionsList":[
                      {id: '1000', label: '$1000 or less'},
                      {id: '1500', label: '$1001 - $1500'},
                      {id: '2000', label: '$1501 - $2000'},
                      {id: '2500', label: '$2001 - $2500'},
                      {id: '3000', label: '$2501 - $3000'},
                      {id: '3500', label: '$3001 - $3500'},
                      {id: '4000', label: '$3501 - $4000'},
                      {id: '4500', label: '$4001 - $4500'},
                      {id: '5000', label: '$4501 - $5000'},
                      {id: '6000', label: 'More than $5,000'}]},on:{"input":_vm.onSelectOption}})],1),_c('small',[_vm._v("* You are not required to disclose income that is alimony, child support, or separate maintenance unless you want to use that income to qualify for a loan.")])])])}
var staticRenderFns = []

export { render, staticRenderFns }