<template>
  <!-- eslint-disable max-len -->
  <div class="control-group">
    <label v-if="label" class="control-group__label" :for="id" :class="{'control-group__label_focused' : this.focused || value}">{{label}}</label>
    <input
      :autocomplete="autocomplete"
      :class="[ ( error && (exval || dirty) ) && 'control-group__input_invalid', ( !error && value && dirty ) && 'control-group__input_valid', extracss ]"
      :id="id"
      :maxlength="maxlength"

      :name="name"
      :type="type"
      @blur="blurField"
      @focus="focusField"
      @input="updateValue"
      @paste="pasteReformat"
      class="control-group__input"
      v-bind="$attrs"
    >
  <div v-if="help" class="control-group__optional-field-helper" v-html="help"></div>
  <div v-if="( error && (exval || dirty) )" class="control-group__input-error">{{ errormsg || error }}</div>
  </div>
</template>

<script>
import Cleave from 'cleave.js';

export default {
  name: 'CleaveInput',
  inheritAttrs: false,
  $_veeValidate: {
    // value getter
    value() {
      return this.$el.value;
    },
    // name getter
    name() {
      return this.name;
    },
  },
  props: {
    label: String,
    id: String,
    name: String,
    errormsg: String,
    help: String,
    extracss: String,
    exval: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    cleaveoptions: {
      type: Object,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
  },
  data() {
    return {
      focused: false,
      touched: false,
      dirty: false,
    };
  },
  mounted() {
    this.cleave = new Cleave(this.$el.querySelector('input'), this.cleaveoptions);
    this.cleave.setRawValue(this.value);
    if (this.value) {
      this.dirty = true;
    }
  },
  destroyed() {
    this.cleave.destroy();
  },
  watch: {
    value: 'updateInput',
  },
  methods: {
    updateValue() {
      const val = this.cleave.getFormattedValue();
      if (val !== this.value) {
        this.$emit('input', val);
      }
    },
    updateInput() {
      this.cleave.setRawValue(this.value);
    },
    focusField() {
      this.focused = true;
      this.touched = true;
    },
    blurField() {
      this.focused = false;
      this.dirty = true;
    },
    pasteReformat(event) {
      event.preventDefault();
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      this.cleave.setRawValue(paste);
      this.updateValue();
    },
  },
};
</script>
