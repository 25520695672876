<template>
  <div class="form-button">
    <button class="form-button__button form-button__button_color uc"
            :id="id" :disabled="isProcessing" v-on="$listeners">{{buttonLabel}}</button>
    <slot name="loader"></slot>
    <div>
      <transition name="slide">
        <span class="form-button__some-errors" v-if="hasErrors">You have some errors</span>
      </transition>
    </div>
    <slot name="errorMessage"></slot>
  </div>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    hasErrors: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      require: true,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'form-submit',
    },
  },
};
</script>

<style></style>
