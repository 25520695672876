<template>
  <wizard-wrapper
    message="Please enter your zipcode so we can check your eligibility based on your state"
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <div class="form-row">
          <div class="form-row__inner">
            <CleaveInput
              label="What's your zipcode?"
              id="zipcode"
              name="zipcode"
              maxlength="5"
              type="tel"
              v-model="zipcode"
              :cleaveoptions="masks.zipcode"

              v-validate="{ required: true, validateZip: statesList, regex: /^[0-9]{5}$/ }"
              :exval="validationAttempt"
              :error="errors.first('zipcode')"
            />
          </div>
        </div>
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'Zipcode',
  title: 'Apply | Step 5',
  mixins: [formsRouterHooks],
  components: {
    FormButton,
    WizardWrapper,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      zipcode: '',
      masks: {
        zipcode: { numericOnly: true, blocks: [5] },
      },
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            zipcode: this.zipcode,
          });
          this.completeStep();
        }
        this.saving = false;
      });
    },
  },
  computed: {
    ...mapGetters(['formData', 'statesList']),
  },
  created() {
    this.zipcode = this.formData.zipcode || '';
  },
};
</script>
