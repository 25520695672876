<template>
  <div class="form-check" v-if="repostLoanAmount && isLoanHigh">
    <input
      type="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
      class="form-check-input"
      :id="id"
    >
    <label
      class="form-check-label"
      :for="id"
    >
      Search for a short term loan between {{repostLoanAmount.label}} if we are unable to match you with a lender for your requested amount
      <slot></slot>
    </label>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'RepostCheckbox',
  props: {
    id: String,
    value: {
      type: null,
      default: null,
    },
    currentLoanAmount: Number,
  },
  computed: {
    ...mapGetters(['repostLoanAmount']),
    isLoanHigh() {
      return this.repostLoanAmount ? this.currentLoanAmount > this.repostLoanAmount.value : false;
    }
  },
  watch:{
    currentLoanAmount() {
      this.$emit('input', this.isLoanHigh)
    }
  },
  created () {
    this.$emit('input', this.isLoanHigh)
  }
};
</script>
