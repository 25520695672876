import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      zipcode: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    formatPhoneNumber(phoneString = '') {
      const formatted = phoneString.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (formatted) {
        return `${formatted[1] }-${ formatted[2] }-${ formatted[3]}`;
      }
      return '';
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    this.email = urlParams.has('e') ? urlParams.get('e') : '';
    this.firstName = urlParams.has('f') ? urlParams.get('f') : '';
    this.lastName = urlParams.has('l') ? urlParams.get('l') : '';
    this.phone = this.formatPhoneNumber(urlParams.get('p') || '');
    this.zipcode = urlParams.has('z') ? urlParams.get('z') : '';

    this.saveFormData({
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      zipcode: this.zipcode,
    });
  },
};
