<template>
  <!-- eslint-disable max-len -->
  <div class="form">
    <h3 class="uc">Welcome Back {{fname}}</h3>
    <p class="form__step-description">Enter your details below and we'll pull up your previous data for quick resubmission.</p>
    <SignLeadForm :show="true" :loanSelectType="loanSelectType"/>
  </div>
</template>

<script>
import SignLeadForm from './components/SigninLeadForm';

export default {
  name: 'Signin',
  title: 'Login',
  components: {
    SignLeadForm,
  },
  props: {
    loanSelectType: {
      type: String,
      default: 'tel',
    },
  },
  data() {
    return {
      fname: '',
    };
  },
  mounted() {
    this.fname = this.$route.query.fname || '';
  },
};
</script>
