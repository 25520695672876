import oneSignalUtils from '@/code/FastCash/Bundle/OneSignal/view/frontend/utils.js'

export default {
  saveSplitTestData: (state, data) => {
    state.splitTest = {
      ...state.splitTest,
      ...data,
    };
  },
  saveFormData: (state, data) => {
    state.formData = {
      ...state.formData,
      ...data,
    };
  },
  setUseragentRecogniseUser: (state, data) => {
    state.auth.useragentRecognised = data.recognised;
    state.auth.userDetails.name = data.name;
  },
  setRecogniseUser: (state, set) => {
    state.auth.recognisedUser = set;
  },
  setRecogniseEmail: (state, data) => {
    state.auth.userDetails.name = data.name;
    state.auth.userDetails.email = data.email;
    state.auth.recognisedEmail = data.recognised;
  },
  setAuthoriseUser: (state, data) => {
    state.auth.confirmedUser = data.authorised;
    state.auth.userDetails = data.userDetails;
  },
  mergeVisitData: (state, data) => {
    Object.entries(data).forEach( ([k, v]) => {
      state.visitData[k] = state.visitData[k] || v
    })
  },
  saveClickID: (state, clickID) => {
    state.visitData.clickID = clickID;
  },
  saveSessionID: (state, data) => {
    state.visitData.sessionID = data.sessionID;
    // save to local storage
    const now = new Date();
    localStorage.setItem('session',
      JSON.stringify({
        subid1: data.subid1,
        subid2: data.subid2,
        subid3: data.subid3,
        subid4: data.subid4,
        sessionID: data.sessionID,
        webmasterID: data.webmasterID,
        subaccount: data.subaccount,
        expires: now.getTime() + (24 * 60 * 60 * 1000),
      }));

    oneSignalUtils.setExternalId(data.sessionID)
  },
  setAbasList: (state, data) => {
    state.abasList = data.map((v) => v.routing);
  }
};
