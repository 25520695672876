<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper
    message="Tell us a little bit about your income and employment"
  >
    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <div class="form-row">
          <div class="form-row__inner form-row__inner_size_lg-8 form-row__inner_size_xl-8">
            <CleaveInput
              label="Work phone number"
              id="workPhone"
              name="workPhone"
              type="tel"
              maxlength="12"
              :cleaveoptions="masks.workPhone"
              v-model="workPhone"
              v-validate="{ required: true, validateAreaCode: true, regex: /^[0-9]{3}\-[0-9]{3}\-[0-9]{4}$/ }"
              :exval="validationAttempt"
              :error="errors.first('workPhone')"
              help="Lenders require this to verify employment, you must provide an accurate number to obtain a loan.<br><br>Format: 999-999-9999."
            />
          </div>
          <div class="form-row__inner form-row__inner_size_lg-4 form-row__inner_size_xl-4">
            <CleaveInput
              label="Extension"
              id="workExt"
              name="workExt"
              maxlength="7"
              :cleaveoptions="masks.workExt"
              type="tel"
              v-model="workExt"
              :exval="validationAttempt"
              :error="errors.first('workExt')"
            />
          </div>
        </div>
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

// eslint-disable-next-line import/no-extraneous-dependencies
require('@root/node_modules/cleave.js/dist/addons/cleave-phone.us.js');

export default {
  name: 'WorkPhone',
  title: 'Apply | Step 19',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,

      workPhone: '',
      workExt: '',

      masks: {
        workPhone: { phone: true, phoneRegionCode: 'US', delimiter: '-' },
        workExt: { numericOnly: true, blocks: [7] },
      },
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            workPhone: this.workPhone,
            workExt: this.workExt,
          });
          this.completeStep();
        }
        this.saving = false;
      });
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.workPhone = this.formData.workPhone || '';
    this.workExt = this.formData.workExt || '';
  },
};
</script>
