export default {
  data() {
    return {
      subid1: '',
      subid2: '',
      subid3: '',
      subid4: '',
      subaccount: '',
      webmasterID: '',
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    let subid = urlParams.has('subid') ? urlParams.get('subid') : '';
    if (subid === '') {
      subid = urlParams.has('s1') ? urlParams.get('s1') : '';
    }
    if (subid === '') {
      subid = urlParams.has('s') ? urlParams.get('s') : '';
    }

    this.subid1 = subid;
    this.subid2 = urlParams.has('s2') ? urlParams.get('s2') : '';
    this.subid3 = urlParams.has('s3') ? urlParams.get('s3') : '';
    this.subid4 = urlParams.has('s4') ? urlParams.get('s4') : '';

    this.subaccount = urlParams.has('subacc') ? urlParams.get('subacc') : '';
    this.webmasterID = urlParams.has('aid') ? urlParams.get('aid') : '';
  },
};
